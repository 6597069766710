$.extend(true, window.locales.en, {
	management: {
		goToProjectButton: 'Go to Element'
	},
	projects: {
		name: 'Composite Name',
		project: 'Composite',
		projectInformation: 'Basic composite information',
		projects: 'Composites',
		projectSettings: 'Composite Settings',
		projectSettingsDescription: 'Advanced Composite settings'
	},
	organizations: {
		name: 'School Name',
		newOrganization: 'Create new school',
		organization: 'School',
		organizationChoice: 'Choose your school'
	},
	photoPicker: {
		timesUsed: '%{count} times used in Elements'
	},
	tabs: {
		composites: 'Composites',
		globalGraphics: 'Global Graphics',
		globalLayouts: 'Global Layouts',
		globalSheets: 'Global Sheets',
		graphics: 'Graphics',
		layouts: 'Layouts',
		layoutAssignments: 'Layout Assignments',
		layoutEditor: 'Layout Editor',
		proofs: 'Proofs',
		projectWizard: 'Create Composite',
		sheets: 'Sheets',
		studioTemplates: 'Studio Templates',
		subjectPoses: 'Subject Poses',
		userActivity: 'User Activity',
		workerLogs: 'Worker Logs'
	}
});