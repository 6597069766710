<template>
	<div :id="name + 'CategoryChoice'" class="ui menu categoryChoice headerButton" :class="rootClasses" :style="rootStyle" >
		<div class="ui dropdown item main-dropdown" :style="dropdownStyle" :class="computedDropdownClasses" @click="openRootMenu" ref="dropdown">
			<i class="icon" :class="iconClasses" />
			<span :id="name + 'CategoryButton'" class="dropdown-label"><i class="icon" :class="{ [selectedItem.icon]: true }" v-if="selectedItem && selectedItem.icon" />{{ selectedLabel }}</span>

			<dropdown-menu
				v-for="menu in recursiveMenus" :key="menu.id"
				:items="menu.items"
				:depth="menu.depth"
				:is-visible="visibility[menu.depth] && visibility[menu.depth].id == menu.id"
				:top="(visibility[menu.depth] && visibility[menu.depth].id == menu.id) ? visibility[menu.depth].top : 0"
				:bottom="(visibility[menu.depth] && visibility[menu.depth].id == menu.id) ? visibility[menu.depth].bottom : 0"
				:selected-id="selectedId"
				:left-dropdown="leftDropdown"
				:search="(items.length > 0 && menu.depth === 0) ? search : null"
				:search-input="searchInput"
				:toggle-not-in-use="items.length > 0 ? toggleNotInUse : null"
				@select-item="selectItem"
				@hovered="hoveredItem"
				@on-search="changeSearch"
				@change-not-in-use="changeNotInUse"
				:items-in-category="itemsInCategory" />
		</div>
	</div>
</template>

<script>
import DropdownMenu from './CategoryDropdownMenu.vue';

export default {
	props: ['items', 'rootClasses', 'dropdownClasses', 'rootStyle', 'dropdownStyle', 'name', 'defaultName', 'leftDropdown', 'search', 'canSearch', 'onSearch', 'toggleNotInUse', 'itemsInCategory'],
	data: () => ({
		visibility: {},
		selectedItem: null,
		searchInput: '',
		
		loading: false,
		hasError: false,

		clearHoverTimeout: null
	}),
	computed: {
		recursiveMenus() {
			let menus = [
				{
					id: 'root',
					depth: 0,
					items: this.items
				}
			];
			recursivelyAddMenus(menus, this.items, 1);

			return menus;
		},
		selectedId() {
			return this.selectedItem?.id;
		},
		selectedLabel() {
			return this.selectedItem?.name ?? ' ';
		},

		iconClasses() {
			if(this.loading) {
				return {
					notched: true,
					circle: true,
					loading: true
				}
			} else {
				return {
					dropdown: true
				};
			}
		},
		computedDropdownClasses() {
			let classes = { ...this.dropdownClasses };

			if(this.hasError) {
				classes.error = true;
			}

			return classes;
		}
	},
	watch: {
		items() {
			this.updateAllItems();
			if(!this.selectedItem || !this.getLeafItems().includes(this.selectedItem)) {
				this.selectDefaultItem(this.defaultName);
			}
		}
	},
	methods: {
		updateAllItems() {
			if(!this.items) {
				return;
			}
			
			// Every menu item (including folders) need a button id for lookups to work
			this.getAllItems().forEach(item => {
				if(!item.buttonId) {
					Vue.set(item, 'buttonId', 'B' + $.createRandomString(20));
				}
			});
			// Each selectable item needs an id to be able to set selectedId
			this.getLeafItems().forEach(item => {
				if(!item.id) {
					Vue.set(item, 'id', $.getUniqueId());
				}
			});
		},
		selectDefaultItem(defaultName) {
			let defaultItem = this.items[0] ?? null;
			this.getLeafItems().forEach((item, i) => {
				if(item.name == defaultName || (item.id && item.id == defaultName) || (defaultName && defaultName[0] == item.name) || (!defaultName && i === 0)) {
					defaultItem = item;
				}
			});

			if(defaultItem) {
				if(defaultItem.subCategories?.length) {
					defaultItem = defaultItem.subCategories[0];
				}

				this.selectItem(defaultItem);
			} else if(this.selectItem !== null) {
				this.selectItem(null);
			}
		},

		documentClickHandler(event) {
			if(!this.isAttachedTo(event.target, this.$el)) {
				this.clearVisibility();
			}
		},
		openRootMenu(event) {
			if(event.target.classList.contains('search-input') || event.target.parentNode?.classList.contains('search-input') || event.target.querySelector(':scope > .search-input') || event.target.classList.contains('menu') || event.target.classList.contains('toggle-not-in-use') || event.target.parentNode?.classList.contains('toggle-not-in-use')) {
				return;
			}

			let itemDepth = event.target.getAttribute('depth');
			if(itemDepth) {
				let itemId = event.target.id;
				Vue.set(this.visibility, itemDepth, {
					id: itemId,
					...this.getTopForTarget(event.target, itemDepth)
				});
				this.clearUpperVisibility(parseInt(itemDepth) + 1);
			} else if(this.visibility[0]) {
				this.clearVisibility();
			} else {
				Vue.set(this.visibility, 0, {
					id: 'root',
					top: 0
				});
			}
		},

		isAttachedTo(elem, root) {
			return root.contains(elem);
		},
		clearVisibility() {
			for(let id in this.visibility) {
				this.visibility[id] = null;
			}
		},

		selectItem(item) {
			this.hasError = false;
			if(typeof item?.load !== 'function') {
				this.selectedItem = item;
			}
			
			this.$emit('select-item', item);
			this.searchInput = '';
		},
		addItemAlphabetical(newItem, subFolders) {
			let items = this.getItemsArrayForSubFolders(subFolders);
			this.addItemAlphabeticalInternal(items, newItem);
		},
		addItemAlphabeticalInternal(items, newItem, beforeIndex = null) {
			if(beforeIndex === null) {
				items.forEach(item => {
					if((newItem.name.toLowerCase() > item.name.toLowerCase() || item.emphasize) && !item.sticky) {
						beforeIndex++;
					}
				});
			}

			if(beforeIndex >= items.length) {
				items.push(newItem);
			} else {
				items.splice(beforeIndex, 0, newItem);
			}
		},
		addItem(item, subFolders) {
			let items = this.getItemsArrayForSubFolders(subFolders);
			items.push(item);
		},
		getItemsArrayForSubFolders(subFolders) {
			if(!subFolders || !subFolders.length) {
				return this.items;
			}

			let items = this.items;
			for(let i = 0; i < items.length; i++) {
				let matchItem = items[i];
				if(matchItem.name.toLowerCase() == subFolders[0].toLowerCase()) {
					if(matchItem.subCategories) {
						return matchItem.subCategories;
					} else {
						items.removeItem(matchItem);
						let newFolder = {
							name: subFolders[0],
							subCategories: [matchItem],
							buttonId: 'B' + $.createRandomString(20)
						};
						this.addItemAlphabeticalInternal(this.items, newFolder, i);
						return newFolder.subCategories;
					}
				}
			}

			let newFolder = {
				name: subFolders[0],
				subCategories: [],
				buttonId: 'B' + $.createRandomString(20)
			};
			this.addItemAlphabeticalInternal(this.items, newFolder);
			return newFolder.subCategories;
		},

		removeItem(item) {
			let { parent, items } = this.getItemsArrayForItem(item);
			let oldIndex = items.indexOf(item);
			items.removeItem(item);
			if(parent.subCategories?.length === 0) {
				this.removeItem(parent);
			}

			if(item === this.selectedItem) {
				let nextItem = null;
				if(oldIndex !== -1) {
					if(oldIndex >= items.length || items[oldIndex]?.sticky) {
						nextItem = items[oldIndex - 1];
					} else {
						nextItem = items[oldIndex];
					}
				}

				if(nextItem) {
					if(nextItem.subCategories) {
						nextItem = nextItem.subCategories[0];
					}

					this.selectItem(nextItem);
				} else {
					this.selectDefaultItem();
				}
			}
		},
		getItemsArrayForItem(item) {
			return this.getItemsArrayForItemRecursive(this, this.items, item) || {
				parent: this,
				items: this.items
			};
		},
		getItemsArrayForItemRecursive(parent, items, item) {
			if(items.includes(item)) {
				return {
					parent,
					items
				};
			}

			for(let i = 0; i < items.length; i++) {
				let subItem = items[i];
				if(subItem.subCategories) {
					let subItemHasIt = this.getItemsArrayForItemRecursive(subItem, subItem.subCategories, item);
					if(subItemHasIt) {
						return subItemHasIt;
					}
				}
			}

			return null;
		},

		editItemName(item, name) {
			item.name = name;

			let { items } = this.getItemsArrayForItem(item);
			items.caseInsensitiveSort('name');
		},
		editItemFolder(item, subFolders) {
			let existingPlacement = this.getItemsArrayForItem(item);
			let newPlacement = this.getItemsArrayForSubFolders(subFolders);
			if(existingPlacement.items !== newPlacement) {
				existingPlacement.items.removeItem(item);
				if(existingPlacement.parent.subCategories?.length === 0) {
					this.removeItem(existingPlacement.parent);
				}

				this.addItemAlphabeticalInternal(newPlacement, item);
			}
		},

		hoveredItem(item, depth) {
			if(item.subCategories) {
				let target = this.$el.querySelector('#' + item.buttonId);
				Vue.set(this.visibility, depth, {
					id: item.buttonId,
					...this.getTopForTarget(target, depth)
				});
				this.clearUpperVisibility(depth + 1);

				if(this.clearHoverTimeout) {
					window.clearTimeout(this.clearHoverTimeout.timeout);
					this.clearHoverTimeout = null;
				}
			} else {
				if(!this.clearHoverTimeout || this.clearHoverTimeout.depth > depth) {
					if(this.clearHoverTimeout) {
						window.clearTimeout(this.clearHoverTimeout.timeout);
					}

					this.clearHoverTimeout = {
						timeout: window.setTimeout(() => {
							this.clearUpperVisibility(depth);
						}, 300),
						depth
					};
				}
			}
		},
		getTopForTarget(target, depth) {
			let top = target.offsetTop - target.parentNode.scrollTop;
			if(depth > 1) {
				top = top + target.parentNode.offsetTop - this.$refs.dropdown.offsetHeight;
			}

			let maxHeight = window.innerHeight / 3 - this.$el.offsetTop;
			if(top < maxHeight) {
				return {
					top
				};
			} else {
				let extraBottom = 0;
				if(target.__vue__?.item?.subCategories.length > 5) {
					extraBottom += 100;
				}
				return {
					bottom: top + extraBottom
				};
			}
		},
		clearUpperVisibility(depth) {
			if(this.visibility[depth]) {
				this.visibility[depth] = null;
				this.clearUpperVisibility(depth + 1);
			}
		},

		getAllItems() {
			return this.getAllItemsRecursive(this.items);
		},
		getAllItemsRecursive(items) {
			return items.reduce((leaves, item) => {
				leaves.push(item);
				if(item.subCategories) {
					leaves.push(...this.getAllItemsRecursive(item.subCategories));
				}

				return leaves;
			}, []);
		},
		getLeafItems() {
			return this.getLeafItemsRecursive(this.items);
		},
		getLeafItemsRecursive(items) {
			return items.reduce((leaves, item) => {
				if(item.subCategories) {
					leaves.push(...this.getLeafItemsRecursive(item.subCategories));
				} else {
					leaves.push(item);
				}

				return leaves;
			}, []);
		},

		changeSearch(e) {
			this.searchInput = e.target.value;
			// On escape, cancel current search
			if(e.keyCode == 27) {
				this.searchInput = '';
			} else if(e.keyCode < 46 && e.keyCode != 8) {
				// Don't do anything for weird keys
				return;
			} else if(!this.canSearch()) {
				this.searchInput = '';
				return;
			}

			this.onSearch?.(this.searchInput);
		},
		changeNotInUse(value) {
			this.$emit('change-not-in-use', value);
		},

		// Legacy $.CategoryPicker support
		startLoading() {
			this.loading = true;
		},
		stopLoading() {
			this.loading = false;
		},
		error() {
			this.hasError = true;
			this.loading = false;
		}
	},
	mounted() {
		document.addEventListener('click', this.documentClickHandler);
		this.updateAllItems();
		this.selectDefaultItem(this.defaultName);
	},
	destroyed() {
		document.removeEventListener('click', this.documentClickHandler);
	},
	components: { DropdownMenu }
};

function recursivelyAddMenus(menus, items, depth) {
	items.forEach(item => {
		if(item.subCategories?.length) {
			menus.push({
				id: item.buttonId,
				depth,
				items: item.subCategories
			});

			recursivelyAddMenus(menus, item.subCategories, depth + 1);
		}
	});
}
</script>

<style scoped>
.dropdown-label {
	flex-grow: 1;
}
</style>