$.convertToPt = function(px, asFloat) {
	var float = parseFloat(px) * 72.0 / 96.0;
	if(asFloat) {
		return float;
	} else {
		return float.toFixed(2);
	}
};
$.convertToPx = function(pt, asFloat) {
	var float = parseFloat(pt) * 96.0 / 72.0;
	if(asFloat) {
		return float;
	} else {
		return float.toFixed(2);
	}
};

$.convertRGBToHex = function(rgb) {
	rgb = rgb.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
	return (rgb && rgb.length === 4) ? "#" +
		('0' + parseInt(rgb[1],10).toString(16)).slice(-2) +
		('0' + parseInt(rgb[2],10).toString(16)).slice(-2) +
		('0' + parseInt(rgb[3],10).toString(16)).slice(-2) : '';
};
$.convertHexToRGB = function(hex, array) {
	if(hex.charAt(0) == '#') {
		hex = hex.substring(1);
	}

	var red = parseInt(hex.substring(0, 2), 16);
	var green = parseInt(hex.substring(2, 4), 16);
	var blue = parseInt(hex.substring(4, 6), 16);

	if(array) {
		return [red, green, blue];
	} else {
		return 'rgb(' + red + ',' + green + ',' + blue + ')';
	}
};
$.convertMSToRGB = function(number, array) {
	var hex = $.convertMSToHex(number);
	return $.convertHexToRGB(hex, array);
};
$.convertMSToHex = function(number) {
	if(isNaN(number)) {
		number = parseInt(number);
	}

	// needed since toString does not zero fill on left
	var template = "#000000";

	// in the MS Windows world RGB colors
	// are 0xBBGGRR because of the way Intel chips store bytes
	var red = (number & 0x0000ff) << 16;
	var green = number & 0x00ff00;
	var blue = (number & 0xff0000) >>> 16;

	// mask out each color and reverse the order
	number = red | green | blue;

	// toString converts a number to a hexstring
	var hex = number.toString(16);

	// template adds # for standard HTML #RRGGBB
	hex = template.substring(0,7 - hex.length) + hex;
	return hex;
};

$.convertHexToBytes = function(hex) {
    for (var bytes = [], c = 0; c < hex.length; c += 2)
    bytes.push(parseInt(hex.substr(c, 2), 16));
    return bytes;
}
$.convertBytesToHex = function(bytes) {
	for (var hex = [], i = 0; i < bytes.length; i++) {
        hex.push((bytes[i] >>> 4).toString(16));
        hex.push((bytes[i] & 0xF).toString(16));
    }
    return hex.join("");
};

$.matrixToArray = function(transform) {
	return transform.match(/(-?[0-9.]+)/g);
};

$.prettyFormatJSON = function(json, depth) {
	if(!depth) {
		depth = 0;
	}

	var str;
	if(json === null || typeof json == 'undefined') {
		str = 'null';
	} else if($.isArray(json)) {
		str = '[\n';

		for(let i = 0; i < json.length; i++) {
			str += $.getTabs(depth + 1) + $.prettyFormatJSON(json[i], depth + 1);
			if(i != json.length - 1) {
				str += ',';
			}
			str += '\n';
		}

		str += $.getTabs(depth) + ']';
	} else if(typeof json === 'object') {
		str = '{\n';

		var runs = 0, count = $.getObjectCount(json);
		for(let i in json) {
			str += $.getTabs(depth + 1) + '"' + i + '": ' + $.prettyFormatJSON(json[i], depth + 1);
			if(runs != count - 1) {
				str += ',';
			}
			str += '\n';
			runs++;
		}

		str += $.getTabs(depth) + '}';
	} else if(typeof json == 'string') {
		str = '"' + json + '"';
	} else {
		str = json;
	}

	return str;
};
$.getTabs = function(count) {
	var str = '';
	for(var i = 0; i < count; i++) {
		str += '\t';
	}

	return str;
};

$._RANDOM_POSSIBLE_CHARACTERS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
$.createRandomString = function(length) {
	var text = '';
	for( var i=0; i < length; i++ ) {
		text += $._RANDOM_POSSIBLE_CHARACTERS.charAt(Math.floor(Math.random() * $._RANDOM_POSSIBLE_CHARACTERS.length));
	}

	return text;
};

// Normal speaking of numbers
$.numToWord = function(num) {
	var ones = Array('zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine');
	return ones[num];
};
// Special speaking of numbers.  Good for First Grade, Second Grade, etc...
$.numToWord2 = function(num) {
	var special = ['zeroth','first', 'second', 'third', 'fourth', 'fifth', 'sixth', 'seventh', 'eighth', 'ninth', 'tenth', 'eleventh', 'twelfth', 'thirteenth', 'fourteenth', 'fifteenth', 'sixteenth', 'seventeenth', 'eighteenth', 'nineteenth'];
	var deca = ['twent', 'thirt', 'fourt', 'fift', 'sixt', 'sevent', 'eight', 'ninet'];

	if(num < 20) {
		return special[num];
	} else if(num % 10 === 0) {
		return deca[Math.floor(num / 10) - 2] + 'ieth';
	} else {
		return deca[Math.floor(num / 10) - 2] + 'y ' + special[num % 10];
	}
};
$.numToOrdinal = function(number) {
	if(number > 3 && number < 21) {
		return `${number}th`;
	} else {
		let endDigit = number % 10;
		if(endDigit === 1) {
			return `${number}st`;
		} else if(endDigit === 2) {
			return `${number}nd`;
		} else if(endDigit === 3) {
			return `${number}rd`;
		} else {
			return `${number}th`;
		}
	}
};

$.formatBytes = function(bytes, decimals) {
	if(bytes == 0) return '0 Byte';
	var k = 1000;
	var dm = decimals + 1 || 3;
	var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
	var i = Math.floor(Math.log(bytes) / Math.log(k));
	return (bytes / Math.pow(k, i)).toPrecision(dm) + ' ' + sizes[i];
};

$.padZeros = function(number, zeroes) {
	if(!zeroes) {
		zeroes = 1;
	}

	var exp = 10;
	var prepend = '';
	for(var i = 0; i < zeroes; i++) {
		if(number < exp) {
			prepend += '0';
		}
		exp *= 10;
	}

	return prepend + number;

};

$.formatDuration = function(seconds) {
	var val = '';

	var hours = Math.floor(seconds / 3600);
	if(hours > 0) {
		val = $.padZeros(hours) + ':';
	}

	seconds = seconds % 3600;
	var minutes = Math.floor(seconds / 60);
	if(val) {
		val += $.padZeros(minutes) + ':';
	} else {
		val = minutes + ':';
	}

	seconds = seconds % 60;
	if(val) {
		val += $.padZeros(Math.floor(seconds));
	} else {
		val = seconds;
	}

	return val;
};

$.htmlEscape = function(str) {
	return str.replace(/&/g, '&amp;').replace(/"/g, '&quot;')
		.replace(/'/g, '&#39;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
};

$.htmlUnescape = function(str){
	return str.replace(/&quot;/g, '"').replace(/&#39;/g, "'")
		.replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&amp;/g, '&');
};

$.reduceFloatPrecision = function(num, precision) {
	if(!precision) {
		precision = 7;
	}

	return parseFloat(num.toPrecision(7));
};
$.toFixedFloored = function(number, precision) {
	var string = number.toFixed(precision + 1);

	return string.substr(0, string.length - 1);
};

$.isTruthy = function(value) {
	return value === 'true' || value === true;
};

$.sanitizeNumbersAsStrings = function(data) {
	data = $.extend(true, {}, data);

	for(let id in data) {
		var value = data[id];
		if($.isPlainObject(value)) {
			data[id] = this.sanitizeNumbersAsStrings(value);
		} else if(typeof value == 'string' && !isNaN(value)) {
			value = parseFloat(value);

			// To prevent things like parseFloat(true) because true is a number apparently
			if(!isNaN(value)) {
				data[id] = value;
			}
		}
	}

	return data;
};

$.convertToRadians = function(angle) {
	return angle * (Math.PI / 180);
};
$.convertToDegrees = function(angle) {
	return angle * (180 / Math.PI);
};
$.rotatePoint = function(angle, x, y, originX, originY) {
	return {
		x: Math.cos(angle) * (x - originX) - Math.sin(angle) * (y - originY) + originX,
		y: Math.sin(angle) * (x - originX) + Math.cos(angle) * (y - originY) + originY
	};
}

$.getDisplayUnit = function(value) {
	var unitType = $.UNIT_TYPE || 'us';

	value = parseFloat(value);
	if(unitType == 'us') {
		return value.toFixed(2) + '"';
	} else if(unitType == 'metric') {
		return (value * 2.54).toFixed(2) + 'cm';
	} else {
		return value.toFixed(2);
	}
};


$.formatCSV = function(rows, options) {
	options = $.extend({
		stripNewLines: false
	}, options);

	return rows.map(function(row) {
		return $.formatCSVRow(row, options);
	}).join('\r\n');
};
$.formatCSVRow = function(row, options) {
	options = $.extend({
		stripNewLines: false
	}, options);

	return row.map(function(column) {
		if(typeof column === 'string') {
			var result = column.replace(/"/g, '""');

			if(options.stripNewLines) {
				result = result.replace(/(?:\r\n|\r\r|\r|\n)/g, ' ');
			}

			return '"' + result + '"';
		} else {
			return column;
		}
	}).join(',');
};

$.saveCSV = function(filename, rows, options) {
	// Special character to make Excel read this as we UTF8 file
	const universalBOM = "\uFEFF";

	let csvString = $.formatCSV(rows, options);
	let a = document.createElement('a');
	a.href = 'data:attachment/csv; charset=utf-8,' +  encodeURIComponent(universalBOM + csvString);
	a.target = '_blank';
	a.download = filename;

	document.body.appendChild(a);
	a.click();
	$(a).remove();
};

$.isValidUUID = function(string) {
	return /^[0-9A-F]{8}-[0-9A-F]{4}-[1-5][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i.test(string);
};
$.sanitizeFilename = function(string) {
	return string.replace(/[^\x20-\x7E]/g, ' ').replace(/[\\/:*?"<>|]/g, '');
};