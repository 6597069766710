$.LayoutsDB = function(layouts, settings) {
	var obj = new $.DBQueue($.extend({
		loadUrl: 'ajax/getLayout.php',
		saveUrl: 'ajax/saveLayout.php',
		postData: {
			layoutId: layouts.layoutId,
			composer: true
		},
		pageSet: layouts,
		userEvents: $.userEvents,
		allowPartialUpdates: false,
		getSocket: function() {
			var url = 'wss?composer=' + layouts.layoutId + '&token=' + $.PlicToken + '&type=layout';
			if($.LoggedInAsUser) {
				url += '&originalToken=' + $.OriginalUserToken;
			}

			return url;
		},

		addPageFromDefinition: function(layout) {
			var page = new $.LayoutPage({
				id: layout.layoutId,
				layoutId: layout.layoutId,
				layout: layout.definition,
				candids: layout.candids,
				texts: layout.texts,
				type: layout.type,
				extras: layout.definition.extras
			});

			this.pageSet.pages.push(page);
			if($.isInit(layout.pageNumber)) {
				page.pageNumber = layout.pageNumber + 1;
			} else {
				page.pageNumber = layout.order + 1;
			}
			page.pageSet = this.pageSet;
			if(layout.totalPages) {
				this.pageSet.getTotalPagesStub = function() {
					return layout.totalPages;
				};
			}

			page.db = this;
		},
		addEntirePage: function(page, inserted, extras) {
			if(!extras) {
				extras = {};
			}

			var pageData = this.getEntirePageData(page);
			if(inserted !== false) {
				pageData.inserted = true;
			}

			$.mainApp.loading = true;
			$.ajax({
				url: 'ajax/createLayout.php',
				data: {
					layoutName: $.LayoutName + ' - Page ' + page.pageNumber,
					order: page.pageNumber - 1,
					definition: JSON.stringify(page.layout),
					categoryId: this.currentCategoryId,
					type: 'Basic',
					rootLayoutId: this.pageSet.pages[0].id
				},
				dataType: 'json',
				type: 'POST',
				success: function(data) {
					page.id = page.layoutId = pageData.id  = data.layout.layoutId;
					$.mainApp.loading = false;

					if(obj.userEvents) {
						obj.userEvents.addEvent({
							context: [page],
							action: 'insert',
							args: [pageData, page.pageSet.pages.indexOf(page)]
						});
					}
				},
				error: function() {
					$.Alert('Error', 'Failed to add a page to the layout');
				}
			});

			return pageData;
		},
		getEntirePageData: function(page) {
			return {
				id: page.id,
				order: page.pageNumber - 1,
				layout: page.layout,
				type: page.type
			};
		}
	}, settings));
	obj.layouts = layouts;

	var _load = obj.load;
	$.extend(obj, {
		onLoadGlobalData: function(data) {
			if(data.orderedProductId) {
				$.OrderedProductId = data.orderedProductId;
			}
			if(data.compositeProofBatch) {
				this.pageSet.setPrimaryProofBatch(data.compositeProofBatch);
			}
		},
		loadPages: function(pages) {
			this.addPageFromDefinition(pages);
		},
		load: function(events) {
			_load.call(this, $.extend({
				onReady: function(data) {
					$.ProjectName = data.layout.layoutName;
					$.LayoutName = data.layout.layoutName;
					obj.currentCategoryId = data.layout.categoryId;

					obj.addPageFromDefinition(data.layout);
					data.layoutPages.forEach(function(layoutPage) {
						obj.addPageFromDefinition(layoutPage);
					});

					$.plicAPI({
						method: 'project-templates',
						type: 'GET',
						success: function(templatesData) {
							let template = templatesData.project_templates.find(template => template.name === 'School Subjects');
							if(template) {
								$.plicAPI({
									method: 'project-templates/' + template.id,
									type: 'GET',
									success: function(templateData) {
										obj.pageSet.subjectTemplate = templateData.project_template;

										if(events.onReady) {
											events.onReady(data);
										}
									},
									error: function() {
										if(events.onReady) {
											events.onReady(data);
										}
									}
								});
							} else if(events.onReady) {
								events.onReady(data);
							}
						},
						error: function() {
							if(events.onReady) {
								events.onReady(data);
							}
						}
					});
				}
			}), events);
		},
		loadSubjects: function(project) {
			if(project.subjects) {
				obj.finishLoadingSubjects(project);
			} else {
				var subjects = null;
				var template = null;
				var chain = new $.ExecutionChain(function () {
					if (!subjects || !template) {
						$.Alert('Error', 'Failed to load subjects for this project');
						return;
					}

					var placeholderSubjects = [];
					project.subjects = $.SubjectManagement.populateSubjectsWithTemplate(subjects, template, null, placeholderSubjects);
					project.subjects.caseInsensitiveSort('Last Name');

					project.placeholderSubjects = placeholderSubjects;
					project.placeholderSubjects.caseInsensitiveSort('Last Name');

					project.template = template;

					obj.finishLoadingSubjects(project);
				});

				$.loadSubjects({
					plicProjectId: project.plicProjectId,
					chain: chain,
					width: 1000,
					loadTemplate: true,
					loadOrders: true,
					onComplete: function(dataSubjects, dataTemplate) {
						subjects = dataSubjects;
						template = dataTemplate;
					}
				});

				chain.add($.getPlicAPI({
					method: 'projects/' + project.plicProjectId,
					success: function (data) {
						layouts.projectBackgroundId = data.project.background_photo_id;
						$.projectData = data.project;
					}
				}));
				chain.add($.getPlicAPI({
					method: 'projects/' + project.plicProjectId + '/ecommerce-gallery',
					success: function(data) {
						if(data.ecommerce_gallery && data.ecommerce_gallery.plic_app.slug.includes('imagequix') && data.ecommerce_gallery.republishing_enabled) {
							$.EcommerceGallery = data.ecommerce_gallery;
						} else {
							$.EcommerceGallery = null;
						}
					}
				}));

				chain.done();
			}
		},
		finishLoadingSubjects: function(project) {
			if(this.pageSet) {
				this.pageSet.setSubjects(project.subjects);
				this.pageSet.setSubjectTemplate(project.template);
				this.pageSet.setPlaceholderSubjects(project.placeholderSubjects);
			}

			if(this.onSubjectsLoaded) {
				var subjects = [];
				$.merge(subjects, project.subjects);
				$.merge(subjects, project.placeholderSubjects);

				this.onSubjectsLoaded(subjects);
			}
		},

		loadOrderBatch: function(batch) {
			window.loadOrders({
				batchId: batch.studio_order_batch_id
			}).then((orders) => {
				let subjectIds = orders.reduce((ids, order) => {
					if(order.subject_id && !ids.includes(order.subject_id)) {
						ids.push(order.subject_id);
					}
		
					return ids;
				}, []);

				window.loadSubjectsByIds({
					subjectIds
				}).then((response) => {
					let subjects = response.subjects;
					subjects.forEach(subject => {
						subject.orders = orders.filter(order => order.subject_id === subject.id);
					});
					batch.template = this.pageSet.subjectTemplate = response.projectTemplates[0];
					batch.subjects = subjects;
					batch.placeholderSubjects = [];

					if(this.pageSet) {
						this.pageSet.setSubjects(subjects);
					}
					if(this.onSubjectsLoaded) {
						this.onSubjectsLoaded(subjects);
					}
				});
			});
		},
		loadProof: function(project) {
			if(project.compositeProof) {
				this.pageSet?.setProof(project.compositeProof);
				this.onLoadProof?.(project.compositeProof);
				return;
			} else if(this.loadingProof) {
				return;
			}

			this.loadingProof = $.proxyAjax({
				url: 'ajax/compositeProofs.php',
				data: {
					jobId: project.jobId
				},
				type: 'GET',
				dataType: 'json',
				success: (data) => {
					project.compositeProof = data.compositeProof;
					this.pageSet?.setProof(data.compositeProof);
					this.onLoadProof?.(data.compositeProof);
					this.loadingProof = null;
				},
				error: () => {
					$.Alert('Error', 'Failed to load proof');
					this.loadingProof = null;
				}
			});
		}
	});

	$.UserActivityModule(obj, {
		showLastSeen: false,
		activitySubjectLabel: 'Layout'
	});
	return obj;
};