<template>
	<div class="group-row-comments" v-if="render">
		<v-alert type="info">Row 1 should be the bottom row.  Names should be entered from the left to right.</v-alert>

		<comment v-for="row in rows" :key="row.id" :row="row" :render="render" :last-row-id="lastRowId" :disabled="disabled" :names="names" :row-name-editable="!!proof.canEditRowNames" />
		<comment v-for="row in extraDataFields" :key="row.id" :row="row" :render="render" :last-row-id="0" :disabled="disabled" :row-name-editable="false" :text-label="row.name" />

		<v-btn color="primary" class="add-button" @click="addRow" :disabled="disabled">Add Row</v-btn>
	</div>
	<div v-else></div>
</template>

<script>
import { appClient } from '../../../../PlicCanvas/vue/utils/axios';
import Comment from './Comment';

export default {
	props: ['render', 'proof'],
	data: () => ({
		names: null
	}),
	computed: {
		disabled() {
			return this.render.approved === true || this.render.approved === false || (this.proof && this.proof.getDeadlineDays() < 0);
		},
		comments() {
			return Object.values(this.render.comments).filter(comment => {
				return comment && comment.id && !comment.deleted;
			});
		},
		rows() {
			let comments = this.comments.filter(comment => {
				return comment.id.includes('row-') || comment.id === 'not-pictured';
			});

			comments.sort((a, b) => {
				if(a.id === 'not-pictured') {
					return 1;
				} else if(b.id === 'not-pictured') {
					return -1;
				}

				let aId = parseInt(a.id.replace('row-', ''));
				let bId = parseInt(b.id.replace('row-', ''));

				return aId - bId;
			});

			return comments;
		},
		extraDataFields() {
			return (this.proof.extraDataToCollect ?? []).map(field => {
				let comment = this.comments.find(comment => comment.id === `extra-${field}`);
				if(!comment) {
					comment = {
						id: `extra-${field}`,
						time: (new Date()).getTime(),
						name: field,
						comment: '',
						deleted: false
					};

					this.render.jsonPropertyChange('comments', comment.id, comment, {
						permanent: true
					});
				}

				return comment;
			});
		},
		lastRowId() {
			let lastRowId = 0;
			Object.values(this.comments).forEach(comment => {
				if(!comment.id.includes('row-')) {
					return;
				}

				let rowId = parseInt(comment.id.replace('row-', ''));
				if(rowId > lastRowId) {
					lastRowId = rowId;
				}
			});

			return lastRowId;
		}
	},
	watch: {
		render() {
			this.initComments();
			this.loadSubjects();
		}
	},
	methods: {
		initComments() {
			if(!this.render) {
				return;
			}

			if(this.render.comments === []) {
				this.render.comments = {};
			}

			if(!this.render.comments['row-1']) {
				let comments = { ...this.render.comments };

				for(let i = 1; i <= 3; i++) {
					comments[`row-${i}`] = {
						id: `row-${i}`,
						time: (new Date()).getTime(),
						name: `Row ${i}`,
						comment: '',
						deleted: false
					};
				}

				comments['not-pictured'] = {
					id: 'not-pictured',
					time: (new Date()).getTime(),
					name: `Not Pictured`,
					comment: '',
					deleted: false
				};

				this.render.jsonReplace('comments', comments, {
					permanent: true
				});
			}
		},
		addRow() {
			let newRowId = this.lastRowId + 1;
			if(this.render.comments[`row-${newRowId}`]) {
				this.render.jsonSubPropertyChange('comments', `row-${newRowId}`, 'deleted', false, {
					permanent: true,
					mergeSub: true
				});
			} else {
				this.render.jsonPropertyChange('comments', `row-${newRowId}`, {
					id: `row-${newRowId}`,
					time: (new Date()).getTime(),
					name: `Row ${newRowId}`,
					comment: '',
					deleted: false
				});
			}
		},
		async loadSubjects() {
			if(!this.render || !this.render.jobId || this.names) {
				return;
			}

			try {
				let response = await appClient.get('api/v1/getSubjectNames?jobId=' + this.render.jobId);
				let names = response.data.names;
				names.sort();
				this.names = names;
			} catch(e) {
				console.error('Failed to load subject names: ', e);
			}
		}
	},
	mounted() {
		this.initComments();
		this.loadSubjects();
	},
	components: {
		Comment
	}
};
</script>

<style scoped>
.group-row-comments {
	margin-right: 1em;
}
</style>