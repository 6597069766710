$.CompositesDB = function(composite, progressIndicator, usersLoggedInLabels) {
	var obj = new $.ComposerDBQueue({
		pageSet: composite,
		loadUrl: 'ajax/getComposites.php',
		saveUrl: 'ajax/saveComposites.php',
		postData: {
			jobId: composite.jobId
		},
		progressIndicator: progressIndicator,
		usersLoggedInLabels: usersLoggedInLabels,
		getSocket: function() {
			var url = 'wss?composer=' + composite.jobId + '&token=' + $.PlicToken;
			if($.LoggedInAsUser) {
				url += '&originalToken=' + $.OriginalUserToken;
			}

			return url;
		},
		userEvents: $.userEvents,
		loadOrders: true
	});

	$.extend(obj, {
		loadModel: function(data) {
			if($.getStudioSetting('forceOriginalPhotoVersions')) {
				$.primarySubjectPhotoVersion = 'original';
				$.primaryGraphicPhotoVersion = 'original';
			}
			if($.plicLicenseFeatures['is-demo']) {
				obj.includeLicenseData = true;
			}
		},
		loadSinglePage: function (definition, previousPage) {
			var page = new $.CompositePage(definition.classObj);
			this.loadStandardPageData(definition, page);

			if (definition.studentLabelCSS) {
				page.studentLabelCSS.css = definition.studentLabelCSS;
			}
			if (definition.largeCellPosition) {
				if(typeof definition.largeCellPosition === 'string') {
					page.largeCellPosition = JSON.parse(definition.largeCellPosition);
				} else {
					page.largeCellPosition = definition.largeCellPosition;
				}
			}
			if (definition.extras) {
				if(typeof definition.extras === 'string') {
					page.extras = JSON.parse(definition.extras);
				} else {
					page.extras = definition.extras;
				}

				if($.isArray(page.extras)) {
					var extras = {};
					for(var i = 0; i < page.extras.length; i++) {
						$.extend(extras, page.extras[i]);
					}
					page.propertyChange('extras', extras);
				}
			}
			page.compositeProofRenderId = definition.compositeProofRenderId;

			return page;
		},
		getEntirePageData: function(page) {
			return {
				id: page.id,
				candids: page.candids,
				classObj: page.classObj ? {
					'id': page.classObj['id']
				} : null,
				studentLabelCSS: page.studentLabelCSS ? JSON.stringify(page.studentLabelCSS.css) : null,
				texts: page.texts,
				pageNumber: page.pageNumber
			};
		},
		formatLastPageSeen: function(pageNumber) {
			let page = this.pageSet.getPage(pageNumber - 1);
			if(page) {
				return 'the batch ' + page.classObj.name;
			} else {
				return 'page ' + pageNumber;
			}
		},

		activitySubjectLabel: 'Composite',
		includeOrganizationData: true
	});
	
	return obj;
};