export default function populateFullName(subject) {
	let fullName = '';
	if(subject['First Name']) {
		if(subject['Last Name']) {
			fullName = subject['First Name'] + ' ' + subject['Last Name'];
		} else {
			fullName = subject['First Name'];
		}
	} else if(subject['Last Name']) {
		fullName = subject['Last Name'];
	}

	if(!fullName) {
		fullName = subject['Access Code'] || '';
	}

	subject.fullName = fullName;
}