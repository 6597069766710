import populateFullName from "../utils/populate-full-name";

$.loadSubjects = function(options) {
	options = $.extend({
		width: 300
	}, options);

	var subjects;
	var projectTemplate;
	var orders;

	var chain = options.chain;
	if(!chain) {
		chain = new $.ExecutionChain(function() {});
	}

	var orderForms = [], plicLayouts = [];
	chain.addOnCompleteHandler(function(stats) {
		if(!stats.errors) {
			if(options.populateWithTemplate) {
				subjects = $.SubjectManagement.populateSubjectsWithTemplate(subjects, projectTemplate);

				if(options.populateFullName) {
					subjects.forEach(function(subject) {
						populateFullName(subject);
					});
				}
			}

			if(options.loadOrders && orders) {
				sideloadOrderForms(orders, orderForms, plicLayouts);
				subjects.forEach(function(subject) {
					subject.orders = orders.filter(function(order) {
						return order.subject_id === subject.id;
					});

					// Make sure order is consistent
					// Reverse order so things that grab default orders[0] grab most recent
					subject.orders.sort((a, b) => moment(b.created_at) - moment(a.created_at));
				});

			}

			options.onComplete(subjects, projectTemplate);
		}
	});

	var subjectParams = {
		include_all_crops: true,
		include_chroma_key: true,
		include_presigned_get: false
	};

	var firstSubjectParams = $.extend(true, {}, subjectParams);
	if(options.populateWithTemplate || options.loadTemplate) {
		firstSubjectParams.include_project_template = true;
		subjectParams.include_access_codes = firstSubjectParams.include_access_codes = true;
	}

	var SUBJECT_PAGE_COUNT = 500;
	var pagesLoaded = 0;
	chain.add($.getPlicAPI({
		method: 'projects/' + options.plicProjectId + '/subjects',
		params: $.extend({
			limit: SUBJECT_PAGE_COUNT,
			page: 1
		}, firstSubjectParams),
		type: 'GET',
		timeout: 20000,
		success: function (data) {
			subjects = data.subjects;
			projectTemplate = data.project_template;
			pagesLoaded++;
			if(!data.page) {
				console.warn('Missing page from response: ', JSON.stringify(data));
			}

			for(var i = 1; i < data.page.total_pages; i++) {
				chain.add($.getPlicAPI({
					method: 'projects/' + options.plicProjectId + '/subjects',
					params: $.extend({
						limit: SUBJECT_PAGE_COUNT,
						page: i + 1
					}, subjectParams),
					type: 'GET',
					timeout: 20000,
					success: function(data) {
						$.merge(subjects, data.subjects);
						pagesLoaded++;

						onAllSubjectsLoaded(data);
					},
					error: function(jqXHR) {
						if(options.onError) {
							options.onError(jqXHR);
						}
					}
				}));
			}
			onAllSubjectsLoaded(data);

			function onAllSubjectsLoaded(data) {
				if(pagesLoaded < data.page.total_pages) {
					return;
				}

				subjects.forEach(function(subject) {
					subject.photos = subject.photos.filter(function(photo) {
						return !!photo.upload_verified_at;
					});
				});

				var subjectPhotos = subjects.map(function(subject) {
					var primaryPhoto = $.SubjectManagement.getPrimaryPhoto(subject);
					var primaryPhotoId = primaryPhoto ? primaryPhoto.id : subject.primary_photo_id;

					return {
						primary_photo_id: primaryPhotoId,
						photos: subject.photos.map(function(photo) {
							var forceVersionId = null;
							if(photo.version_ids && photo.version_ids.length > 1) {
								if($.primarySubjectPhotoVersion === 'original') {
									forceVersionId = photo.version_ids[0];
								} else {
									forceVersionId = photo.version_ids[photo.version_ids.length - 1];
								}
								
								if(forceVersionId === null) {
									forceVersionId = 'null';
								}
							}

							// Only return minimum amount of data required
							return {
								id: photo.id,
								upload_file_name: photo.upload_file_name,
								upload_verified_at: photo.upload_verified_at,
								category: photo.category,
								force_version_id: forceVersionId
							}
						})
					};
				});

				var subjectsJSON = JSON.stringify(subjectPhotos);
				var encodedJSON;
				if(window.btoa && window.pako) {
					// This is only necessary for users with crap upload speeds
					encodedJSON = window.btoa(window.pako.deflate(subjectsJSON, {to: 'string'}));
				} else {
					encodedJSON = subjectsJSON;
				}

				chain.add({
					url: 'ajax/cacheSubjectPhotos.php',
					data: {
						subjectPhotos: encodedJSON,
						width: options.width
					},
					type: 'POST',
					dataType: 'json',
					timeout: 30000,
					success: function(data) {
						for(var i = 0; i < data.length; i++) {
							var subject = subjects[i];
							var photos = data[i].photos;

							for(var j = 0; j < photos.length; j++) {
								subject.photos[j].cdn_url = photos[j].cdn_url;
								delete subject.photos[j].presigned_get;
							}
						}
					},
					error: function (jqXHR) {
						if(options.onError) {
							options.onError(jqXHR);
						}
					}
				});
			}
		},
		error: function (jqXHR) {
			if(options.onError) {
				options.onError(jqXHR);
			}
		}
	}));

	if(options.loadOrders) {
		var orderFormIds = [];
		chain.add($.getPlicAPI({
			method: 'projects/' + options.plicProjectId + '/studio-orders',
			params: {
				include_catalog_products: true,
				sort: 'created_at',
				direction: 'asc',
				limit: SUBJECT_PAGE_COUNT,
				page: 1
			},
			type: 'GET',
			timeout: 20000,
			success: function(data) {
				sideloadOrderData(data);
				loadOrderForms(chain, data, orderFormIds, orderForms, plicLayouts);
				orders = data.studio_orders;

				for(var i = 1; i < data.page.total_pages; i++) {
					chain.add($.getPlicAPI({
						method: 'projects/' + options.plicProjectId + '/studio-orders',
						params: {
							include_catalog_products: true,
							sort: 'created_at',
							direction: 'asc',
							limit: SUBJECT_PAGE_COUNT,
							page: i + 1
						},
						type: 'GET',
						timeout: 20000,
						success: function(data) {
							sideloadOrderData(data);
							loadOrderForms(chain, data, orderFormIds, orderForms, plicLayouts);
							$.merge(orders, data.studio_orders);
						},
						error: function(jqXHR) {
							if(options.onError) {
								options.onError(jqXHR);
							}
						}
					}));
				}
			},
			error: function(jqXHR) {
				if(options.onError) {
					options.onError(jqXHR);
				}
			}
		}));

		// eslint-disable-next-line
		function sideloadOrderData(data) {
			// NOTE: Do NOT change anything here unless we are adding the same changes to render-layout-manager loading of orders
			if(data.catalog_products) {
				data.studio_orders.forEach(function(order) {
					if(!order.catalog_id) {
						return;
					}

					order.ordered_packages.forEach(function(orderedPackage) {
						orderedPackage.ordered_products.forEach(function(product) {
							var matchingCatalogProduct = data.catalog_products.filter(function(catalogProduct) {
								return catalogProduct.catalog_id === order.catalog_id && catalogProduct.sku === product.sku;
							})[0];
							if(matchingCatalogProduct) {
								$.extend(product, matchingCatalogProduct);
								delete product.catalog_id;
							}
						});
					});
				});
			}

			if(data.imagequix_orders) {
				data.studio_orders.forEach(function(order) {
					var iqOrder = data.imagequix_orders.find(function(iqOrder) {
						return iqOrder.id === order.imagequix_order_id;
					});
					if(iqOrder) {
						order.imagequix_order_number_id = iqOrder.imagequix_order_id;
					}
				});
			}

			// Sideload photos
			if(data.photos) {
				data.studio_orders.forEach(function(order) {
					order.ordered_packages.forEach(function(orderedPackage) {
						orderedPackage.ordered_products.forEach(function(product) {
							product.ordered_photos.forEach(function(photo) {
								if(photo.background_photo_id) {
									var backgroundPhoto = data.photos.find(function(otherPhoto) {
										return otherPhoto.id === photo.background_photo_id
									});
									if(backgroundPhoto) {
										photo.background_photo = {
											id: backgroundPhoto.id,
											original_file_name: backgroundPhoto.upload_file_name,
											download_url: backgroundPhoto.presigned_get
										};
									}
								}
							});
						});
					});
				});
			}

			let batches = data.studio_order_batches || [];
			data.studio_orders.forEach(order => {
				if(order.shipping_direct) {
					order.direct_shipping_address = order.shipping_address;
				}
				if(!order.studio_order_batch_id) {
					return;
				}
	
				let batch = batches.find(batch => batch.id === order.studio_order_batch_id);
				if(!batch) {
					return;
				}
	
				order.studio_order_batch_number = batch.studio_batch_number;
				order.studio_order_batch_created_at = batch.created_at;

				if(!order.shipping_direct || batch.override_direct_shipping) {
					let batchShippingAddress = (data.addresses || []).find(address => address.id === batch.shipping_address_id);
					if(batchShippingAddress) {
						order.shipping_address = batchShippingAddress;
					}
				}
			});
		}

		// eslint-disable-next-line
		function loadOrderForms(chain, data, orderFormIds, orderForms, plicLayouts) {
			data.studio_orders.forEach(function(order) {
				if(order.order_form_id && orderFormIds.indexOf(order.order_form_id) === -1) {
					orderFormIds.push(order.order_form_id);

					chain.add($.getPlicAPI({
						method: 'order-forms/' + order.order_form_id,
						params: {
							include_packages: true,
							include_layouts: true
						},
						type: 'GET',
						timeout: 20000,
						success: function(data) {
							orderForms.push(data.order_form);
							$.merge(plicLayouts, data.plic_layouts);
						}
					}));
				}
			});
		}
	}

	if(!options.chain) {
		chain.done();
	}

	function sideloadOrderForms(orders, orderForms, plicLayouts) {
		// NOTE: Do NOT change anything here unless we are adding the same changes to render-layout-manager loading of orders
		orderForms.forEach(function(orderForm) {
			orders.forEach(function(order) {
				if(order.order_form_id !== orderForm.id) {
					return;
				}
	
				order.ordered_packages.forEach(function(orderedPackage) {
					var catalogPackage = orderForm.catalog_packages.find(function(catalogPackage) {
						return catalogPackage.sku === orderedPackage.sku;
					});
					if(catalogPackage) {
						orderedPackage.price = catalogPackage.price;
						orderedPackage.name = catalogPackage.name;
						orderedPackage.description = catalogPackage.description;
	
						orderedPackage.ordered_products.forEach(function(orderedProduct) {
							var catalogPackageItem = (catalogPackage.catalog_package_items || []).find(function(item) {
								return item.id === orderedProduct.catalog_package_item_id;
							});
							if(catalogPackageItem) {
								orderedProduct.quantity = catalogPackageItem.quantity;
	
								if(catalogPackageItem.plic_layout_id) {
									var plicLayout = plicLayouts.find(function(layout) {
										return layout.id === catalogPackageItem.plic_layout_id;
									});
									if(plicLayout) {
										orderedProduct.plicLayoutName = plicLayout.name;
									}
								}
							}
						});
					}
				});
	
				// Pull this info from order form if we can
				var prices = order.ordered_packages.map(function(orderedPackage) {
					if(orderedPackage.price) {
						return orderedPackage.price.amount * orderedPackage.quantity;
					} else {
						return 0;
					}
				});
				order.order_package_price_cents = prices.reduce(function(total, price) {
					return price + total;
				}, 0);
			});
		});
	}
};