$.PAGE_HEIGHT = 11.25;
$.PAGE_WIDTH = 8.75;
// 70 is the baseline (print width/inches: 595/8.5 = 70)
$.FONT_MULTIPLIER = 70;
// 1pt = 1/72 of an inch.  1px = 0.75pt.  px = 1/96 of an inch
$.PRODUCTION_RATIO = 96;
$.PDF_PIXEL_RATIO = 72;
$.PAGE_BLEED = 0.125;
$.PAGE_OFFSET = 0;

// TODO: This needs to be loaded dynamically
$.DEFAULT_CLASS_LAYOUT = {
	schema: 4,
	row: {
		position: 'outside',
		nameInsidePadding: 0.1
	},
	name: {
		order: '%first% %last%',
		align: 'inside'
	},
	cell: {
		default: 'Small',
		subjectSwappable: true,
		alignCells: 'outside'
	}
};