import { client } from './axios';

export async function sleep(ms) {
	await new Promise(resolve => setTimeout(resolve, ms));
}

export async function waitForWorker(worker, interval = 2000) {
	let complete = false;

	while(!complete) {
		let { data } = await client.get(`ajax/getWorkerStatus.php?token=${worker}`);
		if(data.status == 'invalid' || data.status == 'failed') {
			throw new Error(data.status);
		} else if(data.status === 'complete') {
			complete = true;
		} else {
			await sleep(interval);
		}
	}
}