<template>
	<div class="comment" :class="{ 'is-last-row': isLastRow }">
		<v-text-field label="Row Name" :value="row.name" @input="changeRowName" class="row-name-field" :disabled="disabled" v-if="rowNameEditable" />
		<h4 class="row-name-label" label :ripple="false" v-else>{{ row.name }}:</h4>

		<multiple-search-input 
			v-if="names"
			:label="textLabelComputed"
			:value="row.comment"
			:options="names"
			@input="changeRowValue"
			class="row-comment"
			:disabled="disabled" />
		<v-textarea
			:label="textLabelComputed"
			:value="row.comment"
			@input="changeRowValue"
			class="row-comment text-area"
			:disabled="disabled"
			auto-grow
			no-resize
			rows="1"
			dense
			@keydown.enter.prevent
			v-else />

		<v-btn color="error" fab x-small :style="deleteButtonStyle" @click="deleteRow" class="delete-button"><v-icon>clear</v-icon></v-btn>
	</div>
</template>

<script>
import MultipleSearchInput from '../../../../PlicCanvas/vue/common/MultipleSearchInput';

export default {
	props: ['row', 'render', 'lastRowId', 'disabled', 'names', 'rowNameEditable', 'textLabel'],
	computed: {
		deleteButtonStyle() {
			if(this.canDeleteComment) {
				return {};
			} else {
				return {
					visibility: 'hidden'
				}
			}
		},
		canDeleteComment() {
			if(this.disabled) {
				return false;
			}

			if(this.lastRowId > 1) {
				return this.row.id === `row-${this.lastRowId}`;
			} else {
				return false;
			}
		},
		isLastRow() {
			return this.row.id === `row-${this.lastRowId}`;
		},
		textLabelComputed() {
			return this.textLabel ?? 'List of people in this row';
		}
	},
	methods: {
		changeRowName(value) {
			this.render.jsonSubPropertyChange('comments', this.row.id, 'name', value, {
				permanent: true,
				mergeSub: true
			});
		},
		changeRowValue(value) {
			this.render.jsonSubPropertyChange('comments', this.row.id, 'comment', value.replace(/[\n\r]/g, ''), {
				permanent: true,
				mergeSub: true
			});
		},

		deleteRow() {
			this.render.jsonSubPropertyChange('comments', this.row.id, 'deleted', true, {
				permanent: true,
				mergeSub: true
			});
		}
	},
	components: {
		MultipleSearchInput
	}
};
</script>

<style scoped>
.comment {
	display: flex;
	flex-direction: row;
}

.comment:deep(.multiple-search-input), .comment:deep(.text-area), .comment:deep(.row-name) {
	align-items: end;
}
.comment:deep(.text-area > .v-input__control), .comment:deep(.multiple-search-input > .v-input__control) {
	margin-bottom: 4px;
}

.row-name-field, .row-name-label {
	margin-right: 0.5em;
	flex-grow: 0;
}
.row-name-field {
	width: 25%;
	align-self: end;
}
.row-name-label {
	width: 20%;
}


.is-last-row {
	margin-bottom: 2em;
}

.delete-button {
	margin-left: 1em;
}
</style>