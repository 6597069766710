$.GlobalGoogleFontList = [
	'Overlock',
	'Crafty Girls',
	'Noto Sans',
	'Ubuntu',
	'Lobster',
	'Anton',
	'Shadows Into Light',
	'Libre Baskerville',
	'Sigmar One',
	'Open Sans',
	'Oswald',
	'Indie Flower',
	'Pacifico',
	'Pathway Gothic One',
	'Dancing Script',
	'Coming Soon',
	'Courgette',
	'Bangers',
	'Luckiest Guy',
	'Homemade Apple',
	'Alfa Slab One',
	'Modak',
	'Seymour One',
	'Permanent Marker',
	'Eater',
	'Erica One',
	'Petit Formal Script',
	'Fascinate Inline',
	'Fredoka One',
	'Gloria Hallelujah',
	'Fontdiner Swanky',
	'Tangerine',
	'Monofett',
	'Diplomata SC',
	'Pirata One',
	'Love Ya Like A Sister',
	'Londrina Shadow',
	'Architects Daughter',
	'Montserrat Subrayada',
	'Kavoon',
	'Rubik Mono One',
	'Diplomata',
	'Chewy',
	'Faster One',
	'Berkshire Swash',
	'Abril Fatface',
	'Rock Salt',
	'Bonbon',
	'Lobster Two',
	'Ewert',
	'Playball',
	'Kaushan Script',
	'Emilys Candy',
	'Bubblegum Sans',
	'Cabin',
	'Cabin Sketch',
	'Fredericka the Great',
	'Mystery Quest',
	'Henny Penny',
	'Press Start 2P',
	'EB Garamond',
	'Cormorant Garamond',
	'Pinyon Script',
	'Griffy',
	'Finger Paint',
	'Croissant One',
	'Spirax',
	'Dekko',
	'Roboto',
	'Roboto Condensed',
	'Roboto Slab',
	'Roboto Mono',
	'Orbitron',
	'Exo',
	'Nanum Gothic',
	'Allerta Stencil',
	'Raleway Dots',
	'Special Elite',
	'Unica One',
	'Monoton',
	'Amatic SC',
	'Bungee Shade',
	'Nova Mono',
	'Quicksand',
	'Catamaran',
	'Notable',
	'Ubuntu Condensed',
	'Fira Sans',
	'Felipa',
	'Italianno',
	'Quintessential',
	'Cinzel Decorative',
	'Cherry Swash',
	'Milonga',
	'Eczar',
	'Josefin Slab',
	'Vast Shadow',
	'Glass Antiqua',
	'Elsie',
	'Abel',
	'Amarante',
	'Amiri',
	'Arimo',
	'Candal',
	'Carter One',
	'Coda Caption',
	'Eagle Lake',
	'Germania One',
	'Gentium Book Basic',
	'Gorditas',
	'Halant',
	'Hind',
	'Julius Sans One',
	'Lateef',
	'Lemonada',
	'Limelight',
	'Lustria',
	'Karma',
	'Martel',
	'Marcellus',
	'Merriweather',
	'Mitr',
	'Niconne',
	'Oxygen',
	'Parisienne',
	'Passion One',
	'Poppins',
	'Playfair Display',
	'PT Serif',
	'Raleway',
	'Questrial',
	'Source Sans Pro',
	'Spectral',
	'Tinos',
	'UnifrakturMaguntia',
	'Varela Round',
	'Yantramanav',
	'Old Standard TT',
	'Rufina',
	'Libre Franklin',
	'Tillana',
	'Kalam',
	'Annie Use Your Telescope',
	'Cookie',
	'Chelsea Market',
	'Mr Dafoe',
	'Lato',
	'PT Sans',
	'Lora',
	'Work Sans',
	'Cairo',
	'BioRhyme',
	'Fjalla One',
	'Rubik',
	'Rakkas',
	'Arvo',
	'Satisfy',
	'Shadows Into Light Two',
	'Reenie Beanie',
	'Liu Jian Mao Cao',
	'Cedarville Cursive',
	'Barriecito',
	'Sue Ellen Francisco',
	'Dawning of a New Day',
	'Montserrat',
	'Libre Caslon Display',
	'Libre Caslon Text',
	'Bebas Neue',
	'Galada',
	'Stint Ultra Condensed',
	'Kranky',
	'Black Ops One',
	'Miss Fajardose',
	'Ruthie',
	'Butterfly Kids',
	'Cinzel',
	'Aladin',
	'Mouse Memoirs',
	'Give You Glory',
	'Lemon',
	'Allura',
	'Alex Brush',
	'Arizonia',
	'Bilbo Swash Caps',
	'Bilbo',
	'Euphoria Script',
	'Qwigley',
	'Yellowtail',
	'Noto Serif JP',
	'Sawarabi Mincho',
	'Noto Sans JP',
	'M PLUS 1p',
	'Londrina Sketch',
	'Zilla Slab'
];
$.GoogleFontList = $.merge([], $.GlobalGoogleFontList);

// Update worker/lib/render/font-caching whenever these change
$.GoogleFontSpecialTitleCaseMap = {
	'fredericka the great': 'Fredericka the Great',
	'press start 2p': 'Press Start 2P',
	'eb garamond': 'EB Garamond',
	'diplomata sc': 'Diplomata SC',
	'amatic sc': 'Amatic SC',
	'pt serif': 'PT Serif',
	'unifrakturmaguntia': 'UnifrakturMaguntia',
	'old standard tt': 'Old Standard TT',
	'pt sans': 'PT Sans',
	'biorhyme': 'BioRhyme',
	'dawning of a new day': 'Dawning of a New Day',
	'noto serif jp': 'Noto Serif JP',
	'noto sans jp': 'Noto Sans JP',
	'm plus 1p': 'M PLUS 1p',
	'over the rainbow': 'Over the Rainbow',
	'code128a': 'Code128A',
	'code128b': 'Code128B',
	'code128c': 'Code128C',
	'qr code': 'QR Code',
	'ean13': 'EAN13'
};

// Make sure to upload flow-layout.css .flowPage { font-family: ... } when changing this list
$.GoogleFontDefaultFallbacks = '"open sans", "noto sans jp", "noto sans arabic", "noto sans tibetan", "noto sans telugu", "noto sans yi", "noto sans gujarati", "noto sans javanese", "noto sans oriya", "noto sans math", "emoji", "noto sans cjk", "free serif"';
// eslint-disable-next-line
$.BarcodeIllegalCharacters = /[\*\_\[\]\\\t\\\n\\\r`"!'#&():,;?]/ig;
// eslint-disable-next-line
$.NonASCIICharacterTest = /[^\x00-\x7F]/g;