import Vue from 'vue';

Vue.directive('horizontal-scroll', {
	inserted: function (el) {
		Vue.nextTick(() => {
			setupScrollbar(el);
		});
	},
	updated(el) {
		setupScrollbar(el);
	}
});

function setupScrollbar(el) {
	if(!el.scrollbarSetup) {
		el.addEventListener('mousewheel', (event) => scrollHorizontally(el, event), false);
		el.addEventListener('DOMMouseScroll', (event) => scrollHorizontally(el, event), false);
		el.scrollbarSetup = true;

		// el.style['scroll-behavior'] = 'smooth';
	}
}

function scrollHorizontally(el, event) {
	let delta = Math.max(-1, Math.min(1, (event.wheelDelta || -event.detail)));
	let scrollBy = delta * -window.outerWidth / 10;

	el.scrollLeft += scrollBy;
	event.preventDefault();
}