$.Composites = function(progressIndicator, usersLoggedInLabels) {
	var obj = new $.FlowPageClassSet({});

	$.extend(obj, {
		load: function (events) {
			var me = this;
			this.db.load($.extend({
				onLoadGlobals: function (data) {
					me.classes = data.classes;
					if (data.layout) {
						me.layout = JSON.parse(data.layout);
					}
					if (data.status) {
						me.status = data.status;
					} else {
						me.status = 'In Work';
					}
					if (data.pageMargins) {
						me.pageMargins = data.pageMargins;
					}
					me.compositeProofId = data.compositeProofId;
				}
			}, events));
		},

		setPage: function (i) {
			this.page = i;
		},
		previousPage: function () {
			var even = (this.page % 2) == 0;
			this.page = this.page - (even ? 2 : 3);
		},
		nextPage: function () {
			this.page = this.page + 2;
		},
		getCurrentPages: function () {
			return [this.pages[this.page], this.pages[this.page + 1]];
		},
		getCurrentPage: function () {
			return this.pages[this.page];
		},
		getCurrentPageNumber: function () {
			return this.page;
		},
		getStatus: function () {
			return this.status;
		},
		setStatus: function (status) {
			if (this.status != status) {
				this.db.queueChange({
					scope: 'yearbook',
					name: 'status',
					value: status
				});
			}

			this.status = status;
		},
		renderBatches: function() {
			var form = $('<div class="ui form settings-builder">');
			var fields = $('<div class="three fields">').appendTo(form);

			var pages = $.merge([], this.pages);
			pages.forEach(function(page) {
				var name = page.getTitleText();

				var checkbox = $('<div class="ui checkbox batchCheckbox"><input type="checkbox"><label>' + name + '</label></div>');
				checkbox.data('pageId', page.id);
				checkbox.checkbox({
					onChange: function() {
						form.removeClass('error');
					}
				});

				$('<div class="inline field">').append(checkbox).appendTo(fields);
			});

			var selectAll = $('<div style="margin-top: 1em" class="ui checkbox selectAllCheckbox"><input type="checkbox" name="selectAllBox"><label style="font-weight: bold;">Select All</label></div>');
			selectAll.checkbox({
				onChange: function() {
					var toggle = selectAll.checkbox('is checked');

					if (toggle) {
						form.find('.batchCheckbox').checkbox('check');
					} else {
						form.find('.batchCheckbox').checkbox('uncheck');
					}
				}
			});
			$('<div class="inline field">').append(selectAll).appendTo(form);
			form.append('<div class="ui error message">');

			var productSKUs = $.getProjectSetting('individualizedCompositeSkus');
			var productField = $.getProjectSetting('individualizedCompositeOrderField');
			var checkProductSKUs = !!(productField && productSKUs && productSKUs.length);
			if(checkProductSKUs) {
				var fieldName = $.getProjectSetting('individualizedCompositeOrderField') === 'Package SKU' ? 'package' : 'product';
				var useProductInfoCheckbox = $('<div style="margin-top: 1em" class="ui checkbox useProductInfoCheckbox checked"><input type="checkbox" name="useProductInfoBox" checked><label>Render subjects with an ordered ' + fieldName + ' with the SKU ' + productSKUs.joinAnd(', ', ' or ') + '</label></div>');
				useProductInfoCheckbox.checkbox({
					onChange: function() {
						checkProductSKUs = useProductInfoCheckbox.checkbox('is checked');
					}
				});
				$('<div class="inline field">').append(useProductInfoCheckbox).appendTo(form);
			}

			var uploadGroupImage = false;
			var uploadGroupImageCheckbox = $('<div style="margin-top: 1em" class="ui checkbox uploadGroupImageCheckbox"><input type="checkbox" name="uploadGroupImageBox"><label>Upload rendered composite as a group image to subjects</label></div>');
			uploadGroupImageCheckbox.checkbox({
				onChange: function() {
					uploadGroupImage = uploadGroupImageCheckbox.checkbox('is checked');
				}
			});
			$('<div class="inline field">').append(uploadGroupImageCheckbox).appendTo(form);

			var me = this;
			$('<div class="ui modal"><i class="close icon"></i><div class="header">Render Batches</div></div>')
				.append($('<div class="content">').append(form))
				.append('<div class="actions"><div class="ui negative button">Cancel</div><div class="ui positive button">Render Batch</div></div>')
				.modal({
					onApprove: function() {
						var pagesToRender = [];
						form.find('.batchCheckbox.checked').each(function () {
							var pageId = $(this).data('pageId');
							
							var page = me.getPageById(pageId);
							if(page) {
								pagesToRender.push(page);
							}
						});

						if(!pagesToRender.length) {
							form.addClass('error').find('.error.message').html('You must select at least one batch to render.</p>');
							return false;
						}

						me.renderBatchesStart(pagesToRender, {
							checkProductSKUs: checkProductSKUs,
							extraPostData: {
								uploadGroupImage: uploadGroupImage
							}
						});
					},
					onHidden: function() {
						$(this).remove();
					}
				}).modal('show');
		},
		renderBatchesStart: function(pagesToRender, options) {
			var pageSet = new $.FlowPageSubSet(this, pagesToRender);
			var render = new $.PageRender(pageSet, $.extend(true, {
				production: true,
				includePageTitles: true,
				includeBatchIds: true,
				isDemo: this.isDemoLicense(),
				showDownloadButton: true,
				batchName: pagesToRender.length + ' batches',
				forceSide: 'Right',
				backgroundRender: true,
				includeBatches: true
			}, options));
			render.showImageDialog();
		},
		isDemoLicense: function() {
			return $.plicLicenseFeatures['is-demo'];
		},

		page: 0,
		jobId: $.getGETParams().jobId,
		status: 0,
		containTextInParent: false,
		referencePagesBy: 'getTitleText'
	});

	obj.db = new $.CompositesDB(obj, progressIndicator, usersLoggedInLabels);
		
	return obj;
};
