$.FlowLayoutFlowContent = function(options) {
	var DEFAULT_Z_INDEX = 50;
	var DESTINATION_ALL = '_all_';

	var me = document.createElement('div');
	me.className = 'flowContent';

	$.extend(me, {
		position: function () {
			$(this).css({
				left: this.x,
				top: this.y,
				position: 'absolute'
			});
		},

		hideOverlap: function (elements, helper) {
			var me = this;
			if (!helper) {
				if (this.currentHelper) {
					helper = this.currentHelper;
				} else {
					helper = this;
				}
			}

			var original = $();
			if (this.hiddenCells) {
				original = this.hiddenCells;
			}

			if(this.instance.behindCells || (this.instance.overlayCells && !this.hideCompletelyOverlayedCells) || !$(this).isAttached()) {
				this.hiddenCells = $();
			} else {
				var page = this.getPage();
				var contentOverlapSubjectLabels = !page || page.doesContentOverlapSubjectLabels();

				this.hiddenCells = $(helper).overlaps(elements, {
					includeBaseLabelHeight: !contentOverlapSubjectLabels,
					ratio: this.ratio
				});

				// Even with the overlayCells property, we want to be sure that any cells that are 100% covered are moved so we don't accidentally print a book with a missing kid
				if(this.instance.overlayCells) {
					const MIN_THRESHOLD = 3 / $.PRODUCTION_RATIO * this.ratio;
					let myRect = this.getBoundingClientRect();
					this.hiddenCells = this.hiddenCells.filter(function() {
						let cellRect;
						if(this.checkOverlapBoundsAgainst) {
							cellRect = this.checkOverlapBoundsAgainst.getBoundingClientRect();
						} else {
							cellRect = this.getBoundingClientRect();
						}

						return (cellRect.x + MIN_THRESHOLD) > myRect.x && (cellRect.y + MIN_THRESHOLD) > myRect.y
							&& (cellRect.x + cellRect.width - MIN_THRESHOLD) < (myRect.x + myRect.width)
							&& (cellRect.y + cellRect.height - MIN_THRESHOLD) < (myRect.y + myRect.height);
					});
				}

				this.hiddenCells.each(function () {
					$(this).data('behindFrames').push(me);
				});
				this.hiddenCells.addClass('hiddenCell behindFrame');
			}

			var diff;
			if (original.length) {
				diff = original.not(this.hiddenCells).get();
				$(diff).each(function () {
					if ($(this).hasClass('hiddenCell') && $.contains(document.documentElement, this)) {
						var frames = $(this).data('behindFrames');
						for (var i = 0; i < frames.length; i++) {
							if (frames[i] == me) {
								frames.splice(i, 1);
								i--;
							}
						}

						if (frames.length == 0) {
							$(this).removeClass('hiddenCell behindFrame');
						}
					}
				});
				diff = $.merge(diff, this.hiddenCells.not(original).get());
			} else {
				diff = this.hiddenCells;
			}
			return diff.length;
		},
		removeHidden: function () {
			if (this.hiddenCells) {
				var me = this;
				var length = this.hiddenCells.length;
				this.hiddenCells.each(function () {
					if ($(this).hasClass('hiddenCell')) {
						var frames = $(this).data('behindFrames');
						if (!frames) {
							return;
						}

						for (var i = 0; i < frames.length; i++) {
							if (frames[i] == me) {
								frames.splice(i, 1);
								i--;
							}
						}

						if (frames.length == 0) {
							$(this).removeClass('hiddenCell').removeClass('behindFrame');
						}
					}
				});
				this.hiddenCells = null;
				return length;
			} else {
				return 0;
			}
		},

		lowerZIndex: function () {
			var elements = this.getFocusedElementsByZIndex();
			var z = elements[elements.length - 1].instance.zIndex || 0;
			let siblingFrames = this.getSiblingElements(elements);
			var overlaps = $();
			elements.forEach(function(element) {
				overlaps = overlaps.add($(element).overlaps(siblingFrames));
			});

			var lowerOverlappedFrames = [];
			$.each(overlaps, function() {
				if((this.definition.zIndex || 0) <= z) {
					lowerOverlappedFrames.push(this);
				}
			});

			let notOverlappedElements = siblingFrames.not(overlaps).not(elements);
			let lowerNotOverlappedElements = [];
			$.each(notOverlappedElements, function() {
				if((this.definition.zIndex || 0) <= z) {
					lowerNotOverlappedElements.push(this);
				}
			});

			lowerOverlappedFrames.sort(function(a, b) {
				var aZIndex = $.isInit(a.instance.zIndex) ? a.instance.zIndex : 0;
				var bZIndex = $.isInit(b.instance.zIndex) ? b.instance.zIndex : 0;

				return bZIndex - aZIndex;
			});
			lowerNotOverlappedElements.sort(function(a, b) {
				var aZIndex = $.isInit(a.instance.zIndex) ? a.instance.zIndex : 0;
				var bZIndex = $.isInit(b.instance.zIndex) ? b.instance.zIndex : 0;

				return bZIndex - aZIndex;
			});
			let lowerZIndexesToSkip = lowerNotOverlappedElements.map(elem => elem.instance.zIndex);

			if(lowerOverlappedFrames.length) {
				var nextZIndex = lowerOverlappedFrames[0].instance.zIndex || 0;
				while(lowerZIndexesToSkip.includes(nextZIndex - elements.length)) {
					nextZIndex--;
				}

				for(let i = 0; i < elements.length; i++) {
					var element = elements[i];
					let z = nextZIndex - (elements.length - i);
					while(lowerZIndexesToSkip.includes(z)) {
						z--;
					}

					element.updateProperty('zIndex', z);
					element.applyZIndex();
				}

				let changedZIndexes = elements.map(element => element.instance.zIndex || 0);
				let skipIndexes = 0;
				// By default, last element has higher implicit z-index than first element
				// If multiple frames, decrement all but one by 2 so this is sandwiched between them
				for(let i = 1; i < lowerOverlappedFrames.length; i++) {
					var frame = lowerOverlappedFrames[i];
					if(Math.min(...changedZIndexes) > frame.instance.zIndex && !changedZIndexes.includes(frame.instance.zIndex)) {
						continue;
					}
					let z = nextZIndex - elements.length - i - skipIndexes;
					while(lowerZIndexesToSkip.includes(z)) {
						z--;
						skipIndexes++;
					}

					changedZIndexes.push(z);
					frame.updateProperty('zIndex', z);
					frame.applyZIndex();
				}
			} else if(this.instance.overlayCells) {
				this.updateProperty('overlayCells', false);

				this.applyZIndex();
				this.movingContent();
				this.stopMoving();
			} else if(this.checkIsBlockingUsedCell()) {
				this.updateProperty('behindCells', true);

				this.applyZIndex();
				this.movingContent();
				this.stopMoving();
			} else if(!this.instance.behindCells) {
				var titles = $(this.wrapper.canvasMargins).find('.flowTitle');
				var overlapsTitle = false;
				elements.forEach(function(element) {
					titles.each(function() {
						if($(element).overlaps(this).length) {
							overlapsTitle = true;
							return false;
						}
					});
				});

				if(overlapsTitle) {
					this.updateProperty('behindCells', true);

					this.applyZIndex();
					this.movingContent();
					this.stopMoving();
				}
			}
		},
		raiseZIndex: function () {
			var elements = this.getFocusedElementsByZIndex();
			var z = elements[0].instance.zIndex || 0;
			let siblingFrames = this.getSiblingElements(elements);
			var overlaps = $();
			elements.forEach(function(element) {
				overlaps = overlaps.add($(element).overlaps(siblingFrames));
			});

			let higherOverlappedFrames = [];
			$.each(overlaps, function () {
				if ((this.definition.zIndex || 0) >= z) {
					higherOverlappedFrames.push(this);
				}
			});
			let notOverlappedElements = siblingFrames.not(overlaps).not(elements);
			let higherNotOverlappedElements = [];
			$.each(notOverlappedElements, function() {
				if((this.definition.zIndex || 0) >= z) {
					higherNotOverlappedElements.push(this);
				}
			});

			higherOverlappedFrames.sort(function(a, b) {
				var aZIndex = $.isInit(a.instance.zIndex) ? a.instance.zIndex : 0;
				var bZIndex = $.isInit(b.instance.zIndex) ? b.instance.zIndex : 0;

				return aZIndex - bZIndex;
			});
			higherNotOverlappedElements.sort(function(a, b) {
				var aZIndex = $.isInit(a.instance.zIndex) ? a.instance.zIndex : 0;
				var bZIndex = $.isInit(b.instance.zIndex) ? b.instance.zIndex : 0;

				return aZIndex - bZIndex;
			});
			let higherZIndexesToSkip = higherNotOverlappedElements.map(elem => elem.instance.zIndex);

			if(higherOverlappedFrames.length) {
				var nextZIndex = higherOverlappedFrames[0].instance.zIndex || 0;
				while(higherZIndexesToSkip.includes(nextZIndex + 1)) {
					nextZIndex++;
				}

				for(let i = 0; i < elements.length; i++) {
					var element = elements[i];
					let z = nextZIndex + i + 1;
					while(higherZIndexesToSkip.includes(z)) {
						z++;
					}

					element.updateProperty('zIndex', z);
					element.applyZIndex();
				}

				let changedZIndexes = elements.map(element => element.instance.zIndex || 0);
				let skipIndexes = 0;
				// By default, last element has higher implicit z-index than first element
				// If multiple frames, decrement all but one by 2 so this is sandwiched between them
				for(let i = 1; i < higherOverlappedFrames.length; i++) {
					var frame = higherOverlappedFrames[i];
					if(Math.max(...changedZIndexes) < frame.instance.zIndex && !changedZIndexes.includes(frame.instance.zIndex)) {
						continue;
					}
					let z = nextZIndex + elements.length + i + skipIndexes;
					while(higherZIndexesToSkip.includes(z)) {
						z++;
						skipIndexes++;
					}

					changedZIndexes.push(z);
					frame.updateProperty('zIndex', z);
					frame.applyZIndex();
				}
			} else if(this.instance.behindCells) {
				this.updateProperty('behindCells', false);

				this.applyZIndex();
				this.movingContent();
				this.stopMoving();
			} else if(!this.instance.overlayCells && this.checkIsBlockingUsedCell()) {
				this.updateProperty('overlayCells', true);

				this.applyZIndex();
				this.movingContent();
				this.stopMoving();
			}
		},
		checkIsBlockingUsedCell: function() {
			if(this.hiddenCells && this.hiddenCells.length) {
				let cells = this.wrapper.getCells().toArray();

				// We want to go behind cells if we are overlapping label even if we don't hide the cell from only overlapping the label
				let hiddenCells;
				let page = this.getPage();
				let contentOverlapSubjectLabels = !page || page.doesContentOverlapSubjectLabels();
				if(contentOverlapSubjectLabels) {
					hiddenCells = $(this).overlaps(cells, {
						includeBaseLabelHeight: true,
						ratio: this.ratio
					});
				} else {
					hiddenCells = this.hiddenCells;
				}

				// We aren't currently tracking which cells *should* have had a user in it
				// Users are placed until there is no more room so we should just be able to check if any cells we have hidden are within the range of cells users are put in
				// One exception is going to be merged batches which might have 4 on one line and 6 on the next without anything blocking the cells
				let hiddenIndexes = hiddenCells.toArray().map(cell => cells.indexOf(cell));
				let reversedCells = [...cells].reverse();
				let lastUserIndex = (cells.length - 1) - (reversedCells.findIndex(cell => !!cell.user));
				// On right aligned layouts we need to check if something blocking just the right cell in last row is blocked
				if($(cells[lastUserIndex]).parent().hasClass('right-aligned')) {
					let lastUserRow = $(cells[lastUserIndex]).parent()[0];
					let lastUserRowCell = lastUserRow.cells.at(-1);
					lastUserIndex = cells.findIndex(cell => cell === lastUserRowCell);
				}

				let minHiddenIndex = Math.min(...hiddenIndexes);
				return minHiddenIndex <= lastUserIndex;
			} else {
				return false;
			}
		},
		getFocusedElementsByZIndex: function() {
			var elements = $.merge([], this.secondaryFocusedElements);
			if(this.primaryFocusedElement) {
				elements.push(this.primaryFocusedElement);

				$.merge(elements, this.primaryFocusedElement.secondaryFocusedElements);
			} else {
				elements.push(this);
			}

			this.withLinkedElem((linkedLayout, linkedElem) => {
				elements.push(linkedElem);
			});

			elements.sort(function(a, b) {
				var aZIndex = $.isInit(a.instance.zIndex) ? a.instance.zIndex : 0;
				var bZIndex = $.isInit(b.instance.zIndex) ? b.instance.zIndex : 0;

				return aZIndex - bZIndex;
			});

			return elements;
		},
		getSiblingElements: function(elements) {
			let siblings = $(this).siblings('.flowLayoutMovable').not(elements);

			this.withLinkedElem((linkedLayout, linkedElem) => {
				let linkedOverflowSiblings = $(linkedElem).siblings('.flowLayoutMovable').not(elements);
				siblings = siblings.add(linkedOverflowSiblings);
			});
			siblings = siblings.filter(function() {
				return !!this.definition;
			});

			return siblings;
		},
		applyZIndex: function () {
			var z = this.definition.zIndex ? this.definition.zIndex : 0;

			var baseZIndex = DEFAULT_Z_INDEX;
			if(this.primaryFocusedElement) {
				baseZIndex = 1000;
			} else if(this.instance.behindCells) {
				baseZIndex = -50;
			}

			const important = this.focused ? 'important' : undefined;
			if (z) {
				this.style.setProperty('z-index', (z + baseZIndex), important);
			} else if(baseZIndex != DEFAULT_Z_INDEX) {
				this.style.setProperty('z-index', baseZIndex, important);
			} else {
				this.style['z-index'] = '';
			}
		},

		checkWithinBounds: function () {
			var parent = this.parentNode;
			if (parent) {
				var parentRect = parent.getBoundingClientRect();
				var myRect = this.getBoundingClientRect();

				// Try moving stuff away from top/left boundaries
				var moveDown = parentRect.top - myRect.top;
				var top;
				if (moveDown > 0) {
					top = parseFloat(this.style.top.replace('px', ''));
					top += moveDown;
					this.style.top = top + 'px';
				}

				var moveRight = parentRect.left - myRect.left;
				var left;
				if (moveRight > 0) {
					left = parseFloat(this.style.left.replace('px', ''));
					left += moveRight;
					this.style.left = left + 'px';
				}

				// Next move away from bottom/right boundaries
				parentRect = parent.getBoundingClientRect();
				myRect = this.getBoundingClientRect();
				var moveUp = myRect.bottom - parentRect.bottom;
				if (moveUp > 0) {
					if (!top) {
						top = parseFloat(this.style.top.replace('px', ''));
						top -= moveUp;
						this.style.top = top + 'px';
					} else {
						// If we already moved down, then this is too large and needs to be resized instead
						this.fitInParent(false);
						parentRect = parent.getBoundingClientRect();
						myRect = this.getBoundingClientRect();
					}
				}

				var moveLeft = myRect.right - parentRect.right;
				if (moveLeft > 0) {
					if (!left) {
						left = parseFloat(this.style.left.replace('px', ''));
						left -= moveLeft;
						this.style.left = left + 'px';
					} else {
						// If we already moved right, then this is too large and needs to be resized instead
						this.fitInParent(false);
					}
				}

				// Make sure that after moving left/up we are not too large
				parentRect = parent.getBoundingClientRect();
				myRect = this.getBoundingClientRect();
				if (myRect.top < parentRect.top || myRect.left < parentRect.left) {
					this.fitInParent(true);
				}
			}
		},
		fitInParent: function (snapRight) {
			if(!this.parentNode) {
				return;
			}

			var parentRect = this.parentNode.getBoundingClientRect();
			var myRect = this.getBoundingClientRect();
			var imgRatio = myRect.width / myRect.height;

			// Get height/width based on max available area
			var left = 0, top = 0;
			// Subtract img borders from available space
			var startWidth = parentRect.width - 2;
			var startHeight = parentRect.height - 2;
			var width = startWidth;
			var height = startHeight;

			if (imgRatio > 1) {
				// Wide
				height = width / imgRatio;
			} else {
				// Tall
				width = height * imgRatio;
			}

			// Make sure we didn't go out of bounds
			if (width > parentRect.width) {
				width = startWidth;
				height = width / imgRatio;
			} else if (height > parentRect.height) {
				height = startHeight;
				width = height * imgRatio;
			}

			// Need to scale back size due to rotation
			if (this.definition.transform) {
				var imgWidth = parseFloat(this.style.width.replace('px', ''));
				var imgHeight = parseFloat(this.style.height.replace('px', ''));

				var widthRatio = imgWidth / myRect.width;
				var heightRatio = imgHeight / myRect.height;

				height = height * heightRatio;
				width = width * widthRatio;
			} else if (snapRight) {
				left = parentRect.width - width;
			}

			this.style.left = left + 'px';
			this.style.top = top + 'px';
			this.style.width = width + 'px';
			this.style.height = height + 'px';

			// Might need to redo position if rotated
			if (this.definition.transform) {
				parentRect = this.parentNode.getBoundingClientRect();
				myRect = this.getBoundingClientRect();

				// Try moving stuff away from top/left boundaries
				var moveDown = parentRect.top - myRect.top;
				if (moveDown > 0) {
					top = parseFloat(this.style.top.replace('px', ''));
					top += moveDown;
					this.style.top = top + 'px';
				}

				var moveRight = parentRect.left - myRect.left;
				if (moveRight > 0) {
					left = parseFloat(this.style.left.replace('px', ''));
					left += moveRight;
					this.style.left = left + 'px';
				}
			}
		},

		movingContent: function (uiPosition, onMove, options) {
			if(!options) {
				options = {};
			}

			if(!uiPosition) {
				uiPosition = {};
			}

			this.movingCurrentPosition(uiPosition.left, uiPosition.top);
			if(options.snapToLines !== false) {
				this.snapToAlignmentLines(uiPosition, onMove);
			}
			this.checkOverflowToLinkedLayouts();
			if(options.showTooltip !== false) {
				this.updatePositionTooltip();
			}
			this.wrapper.onFlowChange(this);
		},
		movingCurrentPosition: function(newLeft, newTop) {
			var startLeft = $(this).getFloatStyle('left');
			var startTop = $(this).getFloatStyle('top');

			$(this).css({
				left: newLeft,
				top: newTop
			});

			if($.isInit(newLeft) && $.isInit(newTop)) {
				var diffLeft = newLeft - startLeft;
				var diffTop = newTop - startTop;

				this.getSecondaryFocusedElements().forEach(function(secondaryElement) {
					var secondaryLeft = $(secondaryElement).getFloatStyle('left');
					var secondaryTop = $(secondaryElement).getFloatStyle('top');

					$(secondaryElement).css({
						left: (secondaryLeft + diffLeft),
						top: (secondaryTop + diffTop)
					});
				});
			}
		},
		
		checkOverflowToLinkedLayouts: function() {
			if(!this.overflowToLinkedLayouts) {
				return;
			}

			var originContentRect = this.getBoundingClientRect();
			var startTransform = this.style.transform || this.style['-webkit-transform'];
			this.style.transform = this.style['-webkit-transform'] = '';
			var contentRect = this.getBoundingClientRect();
			if(startTransform) {
				this.style.transform = this.style['-webkit-transform'] = startTransform;
			}

			var rootRect = this.wrapper.container.getBoundingClientRect();
			var outOfBounds = this.checkOutOfBounds(originContentRect, contentRect, rootRect);
			for(var i = 0; i < outOfBounds.length; i++) {
				var outOfBound = outOfBounds[i];

				var linkedLayout;
				switch(outOfBound.direction) {
					case 'left':
						linkedLayout = this.wrapper.getLinkedLayout('previous');
						break;
					case 'right':
						linkedLayout = this.wrapper.getLinkedLayout('next');
						break;
				}

				if(linkedLayout) {
					this.overflowToLayout(linkedLayout, outOfBound, contentRect);
					return;
				}
			}

			// If we got here, no linked layout which was updated
			this.removeOverflowFromLayout();
		},
		checkOutOfBounds: function(originContentRect, contentRect, rootRect) {
			let minOverflow = 0;
			if(this.duplicateLinkedLayoutsInBleed) {
				minOverflow = 3;
			}

			var bounds = [];
			if((originContentRect.left + minOverflow) < (rootRect.left - this.wrapper.inchSafeSpace.left * this.wrapper.ratio)) {
				bounds.push({
					direction: 'left',
					amount: rootRect.left - contentRect.left - (this.wrapper.inchSafeSpace.left * this.wrapper.ratio)
				});
			}
			if((originContentRect.right - minOverflow) > rootRect.right + (this.wrapper.inchSafeSpace.right * this.wrapper.ratio)) {
				bounds.push({
					direction: 'right',
					amount: contentRect.right - rootRect.right - (this.wrapper.inchSafeSpace.right * this.wrapper.ratio)
				});
			}

			return bounds;
		},
		overflowToLayout: function(linkedLayout, outOfBounds, contentRect) {
			var linkedPage = linkedLayout.getPage();
			if(!linkedPage || linkedPage.getLocked() || linkedPage.getFreeMovementLocked()) {
				return;
			}

			this.overflowToLayoutPage(linkedLayout, linkedPage, outOfBounds, contentRect);
		},
		overflowToLayoutPage: function(linkedLayout, linkedPage, outOfBounds, contentRect) {
			var linkedInstanceSettings = this.getLinkedInstance(linkedPage);
			var linkedInstance = linkedInstanceSettings.instance;

			var linkedElem;
			if(linkedInstanceSettings.added) {
				if(this.contentType === 'frame') {
					linkedElem = linkedLayout.refreshFrame(linkedInstance.id);
				} else if(this.contentType.indexOf('text') !== -1) {
					linkedElem = linkedLayout.refreshText(linkedInstance.id);
				}
				
				linkedElem.setLinkedInstanceFocused(this.focused || this.secondaryFocused);
			} else if(linkedInstance.id) {
				linkedElem = linkedLayout.getContent(linkedInstance.id);
			}
			if(!linkedElem) {
				return;
			}
			this.updateLinkedInstance(linkedElem, outOfBounds, contentRect);

			this.linkedOverflowInstance = {
				instance: linkedInstance,
				layout: linkedLayout,
				elem: linkedElem
			};
		},
		removeOverflowFromLayout: function() {
			if(!this.linkedOverflowInstance) {
				return;
			}

			var linkedInstance = this.linkedOverflowInstance.instance;
			var linkedLayout = this.linkedOverflowInstance.layout;
			var linkedElem = linkedLayout.getContent(linkedInstance.id);
			if(linkedElem) {
				if(linkedElem.contentType === 'frame') {
					linkedLayout.removeFrame(linkedElem, true);
				} else if(linkedElem.contentType.indexOf('text') !== -1) {
					linkedLayout.removeText(linkedElem, true);
				}
			}

			this.linkedOverflowInstance = null;
		},
		getLinkedInstance: function(linkedPage) {
			var linkedInstance;
			if(this.contentType === 'frame') {
				linkedInstance = linkedPage.getCandid(this.instance.id);
			} else if(this.contentType === 'text-free') {
				linkedInstance = linkedPage.getText(this.instance.id);
			}

			// If it doesn't exist, add it
			var added = false;
			if(!linkedInstance) {
				this.changeInstanceProperty('duplicateInBleed', this.duplicateLinkedLayoutsInBleed);
				linkedInstance = $.extend(true, {}, this.instance);
				var linkedElem = this.addContentToPage(linkedPage, linkedInstance);
				$(linkedElem).addClass('flowLayoutSecondaryFocused');
				added = true;

				// Make sure that moves are correlated with overflow state changes
				this.flushPositionChangeImmediately = true;
			}

			return {
				instance: linkedInstance,
				added: added
			};
		},
		withLinkedElem: function(callback) {
			// Don't try to do anything if we don't even have an id for this instance...
			if(!this.instance || !this.instance.id || !this.wrapper || !this.wrapper.getLinkedLayout) {
				return;
			}

			var linkedLayout = this.wrapper.getLinkedLayout('previous');
			if(!linkedLayout) {
				linkedLayout = this.wrapper.getLinkedLayout('next');
			}

			if(linkedLayout) {
				var linkedElem = linkedLayout.getContent(this.instance.id);

				if(linkedElem) {
					callback.call(this, linkedLayout, linkedElem);
				}
			}
		},
		updateLinkedInstance: function(linkedElem, outOfBounds, contentRect) {
			var bleedWidth = this.wrapper.inchBleed.left + this.wrapper.inchBleed.right;

			var x;
			switch(outOfBounds.direction) {
				case 'left':
					x = this.wrapper.inchWidth - (outOfBounds.amount / this.ratio);
					if(this.duplicateLinkedLayoutsInBleed) {
						x = x - bleedWidth + linkedElem.wrapper.inchSafeSpace.right;
					} else {
						x = x - (linkedElem.wrapper.inchBleed.left - linkedElem.wrapper.inchBleed.right) - linkedElem.wrapper.inchSafeSpace.right;
					}
					break;
				case 'right':
					var rightEdge = outOfBounds.amount;
					var leftEdge = rightEdge - contentRect.width + this.wrapper.canvasBorderWidth * 2;

					x = leftEdge / this.ratio;
					if(this.duplicateLinkedLayoutsInBleed) {
						x = x - linkedElem.wrapper.inchSafeSpace.left;
					} else {
						x = x - linkedElem.wrapper.inchBleed.left - this.wrapper.inchBleed.right + linkedElem.wrapper.inchSafeSpace.left;
					}
					break;
			}

			let y = ($(this).getFloatStyle('top') + 1 - (this.extraOffset || 0)) / this.ratio;
			var width = this.width / this.ratio;
			var height = this.height / this.ratio;
			if(linkedElem.contentType === 'frame') {
				linkedElem.changeInstanceProperty(['x', 'y', 'width', 'height', 'duplicateInBleed'], [x, y, width, height, this.duplicateLinkedLayoutsInBleed]);
			} else if(linkedElem.contentType && linkedElem.contentType.indexOf('text') !== -1) {
				linkedElem.changeInstanceProperty(['position', 'duplicateInBleed', 'manualSize'], [{
					left: x,
					top: y
				}, this.duplicateLinkedLayoutsInBleed, this.instance.manualSize], false);

				if(linkedElem.setupPosition) {
					linkedElem.setupPosition(linkedElem.instance.position);
				}
			}
			if(typeof linkedElem.refreshInstance !== 'function') {
				console.warn('linkedElem without refreshInstance: ' + linkedElem.id + ' - ' + linkedElem.className + ' - ' + linkedElem.length + ' - ' + linkedElem.constructor?.name + ' - ' + $.isArray(linkedElem) + ' - ' + (linkedElem instanceof jQuery));
			}
			linkedElem.refreshInstance();

			if(linkedElem.wrapper) {
				linkedElem.wrapper.onFlowChange(linkedElem);
			}
		},
		setLinkedInstanceFocused: function(focused) {
			if(focused) {
				if(this.focused) {
					this.setFocused(false);
				}
				$(this).addClass('flowLayoutSecondaryFocused');
			} else {
				$(this).removeClass('flowLayoutSecondaryFocused');
			}

			if(this.onSetSecondaryFocused) {
				this.onSetSecondaryFocused(focused, true);
			}
		},

		updatePositionTooltip: function() {
			if(!this.showDisplayTooltips) {
				return;
			}

			var left = $(this).getFloatStyle('left') + 1 - (this.extraOffset || 0);
			var top = $(this).getFloatStyle('top') + 1 - (this.extraOffset || 0);
			var parentOffset = $(this.parentNode).offset() || $(this.wrapper).offset() || {
				left: 0,
				top: 0
			};
			var rect = this.getCachedRect();

			if(!this.positionTooltip) {
				this.positionTooltip = $('<div class="flowLayoutDisplayTooltip flowLayoutPositionTooltip"></div>');
				$('body').append(this.positionTooltip);
			}

			$(this.positionTooltip)
				.text('x: ' + this.wrapper.getDisplayUnit(left / this.ratio) + ' y: ' + this.wrapper.getDisplayUnit(top / this.ratio))
				.css({
					left: rect.x + parentOffset.left,
					top: rect.y + parentOffset.top + rect.height + 4,
					width: rect.width
				});
		},
		getCachedRect: function() {
			return this.getBoundingClientRect();
		},

		stopMoving: function () {
			this.removeAlignmentLines();
			this.wrapper.onFlowStop(this);

			this.stopPositionTooltip();
		},
		stopPositionTooltip: function() {
			if(this.positionTooltip) {
				$(this.positionTooltip).remove();
				this.positionTooltip = null;
			}
		},
		getCopyContentButton: function() {
			var copyButton = {
				icon: 'clone',
				popup: 'Duplicate ' + me.visibleType,
				duplicateContent: function(destinationPage, options) {
					options = $.extend(true, {
						showNotification: true
					}, options);
					var page = me.wrapper.getPage();
					var idMap = {};
					var newInstances = [];
					this.addDuplicateContentInstance(newInstances, me, page, destinationPage, idMap, options);
					me.getSecondaryFocusedElements().forEach(function(secondaryElement) {
						copyButton.addDuplicateContentInstance(newInstances, secondaryElement, page, destinationPage, idMap, $.extend({
							setSecondaryFocused: true
						}, options));
					});

					newInstances.forEach(function(params) {
						var instance = params.instance;
						if(instance.groupedElements) {
							var groupedElements = $.merge([], instance.groupedElements);
							
							for(var oldId in idMap) {
								var newId = idMap[oldId];
								
								var index = groupedElements.indexOf(oldId);
								if(index !== -1) {
									groupedElements.splice(index, 1, newId);
								}
							}
							
							copyButton.setDestinationPageProperty(page, destinationPage, instance, params.type, 'groupedElements', groupedElements);
						}
					});

					if(destinationPage && destinationPage != page) {
						me.wrapper.parent.updatePagesAfterChange(destinationPage);
					} else {
						me.setFocused(false, false);
					}
				},
				addDuplicateContentInstance: function(newInstances, element, page, destinationPage, idMap, options) {
					var newInstance = copyButton.duplicateContentInstance(element, page, destinationPage, idMap, options);
					newInstances.push({
						type: element.contentType,
						instance: newInstance
					});

					if(newInstance.captionTextId) {
						var captionNode = element.getCaptionNode();
						if(captionNode) {
							var newCaptionInstance = copyButton.duplicateContentInstance(captionNode, page, destinationPage, idMap, options);
							newInstances.push({
								type: captionNode.contentType,
								instance: newCaptionInstance
							});

							this.setDestinationPageProperty(page, destinationPage, newInstance, element.contentType, 'captionTextId', newCaptionInstance.id);
							this.setDestinationPageProperty(page, destinationPage, newCaptionInstance, captionNode.contentType, 'captionImageId', newInstance.id);
						} else {
							delete newInstance.captionTextId;
						}
					}
				},
				setDestinationPageProperty: function(page, destinationPage, instance, type, name, value) {
					if(destinationPage && destinationPage != page) {
						if(type == 'frame') {
							destinationPage.setCandidProperty(instance.id, name, value);
						} else {
							destinationPage.setTextProperty(instance.id, name, value);
						}
					} else {
						if(type == 'frame') {
							me.wrapper.getFrame(instance.id).changeInstanceProperty(name, value);
						} else {
							me.wrapper.getText(instance.id).changeInstanceProperty(name, value);
						}
					}
				},
				duplicateContentInstance: function(element, page, destinationPage, idMap, options) {
					var definition = $.extend(true, {}, element.instance);
					var oldId = definition.id;
					definition.id = $.getUniqueId();
					idMap[oldId] = definition.id;

					if(destinationPage && destinationPage != page) {
						if(destinationPage.getLocked() || destinationPage.getFreeMovementLocked()) {
							if(window.alertify && options.showNotification) {
								window.alertify.error('Page ' + destinationPage.getPageReference() + ' is locked and cannot be duplicated to');
							}
						} else {
							definition.zIndex = destinationPage.getMaxZIndex() + 1;
							if(element.contentType == 'frame') {
								destinationPage.addCandid(definition);
							} else {
								destinationPage.addText(definition.id, definition.position, null, definition);
							}

							if(window.alertify && options.showNotification) {
								window.alertify.success('Duplicated ' + element.visibleType + ' to page ' + destinationPage.getPageReference());
							}
						}
					} else {
						definition.zIndex = page.getMaxZIndex() + 1;
						if(element.contentType == 'frame') {
							definition.x += element.duplicateElementMoveAmount;
							definition.y += element.duplicateElementMoveAmount;

							var frame = me.wrapper.addFrame(definition, true);
							frame.duplicatedAtTime = new Date().getTime();
							if(options.setSecondaryFocused) {
								frame.setSecondaryFocused(true);
							} else {
								frame.setFocused(true, false);
							}
						} else {
							if(!definition.position) {
								$.fireErrorReport(null, 'No position to copy', 'No position in copied text element', {
									definition: JSON.stringify(definition),
									contentType: element.contentType
								});
							}

							definition.position.left += element.duplicateElementMoveAmount;
							definition.position.top += element.duplicateElementMoveAmount;

							var text = me.wrapper.addText(definition, true);
							text.duplicatedAtTime = new Date().getTime();
							if(options.setSecondaryFocused) {
								text.setSecondaryFocused(true);
							} else {
								text.setFocused(true, false);
							}
						}
					}

					return definition;
				}
			};

			if(me.wrapper && me.wrapper.getPageSet() && me.wrapper.getPageSet().getTotalPages() > 1) {
				var page = me.wrapper.getPage();
				var pageSet = page.pageSet;

				var pageSetting;
				if(pageSet && pageSet.referencePagesBy != 'pageNumber') {
					var pageOptions = pageSet.pages.map(function(page) {
						return {
							id: page.pageNumber,
							name: page.getPageReference()
						};
					});
					pageOptions.unshift({
						id: DESTINATION_ALL,
						name: 'All'
					});

					pageSetting = {
						name: 'page',
						type: 'dropdown',
						options: pageOptions,
						description: 'On Page',
						defaultValue: page.pageNumber
					};
				} else {
					pageSetting = {
						name: 'page',
						type: 'text',
						description: 'On Page',
						defaultValue: 'Current page',
						onCreate: function(setting, field) {
							var page = me.wrapper.getPage();
							var input = $(field).find('input');
	
							input.on('focus', function() {
								if(this.value == 'Current page') {
									this.value = Math.max(0, page.getPageNumber() - $.PAGE_OFFSET);
								}
								this.select();
							}).on('blur', function() {
								var enteredPage = parseInt(this.value);
								if(isNaN(enteredPage)) {
									this.value = 'Current page';
								} else {
									var minPage = Math.max(0, 1 - $.PAGE_OFFSET);
									if(enteredPage == (page.getPageNumber() - $.PAGE_OFFSET)) {
										this.value = 'Current page';
									} else if(enteredPage < minPage) {
										this.value = minPage;
									} else if(enteredPage > pageSet.getTotalPages()) {
										this.value = pageSet.getTotalPages() - $.PAGE_OFFSET;
									}
								}
							});
						}
					};
				}

				return $.extend({
					settingsPicker: [
						pageSetting,
						{
							id: 'move',
							type: 'checkbox',
							description: 'Move',
							help: 'Check to move instead of creating a copy'
						}
					],
					settingsPickerOptions: {
						autoFormatColumns: false,
						onSave: function(changes, onSuccess, onError) {
							var destinationPages;
							var destinationPageVal = $(this).find('#settings-page input').val();
							var destinationPageNumber = parseInt(destinationPageVal);
							if(destinationPageVal === DESTINATION_ALL) {
								destinationPages = $.merge([], me.wrapper.getPageSet().pages);
							} else if(!isNaN(destinationPageNumber)) {
								var destinationPageIndex = destinationPageNumber - 1 + $.PAGE_OFFSET;
								if(destinationPageNumber == 0) {
									destinationPageIndex = 0;
								}

								destinationPages = pageSet.getPage(destinationPageIndex);
							}

							if($.isArray(destinationPages)) {
								destinationPages.forEach(function(destinationPage) {
									if(destinationPage !== me.getPage()) {
										copyButton.duplicateContent(destinationPage, {
											showNotification: false
										});
									}
								});

								if(window.alertify) {
									window.alertify.success('Duplicated ' + me.visibleType + ' to all pages');
								}
							} else {
								copyButton.duplicateContent(destinationPages);
								if($(this).find('#settings-move .ui.checkbox').checkbox('is checked')) {
									me.removeContent();
									return;
								}
							}
							onSuccess();

							if(me.editToolbar) {
								var visibleButton = me.editToolbar.find('.clone.icon').parent();
								visibleButton.popup('hide');
							}
						},
						onSaveLabel: 'Duplicate'
					},
					updateDisplay: function() {
						$(this.settingsPicker).find('input').val('Current page');
					}
				}, copyButton);
			} else {
				return $.extend({
					onClick: function() {
						copyButton.duplicateContent();
					}
				}, copyButton);
			}
		},
		getInstanceCopy: function() {
			var copy = $.extend(true, {}, this.instance);

			return {
				type: this.contentType,
				instance: copy
			};
		},
		getGroupButton: function() {
			var hasGroup = this.instance && this.instance.groupedElements && this.instance.groupedElements.length > 0;
			var showLockButton = !hasGroup;
			if(hasGroup) {
				if(this.getSecondaryFocusedElements().length > this.instance.groupedElements.length) {
					showLockButton = true;
				}
			}

			return {
				icon: (showLockButton ? 'object group outline' : 'object ungroup outline'),
				popup: (showLockButton ? 'Group together' : 'Remove grouping'),
				onClick: function() {
					if(showLockButton) {
						this.lockGroup();
					} else {
						this.unlockGroup();
					}

					// Since button is no longer part of toolbar, this will stop it from unfocusing
					this.ignoreNextClickOut = true;
				}
			};
		},
		lockGroup: function() {
			var elements = this.getAllFocusedElements();

			let elementWithGroupBorder = false;
			elements.forEach(function(element) {
				var secondaryElements = element.getSecondaryFocusedElements();
				var ids = element.getSecondaryFocusedElements().map(function(element) {
					return element.instance.id;
				});

				element.changeInstanceProperty('groupedElements', ids);
				element.checkGroupRelativePositions(secondaryElements);
				if(element.instance.groupBorder) {
					if(elementWithGroupBorder) {
						element.changeInstanceProperty('groupBorder', null);
					} else {
						elementWithGroupBorder = true;
					}
				}
			});
			this.updateEditTools();
			if(this.wrapper && this.wrapper.postAddedContent) {
				this.wrapper.postAddedContent();
			}
		},
		checkGroupRelativePositions: function(secondaryElements) {
			// Only want to do this for dynamic text
			if(this.visibleType != 'text' || !this.hasDynamicText || !this.hasDynamicText()) {
				return;
			}

			// Make sure we are just linking a single text box with images
			var secondaryTextElements = secondaryElements.filter(function(element) {
				return element.visibleType == 'text';
			});
			if(secondaryTextElements.length) {
				return;
			}

			var myRect = this.getBoundingClientRect();
			var myLeft = myRect.left;
			var myRight = myRect.right;
			var dynamicDiff = 0;
			if(this.getDynamicSizeDiff) {
				dynamicDiff = this.getDynamicSizeDiff(true);
				myRight += dynamicDiff;
				myLeft -= dynamicDiff;
			}

			var relativeElements = [];
			secondaryElements.forEach(function(secondaryElement) {
				// Check to make sure fully to the left or right of the text
				var secondaryRect = secondaryElement.getBoundingClientRect();
				if((myLeft < secondaryRect.right && myRight > secondaryRect.left) || (myRight > secondaryRect.left && myLeft < secondaryRect.right)) {
					return;
				}

				var diff = secondaryRect.left - myRect.left;
				if((diff - dynamicDiff) > -0.01) {
					diff -= myRect.width + dynamicDiff;
				} else {
					diff += dynamicDiff;
				}

				relativeElements.push({
					x: diff / secondaryElement.ratio,
					id: secondaryElement.instance.id
				});
			});

			if(relativeElements.length) {
				this.changeInstanceProperty('relativeElements', relativeElements);
			}
		},
		unlockGroup: function() {
			var elements = this.getAllFocusedElements();

			elements.forEach(function(element) {
				element.changeInstanceProperty('groupedElements', null);
				if(element.instance.relativeElements) {
					element.changeInstanceProperty('relativeElements', null);
				}
			});

			this.getSecondaryFocusedElements().forEach(function(element) {
				element.setSecondaryFocused(false);
			});
			this.updateEditTools();
			if(this.wrapper && this.wrapper.postAddedContent) {
				this.wrapper.postAddedContent();
			}
		},
		updateRelativeElements: function() {
			if(!this.instance || !this.instance.relativeElements) {
				return;
			}

			window.setTimeout(function() {
				me.updateRelativeElementsCall();
			}, 1);
		},
		updateRelativeElementsCall: function() {
			this.instance.relativeElements.forEach(function(relativeElementProps) {
				var moveElement = me.wrapper.getContentElementById(relativeElementProps.id);
				if(moveElement != null) {
					var relativeLeft = $(me).getFloatStyle('left');

					var newLeft = relativeLeft + relativeElementProps.x * me.ratio;
					// If to the right of element, add width as well
					if(relativeElementProps.x > -0.01) {
						var relativeRect = me.getBoundingClientRect();
						newLeft += relativeRect.width;
					}

					var startLeft = $(moveElement).getFloatStyle('left');
					if(!$.isWithinDiff(startLeft, newLeft, 5)) {
						if(me.focused) {
							moveElement.showPosition(newLeft, $(moveElement).getFloatStyle('top'));
						} else {
							moveElement.setPosition(newLeft, $(moveElement).getFloatStyle('top'));
						}
					}
				}
			});
		},
		getGroupAlignmentButton: function() {
			var me = this;
			return {
				icon: 'indent',
				popup: 'Group alignment',
				settingsPicker: {
					type: 'section',
					perLine: 9,
					settings: [
						{
							type: 'button',
							icon: 'horizontal align left',
							popup: 'Align left',
							hasFieldLabel: false,
							onClick: function() {
								me.alignGroupTo('left', -1);
							}
						},
						{
							type: 'button',
							icon: 'horizontal align center',
							popup: i18n.t('composer.tools.alignHorizontalCenter'),
							hasFieldLabel: false,
							onClick: function() {
								me.alignGroupTo('left', 0);
							}
						},
						{
							type: 'button',
							icon: 'horizontal align right',
							popup: 'Align right',
							hasFieldLabel: false,
							onClick: function() {
								me.alignGroupTo('left', 1);
							}
						},
						{
							type: 'button',
							icon: 'vertical counterclockwise rotated align right',
							popup: 'Align top',
							hasFieldLabel: false,
							onClick: function() {
								me.alignGroupTo('top', -1);
							}
						},
						{
							type: 'button',
							icon: 'vertical rotated align center',
							popup: i18n.t('composer.tools.alignVerticalCenter'),
							hasFieldLabel: false,
							onClick: function() {
								me.alignGroupTo('top', 0);
							}
						},
						{
							type: 'button',
							icon: 'vertical clockwise rotated align right',
							popup: 'Align bottom',
							hasFieldLabel: false,
							onClick: function() {
								me.alignGroupTo('top', 1);
							}
						},
						{
							type: 'button',
							icon: 'horizontal exchange',
							popup: 'Space evenly horizontally',
							hasFieldLabel: false,
							onClick: function() {
								me.distributeGroupSpaceTo('left');
							}
						},
						{
							type: 'button',
							icon: 'vertical rotated exchange',
							popup: 'Space evenly vertically',
							hasFieldLabel: false,
							onClick: function() {
								me.distributeGroupSpaceTo('top');
							}
						}
						,
						{
							type: 'button',
							icon: 'expand',
							popup: 'Size equally',
							hasFieldLabel: false,
							onClick: function() {
								me.resizeGroupToBeEqualSize();
							}
						}
					]
				}
			};
		},
		alignGroupTo: function(axis, side) {
			var sizeProp = axis === 'left' ? 'width' : 'height';
			var elements = this.getAllFocusedElements();

			var minLeft = 100000;
			var maxRight = 0;
			elements.forEach(function(element) {
				element.cachedRect = element.getBoundingClientRect();
				var left = $(element).getFloatStyle(axis);
				var right = left + element.cachedRect[sizeProp];
				minLeft = Math.min(left, minLeft);
				maxRight = Math.max(right, maxRight);
			});

			var center = (maxRight - minLeft) / 2 + minLeft;
			elements.forEach(function(element) {
				if(!element.cachedRect) {
					return;
				}

				if(side === -1) {
					$(element).css(axis, minLeft);
				} else if(side === 1) {
					$(element).css(axis, maxRight - element.cachedRect[sizeProp]);
				} else if(side === 0) {
					$(element).css(axis, center - (element.cachedRect[sizeProp] / 2));
				}
				delete element.cachedRect;
				element.saveCurrentPosition({
					propogate: false
				});
			});
		},
		distributeGroupSpaceTo: function(axis) {
			var sizeProp = axis === 'left' ? 'width' : 'height';
			var elements = this.getAllFocusedElements();

			var minLeft = 100000;
			var maxRight = 0;
			elements.forEach(function(element) {
				element.cachedRect = element.getBoundingClientRect();
				var left = $(element).getFloatStyle(axis) - 1;
				var right = left + element.cachedRect[sizeProp];
				minLeft = Math.min(left, minLeft);
				maxRight = Math.max(right, maxRight);
			});

			// Make sure elements are in order that we can distribute space among evenly
			elements.sort(function(a, b) {
				return $(a).getFloatStyle(axis) - $(b).getFloatStyle(axis);
			});
			
			// Calculate how much space to put between each element (can be negative)
			var totalSpaceUsed = elements.reduce(function(totalSpace, element) {
				return totalSpace + element.cachedRect[sizeProp];
			}, 0);
			var totalSpaceAvailable = maxRight - minLeft;
			var totalSpace = totalSpaceAvailable - totalSpaceUsed;
			var spaceBetweenEach = totalSpace / (elements.length - 1);

			// Go through each middle element since the outer ones won't move
			for(var i = 1; i < elements.length - 1; i++) {
				var element = elements[i];
				var previousElement = elements[i - 1];
				var previousRight = $(previousElement).getFloatStyle(axis) + previousElement.cachedRect[sizeProp];

				$(element).css(axis, previousRight + spaceBetweenEach);
				
				element.saveCurrentPosition({
					propogate: false
				});
			}

			// Cleanup
			elements.forEach(function(element) {
				delete element.cachedRect;
			});
		},
		resizeGroupToBeEqualSize: function() {
			var elements = this.getAllFocusedElements();
			var rect = this.getBoundingClientRect();

			var me = this;
			elements.forEach(function(element) {
				if(me === element) {
					return;
				}

				$(element).css({
					width: rect.width - 2,
					height: rect.height - 2
				});

				var aspectRatio = false;
				var freeRangeStartCrop = null;
				if(element.getLockedAspectRatio && element.getLockedAspectRatio()) {
					aspectRatio = element.getLockedAspectRatio();
					freeRangeStartCrop = element.instance.crop;
				}

				element.saveCurrentSize({
					freeRangeAspectRatio: aspectRatio,
					freeRangeStartCrop: freeRangeStartCrop
				});
			});
		},
		getGroupBorderButton: function() {
			return {
				icon: 'columns',
				popup: 'Group Border',
				colorPicker: true,
					allowTransparency: false,
					settingsPicker: [
						{
							name: 'thickness',
							description: 'Thickness: %value%',
							type: 'inc/dec',
							range: [1, 10],
							value: this.instance.groupBorder ? this.instance.groupBorder.thickness : 1
						},
						{
							name: 'padding',
							description: 'Padding: %value%',
							type: 'inc/dec',
							range: [0, 10],
							value: this.instance.groupBorder ? this.instance.groupBorder.padding : 1
						},
						{
							name: 'roundedCorner',
							description: 'Rounded Corners',
							type: 'checkbox',
							value: this.instance.groupBorder ? this.instance.groupBorder.roundedCorner : false
						}
					],
					allowEmpty: true,
					getCurrentColor: function(selection) {
						let groupBorderElem = me.getGroupBorderElement();
						if(groupBorderElem && groupBorderElem.instance.groupBorder) {
							return groupBorderElem.instance.groupBorder.color;
						} else {
							return 'transparent';
						}
					},
					onChange: function(selection, color) {
						let groupBorderElem = me.getGroupBorderElement();

						if(color && color != 'transparent') {
							if($.isArray(groupBorderElem.instance.groupBorder)) {
								groupBorderElem.instance.groupBorder = null;
							}

							if(groupBorderElem.instance.groupBorder) {
								groupBorderElem.instance.groupBorder.color = color;
							} else {
								groupBorderElem.instance.groupBorder = {
									color: color,
									thickness: 1,
									padding: 0
								};
							}

							groupBorderElem.changeInstanceProperty('groupBorder', groupBorderElem.instance.groupBorder);
						} else {
							groupBorderElem.changeInstanceProperty('groupBorder', null);
						}

						groupBorderElem.applyGroupBorders();
					},
					onChangeSetting: function(section, name, value) {
						let groupBorderElem = me.getGroupBorderElement();
						if(!groupBorderElem.instance.groupBorder) {
							groupBorderElem.instance.groupBorder = {
								color: 'rgb(0, 0, 0)',
								thickness: 1,
								padding: 0
							};
						}

						groupBorderElem.instance.groupBorder[name] = value;
						groupBorderElem.changeInstanceProperty('groupBorder', groupBorderElem.instance.groupBorder);
						groupBorderElem.applyGroupBorders();
					},
					updateButtonColor: function (div, color) {
						if(color == 'transparent') {
							color = 'black';
							div.removeClass('active');
						} else {
							div.addClass('active');
						}

						div.find('i').css('color', color);
					}
			};
		},
		getGroupBorderElement: function() {
			let groupedElements = me.getAllGroupedElements();
			return groupedElements.find(elem  => !!elem.instance.groupBorder) || this;
		},
		applyGroupBorders: function() {
			// Do not try to apply for locked instances since we don't actually support for them
			// NOTE: If we ever add support for locked instances, make sure index headers with & in the name do not break
			if(this.isLockedText) {
				return;
			}
			let elemId = 'groupBorder' + this.instance.id;
			let boxElem = $(this.wrapper).find('#' + elemId);
			if(!this.instance || !this.instance.groupBorder || !$(this).isAttached()) {
				$(boxElem).remove();
				return;
			}

			// If a user accidentally removes a grouping, should no longer have the group border
			// For Ads, we want to be able to setup a group border that is a specific size even if we only have a single element (ie: uploaded a jpg of what they wanted)
			let elements = this.getAllGroupedElements();
			if(elements.length <= 1 && !this.instance.groupBorder.position) {
				boxElem.remove();
				return;
			}

			let groupBorder = this.instance.groupBorder;
			if(!boxElem.length) {
				boxElem = $('<div class="groupBorder"></div>').attr('id', elemId);
				$(this.wrapper.container).append(boxElem);
			}

			let thickness = groupBorder.thickness / 60 * this.ratio;
			let borderRadius = '';
			if(groupBorder.roundedCorner) {
				borderRadius = (thickness * 1.5) + 'px';
			}
			let padding = groupBorder.padding / 60 * this.ratio;

			let box;
			if(groupBorder.position) {
				box = {
					left: groupBorder.position.x * this.ratio + (thickness - 1),
					top: groupBorder.position.y * this.ratio + (thickness - 1),
					right: (groupBorder.position.x + groupBorder.position.width) * this.ratio + 2 - (thickness - 1),
					bottom: (groupBorder.position.y + groupBorder.position.height) * this.ratio + 2 - (thickness - 1)
				};
			} else {
				box = this.getGroupBoxSize();
			}
			boxElem.css({
				position: 'absolute',
				// + 1 for elements implicit border
				left: (box.left - thickness + 1 - padding) + 'px',
				top: (box.top - thickness + 1 - padding) + 'px',
				width: (box.right - box.left - 2 + (padding * 2)) + 'px',
				height: (box.bottom - box.top - 2 + (padding * 2)) + 'px',
				border: thickness + 'px solid ' + groupBorder.color,
				'border-radius': borderRadius
			});
		},
		removeGroupBorder: function() {
			if(!this.instance) {
				return;
			} else if(this.isLockedText) {
				return;
			}
			
			let elemId = 'groupBorder' + this.instance.id;
			$(this.wrapper).find('#' + elemId).remove();
		},

		getGroupBoxSize: function() {
			let elements = this.getAllGroupedElements();
			let minLeft = 100000;
			let minTop = 100000;
			let maxRight = 0;
			let maxBottom = 0;
			elements.forEach(function(element) {
				element.cachedRect = element.getBoundingClientRect();
				let left = $(element).getFloatStyle('left');
				let top = $(element).getFloatStyle('top');
				let right = left + element.cachedRect.width;
				let bottom = top + element.cachedRect.height;
				minLeft = Math.min(left, minLeft);
				maxRight = Math.max(right, maxRight);
				minTop = Math.min(top, minTop);
				maxBottom = Math.max(bottom, maxBottom);
			});

			return {
				left: minLeft,
				right: maxRight,
				top: minTop,
				bottom: maxBottom
			};
		},
		getAllGroupedElements: function() {
			if(!this.instance || !this.instance.groupedElements) {
				return [this];
			}

			let allElements = this.wrapper.getContent().toArray();
			let groupedElements = this.instance.groupedElements.map(id => {
				return allElements.find(elem => elem.instance && elem.instance.id === id);
			}).filter(elem => !!elem);
			groupedElements.push(this);

			return groupedElements;
		},

		getToggleLockButton: function() {
			return {
				icon: this.instance.locked ? 'unlock' : 'lock',
				popup: this.instance.locked ? 'Unlock editing' : 'Lock editing',
				isActive: function() {
					return !!me.instance.locked;
				},
				onClick: function() {
					if(this.instance.locked) {
						this.changeInstanceProperty('locked', false);
					} else {
						this.changeInstanceProperty('locked', $.extend(true, {}, $.CurrentUser));
					}

					this.refreshInstance();
					this.updateEditTools();
					this.forceUpdateToolbarPosition();
					// Since button is no longer part of toolbar, this will stop it from unfocusing
					this.ignoreNextClickOut = true;
				}
			};
		},
		updateLockedTooltip: function() {
			if(this.instance && this.instance.locked && (this.wrapper && this.wrapper.editable)) {
				if(!this.lockedTooltip) {
					this.lockedTooltip = $('<div class="flowLayoutLockedTooltip"><i class="lock icon"></i></div>');
					if(this.instance.locked.name) {
						this.lockedTooltip.popup({
							html: 'Locked for editing by ' + this.instance.locked.name + '.  Lock icon will not show in print',
							observeChanges: false,
							variation: 'wide'
						});
					} else {
						this.lockedTooltip.popup({
							html: 'Locked for editing.  Lock icon will not show in print',
							observeChanges: false,
							variation: 'wide'
						});
					}
					$(this).append(this.lockedTooltip);
				}
			} else if(this.lockedTooltip) {
				$(this.lockedTooltip).popup('destroy').remove();
				this.lockedTooltip = null;
			}
		},
		updateOtherTooltip: function() {
			var page = this.getPage();
			if(this.instance && page && this.movable && page.getContentTooltip && page.getContentTooltip(this.instance)) {
				if(!this.otherTooltip) {
					var contentTooltip = page.getContentTooltip(this.instance);
					this.otherTooltip = $('<div class="flowLayoutOtherTooltip"><i class="lock icon"></i></div>');
					this.otherTooltip.find('i').addClass(contentTooltip.icon);
					this.otherTooltip.popup({
						html: contentTooltip.tooltip,
						observeChanges: false,
						variation: 'wide'
					});
					$(this).append(this.otherTooltip);
				}
			} else if(this.otherTooltip) {
				$(this.otherTooltip).popup('destroy').remove();
				this.otherTooltip = null;
			}
		},

		isTopZIndexAtPosition: function(position, filter) {
			var elements = this.wrapper.getContentElements(filter);
			for(var i = 0; i < elements.length; i++) {
				var element = elements[i];
				if(element != me && element.isWithinPosition(position)) {
					var elementZIndex = $.isInit(element.instance.zIndex) ? element.instance.zIndex : 0;
					var myZIndex = $.isInit(me.instance.zIndex) ? me.instance.zIndex : 0;

					if(myZIndex < elementZIndex) {
						return false;
					}
				}
			}

			return true;
		},
		isWithinPosition: function(position) {
			var rect = this.getBoundingClientRect();

			// Make sure within this bounds
			if(position.x < rect.x || position.y < rect.y || position.x > (rect.x + rect.width) || position.y > (rect.y + rect.height)) {
				return false;
			} else {
				// Make sure also withint parent bounds (ie: not large candid on left page hidden on right page)
				var parentRect = this.wrapper.getBoundingClientRect();
				if(position.x < parentRect.x || position.y < parentRect.y || position.x > (parentRect.x + parentRect.width) || position.y > (parentRect.y + parentRect.height)) {
					return false;
				} else {
					return true;
				}
			}
		},

		fixHiddenContent: function(myRect, parentRect) {
			if(!this.parentNode) {
				return true;
			}

			if(!myRect) {
				myRect = this.getBoundingClientRect();
			}
			if(!parentRect) {
				parentRect = this.getParentRect();
			}

			var maxVisible = 0.01;
			var saveCurrentPosition = false;
			if(myRect.left > (parentRect.right - maxVisible)) {
				var startLeft = $(this).getFloatStyle('left');
				$(this).css('left', startLeft - (myRect.right - parentRect.right));
				saveCurrentPosition = true;
			} else if(myRect.right < (parentRect.left + maxVisible)) {
				$(this).css('left', 0);
				saveCurrentPosition = true;
			}
			if(myRect.top > (parentRect.bottom - maxVisible)) {
				var startTop = $(this).getFloatStyle('top');
				$(this).css('top', startTop - (myRect.bottom - parentRect.bottom));
				saveCurrentPosition = true;
			} else if(myRect.bottom < (parentRect.top + maxVisible)) {
				$(this).css('top', 0);
				saveCurrentPosition = true;
			}

			if(saveCurrentPosition) {
				this.saveCurrentPosition();
			}
		},
		isContentHidden: function(myRect, parentRect) {
			if(!this.parentNode) {
				return true;
			}

			if(!myRect) {
				myRect = this.getBoundingClientRect();
			}
			if(!parentRect) {
				parentRect = this.getParentRect();
			}

			var maxVisible = 0.01;
			// Completely invisible
			return (myRect.right < (parentRect.left + maxVisible) || myRect.left > (parentRect.right - maxVisible) ||
				myRect.bottom < (parentRect.top + maxVisible) || myRect.top > (parentRect.bottom - maxVisible));
		},
		getPage: function() {
			return this.wrapper && this.wrapper.getPage && this.wrapper.getPage();
		},
		addContentToPage: function(page, instance) {
			if(this.contentType == 'frame') {
				return page.addCandid(instance);
			} else if(this.contentType == 'text-free') {
				return page.addText(instance);
			}
		},
		onRemovePropogate: function() {
			this.withLinkedElem(function(layout, elem) {
				if(elem.contentType === 'frame') {
					layout.removeFrame(elem);
				} else if(this.contentType == 'text-free') {
					layout.removeText(elem);
				}
			});
		},

		// Definition is for compat with FlowLayoutFlowContent methods
		updateProperty: function(name, value) {
			this.changeInstanceProperty(name, value);
		},
		overflowToLinkedLayouts: false,
		duplicateLinkedLayoutsInBleed: true,
		hiddenCells: $(),
		alignmentLinesEnabled: true,
		duplicateElementMoveAmount: 0.5,
		showDisplayTooltips: false,
		allowCompletelyInBleed: false,
		canToggleLock: false
	}, options);
	$.FlowLayoutToolbar(me);
	$.FlowLayoutAlignmentLines(me);
	$.FlowLayoutRotatable(me);

	return me;
};