$.CLASS_ID_PREFIX = 'class';
$.FlowPageClass = function(classObj, settings) {
	var obj = new $.FlowPage();
	var _updateTextThemeStyles = obj.updateTextThemeStyles;
	var _getStatisticsForSubject = obj.getStatisticsForSubject;
	let _createLayoutTemplate = obj.createLayoutTemplate;
	
	$.extend(obj, {
		resetBlankTitle: function() {
			if(this.title === null || this.title === '_unset_') {
				delete this.title;
				this.applyDefaultTitleStyle();
			}

			if(this.extraTitles) {
				for(var id in this.extraTitles) {
					if(this.extraTitles[id] === null) {
						delete this.extraTitles[id];
					}
				}
			}

			if(this.overflowPage) {
				this.overflowPage.resetBlankTitle();
			}
		},
		getTitle: function () {
			if (typeof this.title != 'undefined') {
				return this.title;
			} else if (this.classObj) {
				return '%batch%';
			} else {
				return null;
			}
		},
		getTitleText: function () {
			var title = this.getTitle();
			var batch = this.getClass();
			if(!title) {
				if(batch) {
					return batch.name;
				} else {
					return '';
				}
			} else if ($.isInit(title.text)) {
				if (title.text) {
					return title.text;
				} else {
					if (batch) {
						return batch.name;
					} else {
						return '';
					}
				}
			} else if(title.lines) {
				title = $.FlowLayoutSVG({
					renderable: false
				}, title).getInstanceText();

				if(batch) {
					title = title.replace('%batch%', batch.name);
				}

				return title;
			} else if(title == '%batch%' && batch) {
				return batch.name;
			} else {
				return title;
			}
		},
		getExtraTitle: function (id) {
			return this.extraTitles[id];
		},
		setExtraTitle: function (id, title) {
			this.jsonPropertyChange('extraTitles', id, title);
		},
		getLastTitle: function () {
			// When we have multiple titles per page, we use this to get what the most recent one was
			if (this.lastTitle) {
				return this.lastTitle;
			} else {
				return this.getTitle();
			}
		},
		setLastTitle: function (lastTitle) {
			this.lastTitle = lastTitle;
		},
		getLastClass: function(lastClass) {
			return this.lastClass;
		},
		setLastClass: function(lastClass) {
			this.lastClass = lastClass;
		},
		changeClass: function (classObj, options) {
			this.setProperty('classObj', classObj, false, true, undefined, $.extend({
				saveValue: 'id'
			}, options));
		},
		setKids: function (kids) {
			this.classObj.subjects = kids;
		},
		getKids: function () {
			if (!this.classObj.subjects) {
				return this.classObj.subjects;
			}
			var kids = $.merge([], this.classObj.subjects);

			for (var i = 0; i < this.extraClasses.length; i++) {
				var extraClass = this.extraClasses[i];
				this.mergeExtraBatchIntoSubjects(kids, extraClass);
			}

			return kids;
		},
		mergeExtraBatchIntoSubjects: function(subjects, extraClass) {
			var extraClassSubjects = $.merge([], extraClass.subjects);
			if (extraClassSubjects[0]) {
				// Note: We shouldn't need to make a copy anymore since subjects are copies by default now
				extraClassSubjects[0].classBreak = {
					id: extraClass.id,
					name: extraClass.name
				};
			}

			$.merge(subjects, extraClassSubjects);
		},
		setClass: function (classObj) {
			this.classObj = classObj;
		},
		getClass: function () {
			return this.classObj;
		},
		getClassForSubject: function(subject) {
			if(this.classObj) {
				var index = this.classObj.subjects.indexOfMatch(subject, 'id');
				if(index != -1) {
					return this.classObj;
				}

				for(var i = 0; i < this.extraClasses.length; i++) {
					var batch = this.extraClasses[i];

					index = batch.subjects.indexOfMatch(subject, 'id');
					if(index != -1) {
						return batch;
					}
				}
			}

			return null;
		},
		getExtraClasses: function () {
			return this.extraClasses;
		},

		setSize: function (size) {
			if (this.size != size) {
				this.layout.cellSize = size;
				this.layoutChanged = true;

				if (this.db) {
					this.db.queueChange({
						scope: 'pages',
						name: this.getId(),
						value: {layout: this.layout}
					});
				}
			}

			this.size = size;
		},
		getSize: function () {
			return this.size;
		},

		setStudentLabelCSS: function (studentLabelCSS) {
			this.propertyChange('studentLabelCSS', studentLabelCSS);
		},
		setStudentLabelCSSStyles: function(styles) {
			styles = $.extend(true, {}, styles);
			var oldStyles = $.extend(true, {}, this.studentLabelCSS.css);
			this.studentLabelCSS.css = styles;
			if(oldStyles['font-size'] && !styles['font-size']) {
				styles['font-size'] = oldStyles['font-size'];
			}

			if(this.db) {
				this.db.queueChange({
					scope: 'pages',
					name: this.getId(),
					value: {
						studentLabelCSS: JSON.stringify(styles)
					}
				});

				if(this.db.userEvents) {
					this.db.userEvents.addEvent({
						context: [this, 'studentLabelCSS', 'css'],
						action: 'update',
						args: [oldStyles, styles]
					});
				}
			}
		},
		getStudentLabelCSS: function () {
			return this.studentLabelCSS;
		},
		getStudentLabelCSSStyles: function() {
			if(this.studentLabelCSS) {
				var styles = $.extend(true, {}, this.studentLabelCSS.css);
				delete styles['font-size'];
				return styles;
			} else {
				return {};
			}
		},

		setStudentMask: function (mask) {
			if (this.mask != mask) {
				this.mask = mask;

				if (this.db) {
					if (this.layout) {
						this.db.queueChange({
							scope: 'pages',
							name: this.getId(),
							value: {layout: this.layout}
						});
					} else {
						this.db.queueChange({
							scope: 'pages',
							name: this.getId(),
							value: {mask: mask}
						});
					}
				}
			}
		},
		getStudentMask: function () {
			return this.mask;
		},

		addExtraClass: function (classObj) {
			this.arrayPropertyPush('extraClasses', classObj, classObj.id);
		},
		removeExtraClass: function(classObj, options) {
			for (var i = 0; i < this.extraClasses.length; i++) {
				var extraClass = this.extraClasses[i];
				if (extraClass.id == classObj.id) {
					this.arrayPropertyRemove('extraClasses', i, 'id', options);

					if(typeof this.extraTitles[extraClass.id] !== 'undefined') {
						this.jsonPropertyRemove('extraTitles', extraClass.id, options);
					}
				}
			}
		},

		swapStudents: function (draggedStudent, droppedStudent) {
			var batch = this.getClass();
			var students = batch.subjects;
			var draggedIndex = students.indexOf(draggedStudent);
			var droppedIndex = students.indexOf(droppedStudent);
			students.swap(draggedIndex, droppedIndex);

			if(this.db && this.db.userEvents) {
				this.db.userEvents.addEvent({
					context: ['batches', batch.id, 'subjects'],
					action: 'swapWith',
					args: [draggedStudent.id, droppedStudent.id],
					extras: {
						idProp: 'id'
					}
				});
			}

			this.updateStudentOrder();
		},
		shiftStudent: function (draggedStudent, droppedStudent) {
			if (draggedStudent == droppedStudent) {
				return;
			}

			var batch = this.getClassForSubject(draggedStudent);
			var students = batch.subjects;
			var draggedIndex = students.indexOf(draggedStudent);
			var droppedIndex = students.indexOf(droppedStudent);
			if (draggedIndex < droppedIndex) {
				droppedIndex--;

				if (draggedIndex == droppedIndex) {
					return;
				}
			}
			students.move(draggedIndex, droppedIndex);

			if(this.db && this.db.userEvents) {
				this.db.userEvents.addEvent({
					context: ['batches', batch.id, 'subjects'],
					action: 'moveBefore',
					args: [draggedStudent.id, droppedStudent.id, draggedIndex],
					extras: {
						idProp: 'id'
					}
				});
			}

			this.updateStudentOrder();
		},
		moveSubjectToPosition: function(subject, newIndex) {
			var batch = this.getClassForSubject(subject);
			var subjects = batch.subjects;

			var oldIndex = subjects.indexOfMatch(subject, 'id');
			if(oldIndex === -1 || oldIndex == newIndex) {
				console.error('Trying to save moving a subject to the same position');
				return;
			}

			var newSubjectId = null;
			if(newIndex >= subjects.length) {
				subjects.removeMatch(subject, 'id');
				subjects.push(subject);
			} else {
				newSubjectId = subjects[newIndex].id;
				if(oldIndex < newIndex) {
					newIndex--;
				}
				
				subjects.move(oldIndex, newIndex);
			}

			if(this.db && this.db.userEvents) {
				this.db.userEvents.addEvent({
					context: ['batches', batch.id, 'subjects'],
					action: 'moveBefore',
					args: [subject.id, newSubjectId, oldIndex],
					extras: {
						idProp: 'id'
					}
				});
			}

			this.updateStudentOrder();
		},
		updateStudentOrder: function () {
			if (this.db) {
				var students = [];
				var kids = this.getClass().subjects;
				for (var i = 0; i < kids.length; i++) {
					students.push(kids[i].id);
				}

				this.db.queueChange({
					scope: 'batches',
					name: this.getClass().id,
					value: students
				});
			}
		},

		getSubjects: function(includeMergedBatches) {
			if(this.classObj && this.classObj.subjects) {
				var subjects = $.merge([], this.classObj.subjects);

				if(includeMergedBatches) {
					for (var i = 0; i < this.extraClasses.length; i++) {
						var extraClass = this.extraClasses[i];
						$.merge(subjects, extraClass.subjects);
					}
				}

				return subjects;
			} else {
				return null;
			}
		},

		setLargeCellPosition: function (largeCellPosition, forceUpdate) {
			this.propertyChange('largeCellPosition', largeCellPosition, forceUpdate);
		},
		getLargeCellPosition: function () {
			return this.largeCellPosition;
		},

		setPageMargins: function (margins, forceUpdate) {
			// We only pass one part of margins at a time
			if (!forceUpdate) {
				margins = $.extend(true, {}, this.getExtraProperty('pageMargins', {}), margins);
			}
			this.setExtraProperty('pageMargins', margins);
		},
		getPageMargins: function () {
			return this.getExtraProperty('pageMargins');
		},

		setClassActive: function (active) {
			// Needs to check on classObj so this doesn't get called for overflow pages
			if(this.classObj) {
				this.setClassObjActive(this.getClass(), active);
				var extraClasses = this.getExtraClasses();
				if (extraClasses) {
					for (var i = 0; i < extraClasses.length; i++) {
						this.setClassObjActive(extraClasses[i], active);
					}
				}
			}
		},
		removeClassObj: function (classObj, options) {
			if (this.classObj && this.classObj.id == classObj.id) {
				if (this.extraClasses.length) {
					var newMainClass = this.extraClasses[0];
					this.arrayPropertyRemove('extraClasses', 0, 'id', options);

					if (typeof this.extraTitles[newMainClass.id] != 'undefined') {
						this.setTitle(this.extraTitles[newMainClass.id]);
						this.jsonPropertyRemove('extraTitles', newMainClass.id);
					}
					this.changeClass(newMainClass, options);
				} else {
					this.changeClass(null, options);
					this.setTitle('Class Placeholder');

					if (this.getOverflowPage && this.getOverflowPage()) {
						this.pageSet.removePageCascade(this.getOverflowPage());
					}
					if(this.setOverflowPage) {
						this.setOverflowPage(null);
					}
				}
			} else {
				this.removeExtraClass(classObj);
			}

			var overflowPage = this;
			while (overflowPage && overflowPage.getOverflowPage && overflowPage.getOverflowPage()) {
				overflowPage = overflowPage.getOverflowPage();
				if (overflowPage.subjects) {
					overflowPage.subjects = overflowPage.subjects.filter(function (subject) {
						for (var i = 0; i < classObj.subjects.length; i++) {
							if (classObj.subjects[i].id == subject.id) {
								return false;
							}
						}

						return true;
					});
				}
			}

			this.setClassObjActive(classObj, false);
		},
		setClassObjActive: function(classObj, active) {
			if(classObj) {
				classObj.active = active;
				var batchDiv = $('#classes').find('#' + $.CLASS_ID_PREFIX + classObj.id);
				if(active) {
					batchDiv.addClass('active');
				} else {
					batchDiv.removeClass('active');
				}

				var updateFunc = batchDiv.data('updateStatus');
				if (updateFunc) {
					updateFunc(classObj);
				}
			}
		},
		updateTextThemeStyles: function(oldStyles, newStyles) {
			_updateTextThemeStyles.apply(this, arguments);

			if(oldStyles.subjectLabel || newStyles.subjectLabel) {
				var newLabelCSS = this.updateTextObjectThemeStyles(this.studentLabelCSS.css, oldStyles.subjectLabel, newStyles.subjectLabel);
				if(newLabelCSS) {
					this.setStudentLabelCSSStyles(newLabelCSS);
				}
			}
		},
		getStatisticsForSubject: function(subject) {
			var stats = _getStatisticsForSubject.apply(this, arguments);

			var subjects = this.getSubjects(true);
			if(subjects) {
				var index = subjects.indexOfMatch(subject, 'id');

				var page = this.getPageForSubject(subject, subjects);
				if(index != -1) {
					stats.classPage = page.pageNumber;
					// When getting list of all pages a subject is on, don't repeat overflow pages
					if(this.getParentPage && this.getParentPage()) {
						stats.classPages = [];
					} else {
						stats.classPages = [page.pageNumber];
					}
				}
			}

			return stats;
		},
		getPageForSubject: function(subject, subjects) {
			if(!subjects) {
				subjects = this.getSubjects(true);
			}
			if(!subjects) {
				return this;
			}

			var page = this;
			var subjectIndex = subjects.indexOfMatch(subject, 'id');
			var currentSubjects = 0;

			while(subjectIndex != -1 && page.viableCells && page.getOverflowPage && page.getOverflowPage()) {
				currentSubjects += page.viableCells;
				if(currentSubjects > subjectIndex) {
					break;
				}

				page = page.getOverflowPage();
			}

			return page;
		},
		hasPrimarySubject: function() {
			return false;
		},
		getSubjectCellData: function(subjectId) {
			return this.subjectCellData[subjectId];
		},
		getSubjectCellDataValue: function(subjectId, name) {
			var data = this.getSubjectCellData(subjectId);
			if(data) {
				return data[name];
			} else {
				return null;
			}
		},
		setSubjectCellData: function(subjectId, value) {
			this.jsonPropertyChange('subjectCellData', subjectId, value);
		},
		setSubjectCellDataValue: function(subjectId, name, value) {
			this.jsonSubPropertyChange('subjectCellData', subjectId, name, value);
		},
		setSubjectEffects: function(effectsSettings) {
			this.setExtraProperty('subjectEffects', effectsSettings);
		},
		clearAllSubjectCellDataByNames: function(names) {
			var subjectCellData = $.extend(true, {}, this.subjectCellData);
			var changed = false;
			Object.keys(subjectCellData).forEach(function(subjectId) {
				var data = subjectCellData[subjectId];
				names.forEach(function(name) {
					if(data[name]) {
						delete data[name];
						changed = true;
					}
				});

				if($.getObjectCount(data) === 0) {
					delete subjectCellData[subjectId];
				} 
			});

			if(changed) {
				this.jsonReplace('subjectCellData', subjectCellData);
			}
		},

		createLayoutTemplate: function(options = {}) {
			options.extraProperties = ['subjectEffects'];
			let layout = _createLayoutTemplate.call(this, options);

			let pageLayout = this.getLayout();
			if(pageLayout) {
				Object.keys(pageLayout).forEach(layoutProp => {
					if($.isPlainObject(pageLayout[layoutProp])) {
						layout[layoutProp] = $.extend(true, {}, layout[layoutProp], pageLayout[layoutProp]);
					} else {
						layout[layoutProp] = pageLayout[layoutProp];
					}
				});
			}
			let studentLabelCSS = this.getStudentLabelCSS();
			layout.studentLabelCSS = $.extend(true, {}, studentLabelCSS.css);
			let studentMask = this.getStudentMask();
			if(studentMask) {
				layout.studentMask = $.extend(true, {}, studentMask);
			}
			let title = this.getTitle();
			if(title?.lines) {
				layout.title = $.extend(true, {}, title.lines);
			} else if(title === null) {
				layout.title = null;
			}

			return layout;
		},

		createNewStudentLabelCSS: function(css) {
			return createNewStudentLabelCSS(css);
		},
		studentLabelCSS: createNewStudentLabelCSS(),
	
		classObj: classObj,
		extraClasses: [],
		type: 'class',
		extraTitles: {},
		subjectCellData: {},
		lastClass: null
	}, settings);

	function createNewStudentLabelCSS(css) {
		return new $.CSSBundle(function(name, value, oldValue) {
			if(obj.db) {
				obj.db.queueChange({
					scope: 'pages',
					name: obj.getId(),
					value: {
						studentLabelCSS: JSON.stringify(this.css)
					}
				});

				if(obj.db.userEvents) {
					var oldObj = {};
					oldObj[name] = oldValue;
					var newObj = {};
					newObj[name] = value;

					obj.db.userEvents.addEvent({
						context: [obj, 'studentLabelCSS', 'css'],
						action: 'update',
						args: [oldObj, newObj]
					});
				}
			}
		}, css);
	}
	
	return obj;
};