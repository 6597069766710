import { sleep } from '../../vue/utils/sleep';

$.ComposerDBQueue = function(settings) {
	settings = $.extend({
		loadSubjects: true,
		loadOrders: false
	}, settings);

	var obj = new $.DBQueue(settings);
	obj._load = obj.load;

	$.extend(obj, {
		load: function (events) {
			var me = this;
			this._load($.extend({}, events, {
				onReady: function (data) {
					if(data.welcomeEmailSent === null) {
						$.ajax({
							url: 'ajax/sendWelcomeEmail.php'
						});
					}

					me.groupBy = data.groupBy;
					me.sortBy = data.sortBy;
					me.lastSubjectUpdateAt = data.lastSubjectUpdateAt;
					me.lastSubjectsLoadedAt = data.lastSubjectsLoadedAt;

					if(data.userDefaultValues) {
						me.pageSet.userDefaultValues = data.userDefaultValues;
					}

					if(me.loadSubjects) {
						me.loadSubjectData(events, $.PlicProjectId, function (batches) {
							for(let i = 0; i < batches.length; i++) {
								if(batches[i].subjectCount === 0) {
									batches.splice(i, 1);
									i--;
								}
							}

							data.classes = batches;
							me.classes = batches;

							if(events.onLoadGlobals) {
								events.onLoadGlobals(data);
							}

							if(data.pages && data.pages.length) {
								me.loadPages(data.pages);
								me.checkLoadFonts();
								me.checkCustomColors();
							}

							if(events.onReady) {
								events.onReady(data);
							}

							if(me.finishLoading) {
								me.finishLoading(data);
							}
						});
					} else {
						if(events.onLoadGlobals) {
							events.onLoadGlobals(data);
						}

						if(data.pages && data.pages.length) {
							me.loadPages(data.pages);
							me.checkLoadFonts();
							me.checkCustomColors();
						}

						if(events.onReady) {
							events.onReady(data);
						}

						if(me.finishLoading) {
							me.finishLoading(data);
						}
					}

					return false;
				}
			}))
		},

		loadPages: function (pages, options) {
			options = $.extend(true, {
				forceSequentialPages: true,
				forceSequentialOverflowPages: false
			}, options);

			let previousPage, nullPages = 0;
			for (let i = 0; i < pages.length; i++) {
				var definition = pages[i];

				let page = this.loadSinglePage(definition, previousPage, { load: true });
				if (page) {
					if(options.forceSequentialPages || options.forceSequentialOverflowPages) {
						if(page.type.toLowerCase().indexOf('overflow') != -1 && (!previousPage || !previousPage.getOverflowPage)) {
							definition.type = 'empty';
							console.warn('Automatically fixing stranded overflow page ' + definition.id + ' at pageNumber ' + definition.page);
							page = this.loadSinglePage(definition, previousPage, { load: true });
						}
					}

					page.pageNumber = page.pageNumber - nullPages;

					if(options.forceSequentialPages) {
						// Make sure array is as large as necessary
						var length = this.pageSet.pages.length;
						for (let j = page.pageNumber - 1; j > length; j--) {
							this.pageSet.pages.push(null);
						}
						this.pageSet.pages.splice(page.pageNumber - 1, 1, page);
					} else {
						this.pageSet.pages.push(page);
					}
				} else {
					if(definition.id) {
						this.queueChange({
							scope: 'pages',
							name: definition.id,
							value: {
								remove: 'true'
							}
						}, true);
					}

					nullPages++;
				}
				previousPage = page;
			}

			if(options.forceSequentialPages) {
				nullPages = 0;
				for(let i = 0; i < this.pageSet.pages.length; i++) {
					let page = this.pageSet.pages[i];
					if(!page) {
						this.pageSet.pages.splice(i, 1);
						i--;
						nullPages++;
					} else if(nullPages > 0) {
						console.warn('Fixing non-sequential pages for page '  + definition.id + ' at pageNumber ' + page.pageNumber + ' to ' + (page.pageNumber - nullPages));
						page.propertyChange('pageNumber', page.pageNumber - nullPages, true, true, false, {
							permanent: true
						});
					}
				}
			}
		},
		loadSinglePage: function (definition, previousPage) {
			var page;
			if(definition.type == 'class') {
				page = $.FlowPageClass(definition.classObj);
				if(definition.extraClasses) {
					page.extraClasses = definition.extraClasses;
				}
			} else if(definition.type == 'class-root') {
				page = $.FlowPageClass(null, {
					type: 'class-root'
				});
				$.FlowPageOverflowModule(page);
			} else if(definition.type == 'class-overflow') {
				page = $.FlowPageClass(null, {
					type: 'class-overflow'
				});
				$.FlowPageOverflowModule(page, previousPage);
			} else if(definition.type == 'page-with-subjects') {
				page = $.FlowPageWithSubjects({
					type: 'page-with-subjects'
				});
			} else if(definition.type == 'page-root') {
				page = $.FlowPage({
					type: 'page-root'
				});
				$.FlowPageOverflowModule(page);
			} else if(definition.type == 'page-overflow') {
				page = $.FlowPage({
					type: 'page-overflow'
				});
				$.FlowPageOverflowModule(page, previousPage);
			} else if(definition.type == 'error-page') {
				page = $.FlowErrorPage();
			} else if(definition.type == 'error-class-page') {
				page = $.FlowErrorClassPage();
			} else if(definition.type === 'cover') {
				page = new $.FlowPage({
					type: 'cover'
				});
				$.FlowCoverPageCommon(page);
			} else {
				page = $.FlowPage();
			}
			this.loadStandardPageData(definition, page);

			return page;
		},
		loadStandardPageData: function(definition, page) {
			page.db = this;
			page.id = definition.id;
			page.title = definition.title;
			if (page.title && page.title.indexOf && page.title.indexOf('{"') != -1) {
				page.title = JSON.parse(page.title);
			}
			if (definition.layout && typeof definition.layout == 'string') {
				try {
					page.layout = JSON.parse(definition.layout);
				} catch (e) {
					$.fireErrorReport(null, 'Failed to parse layout', 'Failed to parse layout', {
						error: e,
						definition: definition
					});
				}
			} else if($.isArray(definition.layout) && definition.layout.length === 0) {
				page.layout = {};
			} else {
				page.layout = definition.layout;
			}

			if (page.layout && page.layout.cellSize) {
				page.size = page.layout.cellSize;
			}
			page.pageSet = this.pageSet;

			if (definition.candids) {
				if (typeof definition.candids == 'string') {
					page.candids = JSON.parse(definition.candids);
				} else {
					page.candids = definition.candids;
				}

				if ($.isArray(page.candids)) {
					var candids = {};
					for (let j = 0; j < page.candids.length; j++) {
						let candid = page.candids[j];

						if($.isPlainObject(candid)) {
							candid.id = $.getGuid();
							candids[candid.id] = candid;
						}
					}
					page.propertyChange('candids', candids, true);
				}
				
				var fixCandidsOutside = false;
				var maxLeft = -1;
				var maxTop = -1;
				var maxBottom = $.PAGE_HEIGHT;
				var maxRight = $.PAGE_WIDTH;
				if(this.pageSet && this.pageSet.getLayoutDimensions()) {
					var outerDimensions = page.getOuterDimensions?.() ?? this.pageSet.getOuterDimensions();
					if(outerDimensions) {
						if(this.pageSet.getLayoutDimensions().hideBleed) {
							maxLeft = -outerDimensions.safeSpace.left;
							maxTop = -outerDimensions.safeSpace.top;
							maxBottom = outerDimensions.height - outerDimensions.safeSpace.top;
							maxRight = outerDimensions.width - outerDimensions.safeSpace.left;
						} else {
							maxLeft = -outerDimensions.bleed.left;
							maxTop = -outerDimensions.bleed.top;
							maxBottom = outerDimensions.height - outerDimensions.bleed.top;
							maxRight = outerDimensions.width - outerDimensions.bleed.left;
						}

						fixCandidsOutside = true;
					}
				}
				
				var candidChanges = false;
				for (let id in page.candids) {
					let candid = page.candids[id];
					if (candid === null || candid.id != id) {
						delete page.candids[id];
						candidChanges = true;

						console.warn('Deleting candid ' + id + ' due to id mismatch', candid);
					} else if (fixCandidsOutside) {
						var candidLeft = candid.x;
						var candidRight = candid.x + candid.width;
						var candidTop = candid.y;
						var candidBottom = candid.y + candid.height;

						// Check if we need to rotate this to get an accurate reading
						var transform = candid.transform;
						if(transform) {
							var rotate = 0;
							var rotateText = 'rotate(';
							var startIndex = transform.indexOf(rotateText);
							if (startIndex != -1) {
								rotate = transform.substr(startIndex + rotateText.length);
								try {
									rotate = parseFloat(rotate);
								} catch (e) {
									rotate = 0;
									console.error(e);
								}
							}

							if(rotate) {
								var candidCenterX = candidLeft + (candidRight - candidLeft) / 2;
								var candidCenterY = candidTop + (candidBottom - candidTop) / 2;

								var topLeft = $.rotatePoint($.convertToRadians(rotate), candidLeft, candidTop, candidCenterX, candidCenterY);
								var topRight = $.rotatePoint($.convertToRadians(rotate), candidRight, candidTop, candidCenterX, candidCenterY);
								var bottomLeft = $.rotatePoint($.convertToRadians(rotate), candidLeft, candidBottom, candidCenterX, candidCenterY);
								var bottomRight = $.rotatePoint($.convertToRadians(rotate), candidRight, candidBottom, candidCenterX, candidCenterY);

								candidLeft = Math.min(topLeft.x, topRight.x, bottomLeft.x, bottomRight.x);
								candidRight = Math.max(topLeft.x, topRight.x, bottomLeft.x, bottomRight.x);
								candidTop = Math.min(topLeft.y, topRight.y, bottomLeft.y, bottomRight.y);
								candidBottom = Math.max(topLeft.y, topRight.y, bottomLeft.y, bottomRight.y);
							}
						}

						if((candidRight < maxLeft || candidBottom < maxTop || candidTop > maxBottom || candidLeft > maxRight)) {
							// We do < -1 to handle stuff in the bleed
							delete page.candids[id];
							candidChanges = true;

							console.warn('Deleting candid ' + id + ' due to invalid position', candid);
						}
					}
					
					if(page.candids[id]) {
						let mask = page.candids[id].mask;
						if (mask && mask['-webkit-mask-box-image'] && mask['mask']) {
							mask['mask-photo-id'] = mask['mask'].replace('url(#Mask-', '').replace(')', '');
							mask['mask-image'] = mask['-webkit-mask-box-image'];
							mask['mask-size'] = '100% 100%';

							delete mask['mask'];
							delete mask['-webkit-mask-box-image'];

							candidChanges = true;
						}
					}
				}

				if(candidChanges) {
					page.jsonReplace('candids', page.candids);
				}
			}
			if (definition.texts) {
				if (typeof definition.texts == 'string') {
					page.texts = JSON.parse(definition.texts);
				} else {
					page.texts = definition.texts;
				}

				if ($.isArray(page.texts)) {
					var texts = {};
					for (let j = 0; j < page.texts.length; j++) {
						var text = page.texts[j];

						if($.isPlainObject(text)) {
							text.id = $.getGuid();
							texts[text.id] = text;
						}
					}
					page.propertyChange('texts', texts, true);
				}

				var textChanges = false;
				for (let id in page.texts) {
					var textObj = page.texts[id];
					// I should probably delete these, but I am terrified how destroying possibly useful data since I don't even know how it got this way in the first place
					if($.isArray(textObj) || typeof textObj == 'string' || textObj === null) {
						continue;
					}

					if(!textObj.id || textObj.id !== id) {
						textObj.id = id;
						textChanges = true;
					}

					if(textObj.position && !$.isInit(textObj.position.left) && !$.isInit(textObj.position.top)) {
						textObj.position = {
							left: 0,
							top: 0
						};
						textChanges = true;
					}
				}

				if(textChanges) {
					page.jsonReplace('texts', page.texts);
				}
			}
			if (definition.theme) {
				page.theme = definition.theme;
			}
			if (definition.mask) {
				page.mask = definition.mask;

				if (page.mask && page.mask['-webkit-mask-box-image'] && page.mask['mask']) {
					page.mask['mask-photo-id'] = page.mask['mask'].replace('url(#Mask-', '').replace(')', '');
					page.mask['mask-image'] = page.mask['-webkit-mask-box-image'];
					page.mask['mask-size'] = '100% 100%';

					delete page.mask['mask'];
					delete page.mask['-webkit-mask-box-image'];

					page.setProperty('mask', page.mask, true);
				}
			} else if (page.layout && page.layout.cell && page.layout.cell.mask) {
				let mask = page.layout.cell.mask;
				if (mask && mask['-webkit-mask-box-image'] && mask['mask']) {
					mask['mask-photo-id'] = mask['mask'].replace('url(#Mask-', '').replace(')', '');
					mask['mask-image'] = mask['-webkit-mask-box-image'];
					mask['mask-size'] = '100% 100%';

					delete mask['mask'];
					delete mask['-webkit-mask-box-image'];

					page.setProperty('layout', page.layout, true);
				}
			}

			if (definition.comments) {
				page.comments = definition.comments;
				for (let j = 0; j < page.comments.length; j++) {
					if (page.comments[j] === null) {
						page.comments.splice(j, 1);
						j--;
					}
				}

				// Fix issues created by bad backend merge resulting in [[], {}, {}...]
				if(page.comments.length && $.isArray(page.comments[0])) {
					page.comments.splice(0, 1);
					page.propertyChange('comments', page.comments, true);
				}
			}
			if(definition.studentLabelCSS && page.studentLabelCSS) {
				page.studentLabelCSS.css = definition.studentLabelCSS;
				if(typeof page.studentLabelCSS.css === 'string') {
					page.studentLabelCSS.css = JSON.parse(page.studentLabelCSS.css);
				}
				if($.isArray(page.studentLabelCSS.css)) {
					page.studentLabelCSS.css = {};
				}
			}
			if(definition.pageNumberCSS) {
				page.pageNumberCSS = page.createPageNumberCSSBundle(definition.pageNumberCSS);
			}

			// Should show 1-based
			var pageNumber = isNaN(definition.pageNumber) ? definition.page : definition.pageNumber;
			// Don't call function since it triggers db save
			page.pageNumber = pageNumber;

			this.loadPageClassObj(page);
			this.loadJSONValue(definition, page, 'extras');
			this.loadJSONValue(definition, page, 'subjectCellData');
			this.loadAlternativeVersions(definition, page);
			this.runMigrationChanges(page);

			if (page.updatePageLabel) {
				page.updatePageLabel();
			}
		},
		loadPageClassObj: function(page) {
			this.loadPageExtraClasses(page);
			this.loadPageMainClass(page);
		},
		loadPageMainClass: function(page) {
			if(page.classObj) {
				for(let j = 0; j < this.classes.length; j++) {
					var batch = this.classes[j];
					if((page.classObj && page.classObj.id && page.classObj.id == batch.id) || page.classObj == batch.id) {
						page.classObj = batch;
						batch.active = true;
						break;
					}
				}

				if(typeof page.classObj == 'number') {
					// We keep getting cases of users losing all classes from their pages at once
					if(this.classes.length) {
						page.classObj = {
							id: page.classObj
						};
						page.removeClassObj(page.classObj, {
							permanent: true,
							extras: {
								classes: this.classes.arrayOfProperties('id')
							}
						});
					} else {
						page.classObj = null;
					}
				}
			}
		},
		loadPageExtraClasses: function(page) {
			if(page.extraClasses) {
				for(let j = 0; j < page.extraClasses.length; j++) {
					for(var k = 0; k < this.classes.length; k++) {
						var batch = this.classes[k];
						if(page.extraClasses[j] == batch.id) {
							page.extraClasses[j] = batch;
							batch.active = true;
							break;
						}
					}
				}

				for(let j = 0; j < page.extraClasses.length; j++) {
					if(typeof page.extraClasses[j] != 'object') {
						page.extraClasses[j] = {
							id: page.extraClasses[j]
						};

						page.removeExtraClass(page.extraClasses[j], {
							permanent: true
						});
						j--;
					}
				}
			}
		},
		loadJSONValue: function(definition, page, name) {
			if (definition[name] && definition[name] != '[]') {
				if(typeof definition[name] == 'string') {
					page[name] = JSON.parse(definition[name]);
				} else {
					page[name] = definition[name];
				}

				if($.isArray(page[name])) {
					var fixedJSON = {};
					for(let i = 0; i < page[name].length; i++) {
						$.extend(true, fixedJSON, page[name][i]);
					}

					page.propertyChange(name, fixedJSON);
				}
			}
		},
		loadAlternativeVersions: function(definition, page) {
			if(!definition.alternativeVersions) {
				return;
			}

			for(var versionId in definition.alternativeVersions) {
				var versionData = definition.alternativeVersions[versionId];

				var versionPage = new $.FlowVersionPage({
					rootPage: page,
					type: page.type,
					versionId: versionId,
					side: page.side
				});
				this.loadStandardPageData($.extend({
					pageNumber: page.pageNumber
				}, versionData, {
					id: page.id + ',' + versionId
				}), versionPage);
				page.alternativeVersions[versionId] = versionPage;
			}
		},
		runMigrationChanges: function(page) {
			if(page.extras && page.extras.pageMargins) {
				if(page.extras.pageMargins.horizontal && !$.isInit(page.extras.pageMargins.left)) {
					page.extras.pageMargins.left = page.extras.pageMargins.horizontal;
					page.extras.pageMargins.right = page.extras.pageMargins.horizontal;
					delete page.extras.pageMargins.horizontal;
				}

				if(page.extras.pageMargins.vertical && !$.isInit(page.extras.pageMargins.top)) {
					page.extras.pageMargins.top = page.extras.pageMargins.vertical;
					page.extras.pageMargins.bottom = page.extras.pageMargins.vertical;
					delete page.extras.pageMargins.vertical;
				}
			}
		},
		addEntirePage: function (page, inserted, extras) {
			if(!extras) {
				extras = {};
			}

			var pageData = this.getEntirePageData(page);
			if(inserted !== false) {
				pageData.inserted = true;
			}
			if(extras.stripPageNumber) {
				delete pageData.pageNumber;
			}

			this.queueChange({
				scope: 'pages',
				name: page.getId(),
				value: pageData
			}, true);

			return pageData;
		},
		getEntirePageData: function(page) {
			return {
				id: page.id,
				pageNumber: page.pageNumber,
				layout: page.layout ? page.layout : null,
				candids: page.candids,
				texts: page.texts,
				theme: page.theme ? page.theme : null,
				title: page.title ? page.title : null,
				type: page.type,
				extras: page.extras ? page.extras : null,
				comments: page.comments,
				subjectCellData: page.subjectCellData,
				studentLabelCSS: page.studentLabelCSS ? JSON.stringify(page.studentLabelCSS.css) : null
			};
		},
		loadSubjectData: function (events, plicProjectId, onComplete) {
			var me = this;
			var jobId = this.postData.jobId;
			var subjects, template, batches, allBatches;
			var lastError = null;
			var chain = new $.ExecutionChain(function(stats) {
				if(!subjects || !template || !batches || stats.errors) {
					me.loadError(events, lastError);
					return;
				}

				var placeholderSubjects = [];
				subjects = $.SubjectManagement.populateSubjectsWithTemplate(subjects, template, null, placeholderSubjects);
				if(me.pageSet) {
					me.pageSet.setSubjects(subjects);
					me.pageSet.setPlaceholderSubjects(placeholderSubjects);

					if(me.pageSet.setSubjectTemplate) {
						me.pageSet.setSubjectTemplate(template);
					}
				}

				if($.SubjectManagement.cacheSubjectFields) {
					$.SubjectManagement.cacheSubjectFields(jobId, subjects, placeholderSubjects, template, {
						batches: allBatches,
						groupBy: me.groupBy,
						sortBy: me.sortby
					});
				}

				if (subjects.length && !batches.length) {
					var filter = $.SubjectManagement.getDefaultFilter({
						projectType: $.getProjectSetting('projectType')
					});
					if(me.groupBy && me.sortBy) {
						filter = me.groupBy + ' and sort by ' + me.sortBy;
					}

					$.SubjectManagement.recreateBatchesFromFilter({
						job: {
							jobId: jobId,
							plicProjectId: plicProjectId,
						},
						jobId: jobId,
						filter: filter,
						subjects: subjects,
						success: function () {
							$.ajax({
								url: 'ajax/getBatches.php',
								dataType: 'json',
								data: {
									jobId: jobId
								},
								type: 'POST',
								success: function (data) {
									batches = data.results;
									batches.batchNameSort();

									$.SubjectManagement.addSubjectsToBatches(batches, subjects);
									onComplete(batches);
								},
								error: function (jqXHR) {
									me.loadError(events, jqXHR);
								}
							});
						},
						error: function (jqXHR) {
							me.loadError(events, jqXHR);
						}
					});
				} else {
					batches.batchNameSort();
					var unmatchedSubjects = $.SubjectManagement.addSubjectsToBatches(batches, subjects, placeholderSubjects, {
						jobId: jobId,
						groupBy: me.groupBy,
						sortBy: me.sortBy,
						lastSubjectUpdateAt: me.lastSubjectUpdateAt,
						lastSubjectsLoadedAt: me.lastSubjectsLoadedAt
					});
					$.merge(unmatchedSubjects, placeholderSubjects);

					// Start from scratch if all new subjects
					if(subjects.length && unmatchedSubjects.length === (subjects.length + placeholderSubjects.length)) {
						$.SubjectManagement.recreateBatchesFromFilter({
							job: {
								jobId: jobId,
								plicProjectId: plicProjectId,
							},
							jobId: jobId,
							filter: $.SubjectManagement.AUTO_COMPLETE_FIELDS,
							subjects: subjects,
							success: function () {
								$.ajax({
									url: 'ajax/getBatches.php',
									dataType: 'json',
									data: {
										jobId: jobId
									},
									type: 'POST',
									success: function (data) {
										batches = data.results;
										batches.batchNameSort();

										$.SubjectManagement.addSubjectsToBatches(batches, subjects);
										onComplete(batches);
									},
									error: function (jqXHR) {
										me.loadError(events, jqXHR);
									}
								});
							},
							error: function (jqXHR) {
								me.loadError(events, jqXHR);
							}
						});
					} else {
						onComplete(batches);
					}
				}
			});

			$.loadSubjects({
				plicProjectId: this.plicProjectId,
				loadOrders: this.loadOrders,
				chain: chain,
				onComplete: function(loadedSubjects) {
					subjects = loadedSubjects;
				},
				onError: function(jqXHR) {
					lastError = jqXHR;
				}
			});

			chain.add($.getPlicAPI({
				method: 'projects/' + plicProjectId,
				params: {
					include_project_template: true
				},
				type: 'GET',
				success: function (data) {
					template = data.project_template;

					$.plicProjectData = data.project;
					if(obj.pageSet && data.project.background_photo_id) {
						obj.pageSet.projectBackgroundId = data.project.background_photo_id;
						chain.add({
							url: 'ajax/getPhoto.php?id=' + obj.pageSet.projectBackgroundId + '&opts[w]=200&returnRedirect=true',
							dataType: 'text',
							success: function(data) {
								obj.pageSet.projectBackgroundCdnUrl = data;
							}
						});
					}

					if(obj.includeOrganizationData) {
						chain.add($.getPlicAPI({
							method: 'organizations/' + data.project.organization_id,
							success: function(data) {
								$.plicProjectOrganizationData = data.organization;
							},
							error: function (jqXHR) {
								lastError = jqXHR;
							}
						}));
					}
				},
				error: function (jqXHR) {
					lastError = jqXHR;
				}
			}));

			if(obj.includeLicenseData) {
				chain.add($.getPlicAPI({
					method: 'users/me',
					params: {
						include_licenses: {
							plic_app_id: $.plicSlug,
							filter_sub_orgs: true
						},
						include_plic_apps: true
					},
					type: 'GET',
					success: function(data) {
						var myUser = data.user;
						$.plicLicense = null;
						for(let i = 0; i < myUser.licenses.length; i++) {
							var license = myUser.licenses[i];
							if(!$.plicLicense || !license.master_plic_app_license_id) {
								$.plicLicense = license;
							}
						}

						if($.plicLicense && $.plicLicenseFeatures) {
							$.plicLicense.feature_flags.forEach(function(feature) {
								$.plicLicenseFeatures[feature.plic_app_feature_slug] = feature.enabled;
							});
						}
					}
				}));
			}
			if(obj.includeStudioLicenseData) {
				chain.add($.getPlicAPI({
					method: 'organizations/' + obj.includeStudioLicenseData + '/plic-app-licenses',
					params: {
						plic_app_id: $.plicSlug
					},
					type: 'GET',
					success: function(data) {
						let studioLicense = data.plic_app_licenses[0];
						// A lab can't load a studio's license if it isn't distributed by them
						if(studioLicense && studioLicense.master_plic_app_license_id) {
							chain.add($.getPlicAPI({
								method: 'plic-app-licenses/' + studioLicense.id,
								type: 'GET',
								success: function(data) {
									data.feature_flags.forEach(feature => {
										if(feature.enabled) {
											$.plicLicenseFeatures[feature.plic_app_feature_slug] = feature.enabled;
										}
									});
								}
							}));
						}
					}
				}));
			}

			chain.add({
				url: 'ajax/getBatches.php',
				dataType: 'json',
				data: {
					jobId: jobId,
					allowEmpty: true
				},
				type: 'POST',
				success: function (data) {
					allBatches = data.results;
					batches = allBatches.filter(function(batch) {
						return batch.subjectCount > 0;
					});
				},
				error: function (jqXHR) {
					lastError = jqXHR;
				}
			});
			chain.done();
		},

		// Iterate through and preload all of the fonts that we are going to be using in this book
		checkLoadFonts: function() {
			if(!this.pageSet || !$.FlowLayoutSVGUtils || !document.fonts || !document.fonts.check || $.isBackgroundRenderer) {
				return;
			}

			// Keep a local copy so we can cut down on as many unecessary document.fonts.load calls as possible since $.FlowLayoutSVGUtils.globalLoadedFonts won't update until done
			let fontFamilyLoading = {};
			this.preloadFontFamily('open sans', fontFamilyLoading);
			if(this.pageSet.pageNumberCSS?.css) {
				this.preloadFontFamily(this.pageSet.pageNumberCSS.css.fontFamily, fontFamilyLoading);
			}
			this.pageSet.pages.forEach(page => {
				if(page.texts && $.isPlainObject(page.texts)) {
					Object.values(page.texts).filter(text => !!text).forEach(text => {
						$.FlowLayoutSVGUtils.iterateInstanceParts(text, (part) => {
							this.preloadFontFamily(part.fontFamily, fontFamilyLoading);
						});
					});
				}

				if(page.studentLabelCSS && page.studentLabelCSS.css) {
					this.preloadFontFamily(page.studentLabelCSS.css.fontFamily, fontFamilyLoading);
				}

				if(page.subjectCellData && page.subjectCellData.global) {
					Object.values(page.subjectCellData.global).filter(type => !!type).forEach(type => {
						this.preloadFontFamily(type.fontFamily, fontFamilyLoading);
					});
				}
			});
		},
		preloadFontFamily: function(fontFamily, fontFamilyLoading) {
			if(fontFamily && !fontFamilyLoading[fontFamily] && !$.FlowLayoutSVGUtils.globalLoadedFonts[fontFamily]) {
				document.fonts.load('12px "' + fontFamily + '"').then(() => {
					if(document.fonts.check('12px "' + fontFamily + '"')) {
						$.FlowLayoutSVGUtils.globalLoadedFonts[fontFamily] = true;
					} else {
						window.addFontToWaitCallbackList(fontFamily, true);
					}
				}).catch((e) => {
					console.error('Failed to load font ' + fontFamily, e);
				});
				fontFamilyLoading[fontFamily] = true;
			}
		},

		// Put as async so we don't end up slowing down load waiting for this to run
		async checkCustomColors() {
			if(!this.pageSet || !$.FlowLayoutSVGUtils || $.isBackgroundRenderer) {
				return;
			}

			const existingPalette = $.DefaultColorPalette.flat();
			if(this.pageSet.pageNumberCSS?.css) {
				this.checkCustomColorText(this.pageSet.pageNumberCSS.css, existingPalette);
			}

			for(let i = 0; i < this.pageSet.pages.length; i++) {
				let page = this.pageSet.pages[i];

				if(page.texts && $.isPlainObject(page.texts)) {
					Object.values(page.texts).filter(text => !!text).forEach(text => {
						$.FlowLayoutSVGUtils.iterateInstanceLines(text, (line) => {
							this.checkCustomColorText(line, existingPalette);

							if(line.parts) {
								line.parts.forEach(part => {
									this.checkCustomColorText(part, existingPalette);
								});
							}
						});

						if(text.border) {
							this.checkAddCustomColor(text.border.color, existingPalette);
						}
					});
				}
				if(page.studentLabelCSS?.css) {
					this.checkCustomColorText(page.studentLabelCSS.css, existingPalette);
				}
				if(page.subjectCellData && page.subjectCellData.global) {
					Object.values(page.subjectCellData.global).filter(type => !!type).forEach(type => {
						this.checkCustomColorText(type, existingPalette);
					});
				}
				if(page.extras?.subjectEffects) {
					this.checkCustomColorImage(page.extras.subjectEffects, existingPalette);
				}

				if(page.candids && $.isPlainObject(page.candids)) {
					Object.values(page.candids).filter(candid => !!candid).forEach(candid => {
						this.checkCustomColorImage(candid, existingPalette);
					});
				}

				await sleep(0);
			}

			$.CustomColorPalette.sort();
			if($.CustomColorPalette.length >= $.MaxCustomColorPalette) {
				$.CustomColorPalette.length = $.MaxCustomColorPalette;
			}
		},
		checkCustomColorText(part, existingPalette) {
			if(!part) {
				return;
			}

			this.checkAddCustomColor(part.color, existingPalette);
			if(part['drop-shadow']) {
				this.checkAddCustomColor(part['drop-shadow'].color, existingPalette);
			}
			if(part.stroke) {
				this.checkAddCustomColor(part.stroke.color, existingPalette);
			}
			if(part['background-color']) {
				this.checkAddCustomColor(part['background-color'], existingPalette);
			}
		},
		checkCustomColorImage(image, existingPalette) {
			if(!image) {
				return;
			}

			if(image.dropShadow) {
				this.checkAddCustomColor(image.dropShadow.color, existingPalette);
			}
			if(image.border) {
				this.checkAddCustomColor(image.border.color, existingPalette);
			}
		},
		checkAddCustomColor(color, existingPalette) {
			if(!color || typeof color !== 'string') {
				return;
			}

			let hex = color;
			if(hex.includes('rgb')) {
				hex = $.convertRGBToHex(hex).substr(1).toUpperCase();
			}

			if(!existingPalette.includes(hex) && hex !== 'FFFFFF' && hex !== '000000') {
				existingPalette.push(hex);
				$.CustomColorPalette.push(hex);
			}
		}
	});

	$.UserActivityModule(obj);
	return obj;
};