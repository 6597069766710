$.ZoomControl = function(options) {
	var div = $('<div class="ui blue icon button zoomControl"><i class="search icon"/></div>')[0];
	$(div).attr('data-tooltip', i18n.t('composer.zoom.buttonTooltip'));

	$.extend(div, {
		init: function() {
			this.initKeyboardListener();
			this.initScrollListener();
		},
		initKeyboardListener: function() {
			$(document).on('keydown', this.globalKeyboardListener = function(event) {
				if(event.metaKey) {
					event.ctrlKey = true;
				}

				if (event.ctrlKey && (event.keyCode == 61 || event.keyCode == 107 || event.keyCode == 187)) {
					div.increaseZoom();

					event.preventDefault();
					return false;
				}

				if (event.ctrlKey && (event.keyCode == 173 || event.keyCode == 189 || event.keyCode == 109)) {
					div.decreaseZoom();
					
					event.preventDefault();
					return false;
				}
			});			
		},
		initScrollListener: function() {
			window.addEventListener('wheel', this.globalScrollListener = function(event) {
				if(event.metaKey) {
					event.ctrlKey = true;
				}

				if(event.ctrlKey) {
					// We can trigger some different wheel behavior when we are focused on a node
					if(!event.target || !event.target.focused) {
						if(event.deltaY < 0) {
							div.increaseZoom();
						} else {
							div.decreaseZoom();
						}
					}

					event.preventDefault();
					return false;
				}
			}, {
				passive: false
			});
		},

		increaseZoom: function() {
			this.instance.zoom = Math.min(this.maxZoom, this.instance.zoom + this.zoomIncrement);
			
			$.setSingleTimeout.call(this, 'delayedUpdateZoom', function() {
				this.updateZoom();
			}, this.zoomDelay);
		},
		decreaseZoom: function() {
			this.instance.zoom = Math.max(this.minZoom, this.instance.zoom - this.zoomIncrement);
			
			$.setSingleTimeout.call(this, 'delayedUpdateZoom', function() {
				this.updateZoom();
			}, this.zoomDelay);
		},
		resetZoom: function() {
			this.instance.zoom = 100;
			this.updateZoom();
		},
		setZoom: function(zoom) {
			this.instance.zoom = zoom;
			this.updateZoom();
		},
		updateZoom: function() {
			this.flowLayoutSet.setZoom(this.instance.zoom);
		},
		viewHighResolutionPreview: function() {
			var content = $('<div class="content contentPreview">').css({
				display: 'flex',
				'justify-content': 'center'
			});
		
			var flowLayoutSet, hideBleed = false;
			var dialog = $('<div class="ui basic fullscreen modal">')
				.append(content)
				.css({
					minHeight: 'calc(100% - 2em)'
				})
				.modal({
					onShow: function() {
						flowLayoutSet = new $.FlowLayoutSet(content, {
							editable: false,
							pageSet: $.yearbook,
							pageOffset: 1
						});
						flowLayoutSet.wrapPages = true;
						flowLayoutSet.currentPage = $.flowLayout.currentPage;
		
						var page = flowLayoutSet.getCurrentPageForLayoutIndex(0);
						hideBleed = page && page.type !== 'cover';
						flowLayoutSet.addPageLayout(new $.FlowLayout({
							id: "leftPage",
							side: 'Left',
							forcedDPI: 300,
							editable: false,
							fixedSize: true,
							shouldHideInsidePageBorder: true,
							hideBleed: hideBleed,
							includeWhiteSpace: div.includeWhiteSpace
						}));
						flowLayoutSet.addPageLayout(new $.FlowLayout({
							id: "rightPage",
							side: 'Right',
							forcedDPI: 300,
							editable: false,
							fixedSize: true,
							shouldHideInsidePageBorder: true,
							hideBleed: hideBleed,
							includeWhiteSpace: div.includeWhiteSpace
						}));
					},
					onVisible: function() {
						var modalRect = dialog[0].getBoundingClientRect();
						var contentPadding = parseInt(dialog.children('.content').css('paddingTop').replace('px', '')) * 2;
		
						var contentHeight = modalRect.height - contentPadding;
						var contentWidth = modalRect.width;
		
						var pageWidth, pageHeight;
						var maxPageWidth = contentWidth / 2;
						var maxPageHeight = contentHeight;
						
						var page = flowLayoutSet.getCurrentPageForLayoutIndex(0);
						var outerDimensions = flowLayoutSet.pageSet.getOuterDimensions('Right', {
							includeWhiteSpace: div.includeWhiteSpace
						});
						var layout = page && page.getLayout({
							includeWhiteSpace: div.includeWhiteSpace
						});
						if(layout && layout.grid && layout.grid.width) {
							outerDimensions = $.extend(true, {}, layout.grid);
						}

						var inchWidth = outerDimensions.width;
						var inchHeight = outerDimensions.height;
						if(hideBleed) {
							inchWidth = inchWidth - (outerDimensions.bleed.left + outerDimensions.bleed.right) + outerDimensions.safeSpace.left + outerDimensions.safeSpace.right;
							inchHeight = inchHeight - (outerDimensions.bleed.top + outerDimensions.bleed.bottom) + outerDimensions.safeSpace.top + outerDimensions.safeSpace.bottom;
						}
						if(inchHeight > inchWidth) {
							pageHeight = maxPageHeight;
							pageWidth = pageHeight * (inchWidth / inchHeight);
						} else {
							pageWidth = maxPageWidth;
							pageHeight = pageWidth * (inchHeight / inchWidth);
						}
		
						dialog.find('.flowPage').css({
							height: pageHeight,
							width: pageWidth
						});
		
						flowLayoutSet.goToPage($.flowLayout.currentPage);
		
						// Now we want it to shrink to as small as possible
						dialog.removeClass('fullscreen');
					},
					onHidden: function() {
						$(this).remove();
						document.documentElement.scrollTop = 0;
					}
				}).modal('show');
		
			dialog[0].style.setProperty('margin', '1em auto', 'important');
		},
		destroy: function() {
			$(document).off('keydown', this.globalKeyboardListener);
			window.removeEventListener('wheel', this.globalScrollListener);
		},

		zoomIncrement: 5,
		instance: {
			zoom: 100
		},
		minZoom: 100,
		maxZoom: 500,
		zoomDelay: 400,
		includeWhiteSpace: false
	}, options);

	let zoomLevels = [];
	for(let i = div.minZoom; i < div.maxZoom; i += 25) {
		zoomLevels.push(i + '%');
	}

	var editTools = [
		{
			addClass: 'icon',
			updateDisplayAll: true,
			group: [
				{
					icon: 'minus',
					popup: i18n.t('composer.zoom.zoomOut'),
					onClick: function(selection) {
						this.decreaseZoom();
					}
				},
				{
					title: i18n.t('composer.zoom.zoom'),
					dropdown: zoomLevels,
					onChange: function(selection, text) {
						selection.zoom = parseInt(text.replace('%', ''));
						div.updateZoom();
					},
					updateLabel: function(selection, dropdown) {
						dropdown.dropdown('set text', i18n.t('composer.zoom.zoomLevel', {
							level: selection.zoom
						}));
					}
				},
				{
					icon: 'plus',
					popup: i18n.t('composer.zoom.zoomIn'),
					onClick: function(selection) {
						this.increaseZoom();
					}
				}
			]
		}
	];

	if(div.showHighResolutionPreview) {
		editTools.push({
			icon: 'crosshairs',
			popup: i18n.t('composer.zoom.highResPreview'),
			onClick: function(selection) {
				this.viewHighResolutionPreview();
			},
			closeOnClick: true
		});
	}

	$.FlowLayoutToolbar(div, {
		editTools: editTools,
		getEditToolsDistance: function() {
			return 10;
		},
	});
	div.setEditable(true);
	div.init();

	return div;
};