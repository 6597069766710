$.isOrderedIndividualizedComposite = function(subject) {
	var productSKUs = $.getProjectSetting('individualizedCompositeSkus');
	var productField = $.getProjectSetting('individualizedCompositeOrderField');
	if(!productField || !productSKUs || !productSKUs.length) {
		return true;
	} else if(!subject.orders) {
		return false;
	}

	for(var i = 0; i < subject.orders.length; i++) {
		var order = subject.orders[i];

		for(var j = 0; j < order.ordered_packages.length; j++) {
			var orderedPackage = order.ordered_packages[j];
			if(productField === 'Package SKU' && productSKUs.includes(orderedPackage.sku)) {
				return true;
			} else if(productField === 'Product SKU') {
				for(var k = 0; k < orderedPackage.ordered_products.length; k++) {
					var product = orderedPackage.ordered_products[k];
					if(productSKUs.includes(product.sku)) {
						return true;
					}
				}
			}
		}
	}

	return false;
};