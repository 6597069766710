<template>
	<span>
		<span v-for="(part, partIndex) in parts" :key="part.text + partIndex" :style="part.style">{{ part.text }}</span>
	</span>
</template>

<script>
export default {
	props: ['option', 'match'],
	computed: {
		parts() {
			if(!this.match.length) {
				return [{
					text: this.option,
					style: {}
				}];
			}

			let matchIndex = this.option.toLowerCase().indexOf(this.match);
			if(matchIndex === -1) {
				return [{
					text: this.option,
					style: {}
				}];
			}

			return [
				{
					text: this.option.substr(0, matchIndex),
					style: {}
				},
				{
					text: this.option.substr(matchIndex, this.match.length),
					style: {
						'font-weight': 'bold',
						color: 'green'
					}
				},
				{
					text: this.option.substr(matchIndex + this.match.length)
				}
			];
		}
	}
};
</script>