$.BarcodeFontTypes = [
	'Code128',
	'Code128A',
	'Code128B',
	'Code128C',
	'Code39',
	'EAN13',
	'QR Code'
];
$.BarcodeWidths = [
	1,
	2,
	3,
	4
];
$.DefaultFontSizes = [6, 7, 8,9,10,11,12,14,16,18,20,22,24,26,28,30,32,34,36,38,40,44,48,52,56,60,66,72, 80, 88, 96, 104, 112, 120, 130, 140, 150, 160, 170, 180, 190, 200];
// This array is expected to be updated in place (do NOT replace it or it won't update in $.DefaultColorPalette anymore)
$.CustomColorPalette = [];
$.MaxCustomColorPalette = 42;
// Array of arrays allows us to have separation between different groups of colors - ie: one group of defaults and another of our custom colors that we add as we go
$.DefaultColorPalette = [['000', '800000', '8B4513', '2F4F4F', '008080', '000080', '4B0082', '696969', 'B22222', 'A52A2A', 'DAA520', '006400', '40E0D0', '0000CD', '800080', '808080', 'FF0000', 'FF8C00', 'FFD700', '008000', '0FF', '00F', 'EE82EE', 'A9A9A9', 'FFA07A', 'FFA500', 'FFFF00', '00FF00', 'AFEEEE', 'ADD8E6', 'DDA0DD', 'D3D3D3', 'FFF0F5', 'FAEBD7', 'FFFFE0', 'F0FFF0', 'F0FFFF', 'F0F8FF', 'E6E6FA', 'FFF'], $.CustomColorPalette];
$.AutoScalingFontFloor = false;
$.DEFAULT_LINE_HEIGHT = 1.33;
$.DEFAULT_TAB_SPACES = '      ';
$.isPrinceRenderer = false;
$.waitingForFontCSSLoad = {};

$(document).ready(function() {
	if($('link').filter(function() { return this.href.includes('fonts.google') && this.href.includes('Open+Sans') }).length === 0) {
		loadGoogleFontsList($.GlobalGoogleFontList, true);
		$.BarcodeFontTypes.sort();
	}
});

if(window.blockPreloadingFonts === undefined && document.fonts && document.fonts.check) {
	window.blockPreloadingFonts = true;
}

function loadGoogleFontsList(fontList, isGlobalList) {
	if(!isGlobalList) {
		fontList = fontList.filter(function(font) {
			return $.GoogleFontList.indexOf(font) === -1;
		});
		if(fontList.length === 0) {
			return;
		}

		$.merge($.GoogleFontList, fontList);
	}
	$.GoogleFontList.caseInsensitiveSort();

	// Append a google font link with all of these
	var fontUrl = 'https://fonts.googleapis.com/css?family=' + fontList.map(function(font) {
		if(font === 'Coda Caption') {
			return 'Coda Caption:800';
		} else {
			return font;
		}
	}).join('|').replace(/ /ig, '+') + '&subset=all';

	var fontLink = '<link rel="stylesheet" type="text/css" href="' + fontUrl + '"/>';
	if(isGlobalList) {
		$.GoogleFontUrl = fontUrl;
		$.GoogleFontLink = fontLink;
	}

	if(!window.blockRemoteFonts) {
		let fontElem = $(fontLink);
		$('head').append(fontElem);
		// When done loading, we want to make sure that we have finished loading all of the elements that might have been waiting for this to finish
		fontElem[0].onload = function() {
			fontList.map(fontFamily => fontFamily.toLowerCase()).forEach(fontFamily => {
				if($.waitingForFontCSSLoad[fontFamily]) {
					document.fonts.load('12px "' + fontFamily + '"').then(() => {
						if(document.fonts.check('12px "' + fontFamily + '"')) {
							$.FlowLayoutSVGUtils.globalLoadedFonts[fontFamily] = true;

							if($.waitingForFontCSSLoad[fontFamily]) {
								$.waitingForFontCSSLoad[fontFamily].forEach(callback => {
									if(typeof callback === 'function') {
										callback();
									}
								});
								delete $.waitingForFontCSSLoad[fontFamily];
							}
						}
					}).catch((e) => {
						console.error('Failed to load font ' + fontFamily, e);
					});
				}
			});
		}

		if(!window.blockPreloadingFonts) {
			// Make sure each font is manually pre-loaded.  Otherwise on first use it will render incorrectly and appear behind subjects
			fontList.forEach(function(font) {
				var div = document.createElement('div');
				div.className = 'preLoadFonts';
				div.innerHTML = 'T';
				div.style.fontFamily = '"' + font.toLowerCase() + '"';
				$('body').append(div);
			});
		}
	}

	fontList.forEach(function(font) {
		var lowerCaseFont = font.toLowerCase();
		if(!$.GoogleFontSpecialTitleCaseMap[lowerCaseFont] && lowerCaseFont.toTitleCase() !== font) {
			$.GoogleFontSpecialTitleCaseMap[lowerCaseFont] = font;
		}
	});

	// Preload emoji font
	if(!window.blockRemoteFonts && !window.blockPreloadingFonts) {
		if(isGlobalList) {
			var div = document.createElement('div');
			div.className = 'preLoadFonts';
			div.innerHTML = '😂';
			$('body').append(div);
		}

		window.setTimeout(function() {
			$('.preLoadFonts').remove();
		}, 1000);
	}
}
window.loadGoogleFontsList = loadGoogleFontsList;

function getDictionaryOrgId() {
	return $.OrgId;
}
window.getDictionaryOrgId = getDictionaryOrgId;

function addFontToWaitCallbackList(fontFamily, callback) {
	if(!$.waitingForFontCSSLoad[fontFamily]) {
		$.waitingForFontCSSLoad[fontFamily] = [];
	}

	$.waitingForFontCSSLoad[fontFamily].push(callback);
}
window.addFontToWaitCallbackList = addFontToWaitCallbackList;