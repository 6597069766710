$.ProofPage = function(options) {
	var div = $('<div class="proof-page">')[0];
	let pageWrapper = $('<div class="proof-img-wrapper">').appendTo(div);

	$.extend(div, {
		setImageView: function(url, index = 0, photo = null) {
			if(this.imgViews.length <= index) {
				let wrapper = $('<div class="proof-img">').appendTo(pageWrapper);
				this.imgViews.push($('<img>').appendTo(wrapper));
				this.setZoom(this.zoom, index);
			}

			this.setLoading(false);
			let imgView = this.imgViews[index];
			let imgViewWrapper = imgView.parent();
			$('<div class="ui inverted active dimmer"><div class="ui text loader">Loading</div></div>').appendTo(imgViewWrapper);
			imgView.LoadImage(url, {
				onComplete: function() {
					imgViewWrapper.find('.dimmer').remove();
				},
				onFailed: function() {
					imgViewWrapper.find('.dimmer').remove();
				}
			});

			imgView.off('click');
			if(this.poseSelectionEnabled) {
				this.initPoseSelection(imgView);
			}
			imgView.photo = photo;
			if(photo) {
				imgView.attr('title', photo.upload_file_name);
			} else {
				imgView.removeAttr('title');
			}
		},
		updateImageViewPoseSelection: function() {
			$(this).find('.corner.label').remove();
			$(this).find('.active-selection').removeClass('active-selection');

			if(this.page.poseSelectionId) {
				let imgView = this.imgViews.find(imgView => imgView.photo && imgView.photo.id == this.page.poseSelectionId);
				if(imgView) {
					imgView.parent().append('<a class="ui corner label green"><i class="checkmark icon"></i></a>').addClass('active-selection');
				}
			}
		},
		setPage: function(page) {
			this.page = page;

			let expectedImgViews = 1;
			if(page.imageUrl) {
				this.setImageView(page.imageUrl);
			} else if(page.imageUrls) {
				// Want to show selected poses at front so we don't have to hunt for it
				if(page.poseSelectionId) {
					let selectedPoseIndex = page.imageUrls.findIndex(photo => photo.id === page.poseSelectionId);
					if(selectedPoseIndex !== -1) {
						let selectedPose = page.imageUrls[selectedPoseIndex];
						page.imageUrls.splice(selectedPoseIndex, 1);
						page.imageUrls.unshift(selectedPose);
					}
				}

				page.imageUrls.forEach((photo, index) => {
					this.setImageView(photo.cdnUrl, index, photo);
				});
				expectedImgViews = page.imageUrls.length;
				this.updateImageViewPoseSelection();
			}

			while(this.imgViews.length > expectedImgViews) {
				let removeView = this.imgViews.pop();
				removeView.parent().remove();
			}
		},
		setPoseSelectionEnabled: function(poseSelectionEnabled) {
			this.poseSelectionEnabled = poseSelectionEnabled;

			if(this.poseSelectionEnabled) {
				this.imgViews.forEach(imgView => {
					this.initPoseSelection(imgView);
				});
			} else {
				this.imgViews.forEach(imgView => {
					imgView.off('click').css('cursor', '');
				});
			}
		},
		initPoseSelection: function(imgView) {
			imgView.off('click').on('click', () => {
				if(this.page.poseSelectionId === imgView.photo.id) {
					this.page.setProperty('poseSelectionId', null);
				} else {
					this.page.setProperty('poseSelectionId', imgView.photo.id);
				}
				this.updateImageViewPoseSelection();
				this.proofView.updateApproval();
			}).css('cursor', 'pointer');
		},
		getPage: function() {
			return this.page;
		},
		setZoom: function(zoom) {
			this.zoom = zoom;
			$(this).css('overflow', 'auto');

			this.imgViews.forEach(imgView => {
				imgView.css({
					width: 'auto',
					height: zoom + '%',
					'object-fit': 'contain',
					'max-width': 'initial',
					'max-height': 'initial'
				});
			});
		},

		setLoading: function(loading) {
			if(loading) {
				this.loader.addClass('active');
			} else {
				this.loader.removeClass('active');
			}
		},
		zoom: 100,

		imgViews: [],
		poseSelection: false
	}, options);

	if(!div.loader) {
		div.loader = $('<div class="ui inverted dimmer"><div class="ui text loader">Loading</div></div>').appendTo(div);
	}

	return div;
};