// Only include stuff in here that we know for a fact that all apps will need
// Remember Deliveries does not even have a Composer
import './core-libs';
import moment from 'moment';
Vue.mixin({
	methods: {
		moment: (value, format) => moment(value).format(format),
		momentDate: (value) => moment(value).format('MMMM D, YYYY'),
		momentDateTime: (value) => moment(value).format('MMMM D, YYYY h:mm A')
	}
});

import flatpickr from 'flatpickr';
window.flatpickr = flatpickr;
import 'flatpickr/dist/themes/material_blue.css';

import Cropper from 'cropperjs/dist/cropper.js';
require('cropperjs/dist/cropper.css');
window.Cropper = Cropper;

import '../../js/lib/mightyslider.packed.js';
import '../../css/lib/mightyslider.css';
import '../../css/lib/mightyslider_carousel.css';

import 'fancybox/dist/js/jquery.fancybox';
import 'fancybox/dist/css/jquery.fancybox.css';

import Pako from 'pako';
window.pako = Pako;

import hopscotch from 'hopscotch/dist/js/hopscotch';
require('hopscotch/dist/css/hopscotch.css');
window.hopscotch = hopscotch;

import Masonry from 'masonry-layout/dist/masonry.pkgd';
window.masonry = Masonry;

import alertify from '../../js/lib/alertify.js';
window.alertify = alertify;
require('../../css/lib/alertify.css');

// https://www.npmjs.com/package/semantic-ui-range does not include the latest fix for init while element is hidden that we rely on
import '../../js/lib/semantic-range.js';
import '../../css/lib/semantic-range.css';

// https://github.com/bgrins/spectrum does not include fix for removing event listeners in "function destroy" (ie: "$(doc).unbind("keydown.spectrum", onkeydown);")
import '../../js/lib/spectrum.js';
import '../../css/lib/spectrum.css';

import '../../js/lib/jquery.reject.js';
import '../../css/lib/jquery.reject.css';
require('../../js/lib/jquery.ui.touch-punch.min.js');

// TODO: Really need to start removing dependecy on this
import 'jquery-ui-1-11-4/jquery-ui.js';
import 'jquery-ui-1-11-4/jquery-ui.css';
// We are using Semantic UI's not Jquery UI's accordion component
$.fn.accordion = $.fn.semanticAccordion;