import { v4 as uuidv4 } from 'uuid';

export default function createUpload(props) {
	return {
		id: uuidv4(),
		uploaded: false,
		uploading: false,
		error: null,
		rejectReason: null,
		progress: 0,
		converting: false,
		converted: false,
		checkingFormat: false,
		checkedFormat: false,
		progressInfo: '',
		isNewPhoto: false,
		skipUpload: false,
		cropData: null,
		photoCategory: null,
		waitFor: [],
		type: 'image',
		maxDefaultPercentage: 99,
		...props
	};
}