<template>
	<v-menu offset-y v-model="menuActive" :open-on-click="false" :close-on-click="false" :close-on-content-click="false">
		<template v-slot:activator="{ on }">
			<v-textarea
				:label="label"
				:value="value"
				@input="handleInput"
				:disabled="disabled"
				@keypress.enter="enter"
				class="multiple-search-input"
				ref="input"
				@focus="focusInput"
				@blur="blurInput"
				v-on="on"
				auto-grow
				no-resize
				rows="1"
				dense />
		</template>

		<v-list class="list" v-if="filtered.length > 0">
			<v-list-item class="option multiple-search-option" @click="select(option)" v-for="option in filtered" :key="option">
				<multiple-search-result :option="option" :match="match" />
			</v-list-item>
		</v-list>
	</v-menu>
</template>

<script>
import MultipleSearchResult from './MultipleSearchResult.vue';

const MAX_OPTIONS = 50;
export default {
	props: ['value', 'options', 'label', 'disabled'],
	data: () => ({
		menuActive: false
	}),
	computed: {
		match() {
			let value = this.value.toLowerCase().trim();
			let selectionEnd = this.$refs.input?.$el.querySelector('textarea').selectionEnd ?? this.value.length;
			let nextSeparator = value.indexOf(',', selectionEnd);
			if(nextSeparator !== -1) {
				selectionEnd = nextSeparator;
			} else {
				selectionEnd = value.length;
			}

			let match = value.substr(0, selectionEnd);
			let lastSeparator = match.lastIndexOf(',');
			if(lastSeparator !== -1) {
				match = match.substr(lastSeparator + 1).trim();
			}

			return match;
		},
		filtered() {
			let match = this.match;
			let alreadySelected = this.value.toLowerCase().split(',').map(text => text.trim()).filter(selected => {
				return !!this.options.find(option => option.toLowerCase() === selected);
			});
			let options = this.options.filter(option => {
				return !alreadySelected.includes(option.toLowerCase());
			});
			if(!match.length) {
				return [];
			}

			return options.filter(option => {
				return option.toLowerCase().includes(match);
			}).filter((option, index, self) => self.indexOf(option) === index).slice(0, MAX_OPTIONS);
		}
	},
	methods: {
		handleInput(value) {
			this.$emit('input', value);
		},
		enter(event) {
			event.preventDefault();
			if(!this.filtered.length) {
				return false;
			}

			let selected = this.$el.querySelector('.v-list-item--highlighted');
			if(selected) {
				this.select(selected.textContent.trim());
			} else {
				this.select(this.filtered[0]);
			}

			this.$refs.input?.$el.querySelector('textarea').focus();

			return false;
		},
		select(option) {
			let currentInput = this.value;
			let retainInput = '';

			let lastSeparator = currentInput.lastIndexOf(',');
			if(lastSeparator !== -1) {
				let input = this.$refs.input.$el.querySelector('textarea');
				let selectionEnd = input.selectionEnd;
				let nextSeparator = input.value.indexOf(',', selectionEnd);
				
				if(selectionEnd <= nextSeparator) {
					currentInput = currentInput.substr(0, nextSeparator);
					retainInput = this.value.substr(nextSeparator);
					lastSeparator = currentInput.lastIndexOf(',');
				}
			}

			if(lastSeparator !== -1) {
				let includeChars = 1;
				while(currentInput[lastSeparator + includeChars] === ' ') {
					includeChars++;
				}

				let oldInput = currentInput.substr(0, lastSeparator + includeChars);

				// If we have not typed a manual comma after finishing last selection, allow selecting something to auto add a comma
				let newInput = currentInput.substr(lastSeparator + includeChars).trim();
				if(this.options.includes(newInput)) {
					oldInput = currentInput + ', ';
				}

				this.$emit('input', oldInput + option + retainInput);
			} else {
				let isExactMatch = !!this.options.find(option => option === currentInput);
				if(isExactMatch) {
					this.$emit('input', currentInput + ', ' + option + retainInput);
				} else {
					this.$emit('input', option + retainInput);
				}
			}
		},
		focusInput() {
			this.menuActive = true;
			this.$refs.input.$el.querySelector('textarea').setSelectionRange(this.value.length, this.value.length);
		},
		blurInput(event) {
			// Don't blur when clicking an option - selecting removes options automatically
			if([...(event.relatedTarget?.classList ?? [])].includes('multiple-search-option')) {
				return;
			}

			this.menuActive = false;
		}
	},
	components: {
		MultipleSearchResult
	}
};
</script>

<style scoped>
.list {
	max-height: 30em;
	overflow-y: scroll;
}
.option {
	cursor: pointer;
}
</style>