$.ProofView = function(options) {
	var div = $('<div class="proof-view">')[0];

	$.extend(div, {
		init: function() {
			this.proof = $.Proof();

			this.loader = $('<div class="ui inverted dimmer"><div class="ui text loader">Loading</div></div>').appendTo(this);
			this.initHeader();
			this.initBody();
			this.initFooter();

			this.db = new $.ProofDB({
				progressIndicator: this.progressIndicator,
				postData: {
					proofId: this.proofId,
					composer: this.composer,
					notificationId: this.notificationId
				},
				proof: this.proof
			});
		},
		initHeader: function() {
			this.headerBar = $('<div class="proof-header-bar">');

			var proofPagesListWrapper = $('<div class="ui mightySliderWrapper">');
			let wrapper = $('<div>');
			proofPagesListWrapper.append(wrapper).appendTo(this.headerBar);
			this.pagesList = new Vue({
				props: ['renders', 'showWaitingReviewFirst', 'canCreateNewBatch', 'loadAlbumOptions'],
				render: function(createElement) {
					return createElement('proof-batch-header', {
						props: {
							batches: this.renders || [],
							showWaitingReviewFirst: this.showWaitingReviewFirst,
							editable: this.canCreateNewBatch,
							canCreateNewBatch: this.canCreateNewBatch,
							loadAlbumOptions: this.loadAlbumOptions
						},
						on: {
							'clicked-batch': function(page) {
								div.setCurrentPage(page);

								if(div.proofData && div.proofData.jobId && $.userExtras && page.batchId) {
									var lastLoadedBatches = $.userExtras.lastLoadedBatches || {};
									lastLoadedBatches[div.proofData.jobId] = page.batchId;
									$.ajax({
										url: 'ajax/saveUserExtra.php',
										dataType: 'json',
										data: {
											name: 'lastLoadedBatches',
											value: lastLoadedBatches
										},
										type: 'POST'
									});
								}
							},
							'create-new-batch': function(settings) {
								let render = div.proof.insertRender({
									name: settings.name,
									photo: settings.photo,
									imageUrl: $.getPlicThumbnail(settings.photo, {
										w: 2000
									}),
									pageNumber: div.renders.reduce((max, render) => Math.max(max, render.pageNumber), 0) + 1,
									compositeProofId: div.proof.compositeProofId
								});

								div.updatePagesListRenders();
								div.setCurrentPage(render);
							},
							'batch-renamed': function(batch, newName) {
								batch.setName(newName);
							}
						}
					});
				},
				vuetify: window.Vuetify
			}).$mount(wrapper[0]);
			this.pagesListDiv = this.pagesList.$el;

			if(this.showApprovalButtons) {
				var progressIndicatorWrapper = $('<div class="proof-progress-indicator-wrapper">').appendTo(this.headerBar);
				this.progressIndicator = $('<i class="proof-progress-indicator" style="display: none; display: block;"></i>').appendTo(progressIndicatorWrapper);
			}

			$(this).append(this.headerBar);
		},
		initBody: function() {
			this.body = $('<div class="proof-body">');

			$('<div class="ui left-placeholder">').appendTo(this.body);

			this.pageDiv = $.ProofPage({
				loader: this.loader
			});
			this.pageDiv.proofView = this;
			$(this.body).append(this.pageDiv);

			var options = {
				editable: (this.showApprovalButtons || !this.composer),
				onCommentSubmit: function(comment) {
					var id = comment.id = $.getUniqueId();
					div.currentPage.jsonPropertyChange('comments', id, comment, {
						permanent: true
					});
				},
				addCommentButton: 'Add Correction'
			};
			if(!this.showApprovalButtons) {
				options.onCheck = function(rootComment, checkedComment) {
					if(rootComment == checkedComment) {
						div.currentPage.jsonSubPropertyChange('comments', rootComment.id, 'checked', rootComment.checked, {
							permanent: true,
							mergeSub: true
						});
					} else {
						// is a reply
						div.currentPage.jsonSubPropertyDeepChange('comments', rootComment.id, ['replies', checkedComment.id, 'checked'], checkedComment.checked, {
							permanent: true,
							mergeSub: true
						});
					}
				};

				options.onDelete = function(rootComment, deletedComment) {
					if(rootComment == deletedComment) {
						div.currentPage.jsonSubPropertyChange('comments', rootComment.id, 'deleted', rootComment.deleted, {
							permanent: true,
							mergeSub: true
						});
					} else {
						// is a reply
						div.currentPage.jsonSubPropertyDeepChange('comments', rootComment.id, ['replies', deletedComment.id, 'deleted'], deletedComment.deleted, {
							permanent: true,
							mergeSub: true
						});
					}
				}
			}
			if(options.editable) {
				options.onReply = function(comment, reply) {
					div.currentPage.jsonSubPropertyChange('comments', comment.id, 'replies', comment.replies, {
						permanent: true,
						mergeSub: true
					});
				};
			}

			this.comments = $.CommentFeed(null, options);
			this.commentsWrapper = $('<div class="ui segment commentSegment proof-comments">').append(this.comments).appendTo(this.body);

			$(this).append(this.body);
		},
		initFooter: function() {
			if(this.bottomBar) {
				this.bottomBar.remove();
			}

			this.bottomBar = $('<div class="proof-bottom-bar">').appendTo(this);

			if(this.showApprovalButtons) {
				this.initApprovalButtons();
			} else {
				if(!this.composer) {
					this.updateProofDiv = $('<div class="ui form proof-update-form">');
					$(this.bottomBar).append(this.updateProofDiv);

					this.isVisibleButton = $('<div class="ui icon button"><i class="eye icon" /></div>').click(function() {
						div.toggleIsVisible();
					});
					$(this.bottomBar).append(this.isVisibleButton);
					this.atFrontButton = $('<div class="ui icon button"><i class="arrow alternate circle left icon" /></div>').click(function() {
						div.toggleAtFront();
					});
					$(this.bottomBar).append(this.atFrontButton);
					this.canEditRowNamesButton = $('<div class="ui icon button"><i class="edit icon" /></div>').click(function() {
						div.toggleCanEditRowNames();
					}).hide();
					$(this.bottomBar).append(this.canEditRowNamesButton);

					this.extraDataToCollectButton = $('<div class="ui icon button" data-tooltip="Click to edit additional info collected from each group"><i class="list icon" /></div>').click(function() {
						div.editExtraDataToCollect();
					}).hide();
					$(this.bottomBar).append(this.extraDataToCollectButton);

					this.editDeadlineDiv = $('<div class="ui blue button edit-deadline-button"><i class="calendar icon"></i> </div>');
					this.editDeadlineLabel = $('<span>').appendTo(this.editDeadlineDiv);
					this.editDeadlineDiv.click((e) => {
						e.stopPropagation();
						e.preventDefault();

						if(!this.editDeadlineDiv.deadlinePicker) {
							this.initDeadlinePicker();
						}

						this.editDeadlineDiv.deadlinePicker.open();
					});
					$(this.bottomBar).append(this.editDeadlineDiv);
				}

				this.initApprovalResults();
			}

			var zoomButton = this.zoomButton = new $.ZoomControl({
				flowLayoutSet: this,
				zoomDelay: 0,
				minZoom: 50
			});
			$(zoomButton).attr('id', 'flow-layout-zoom-control').appendTo(this.bottomBar);

			// eslint-disable-next-line
			if(this.showApprovalButtons || this.composer) {
				
			} else {
				this.downloadEnabledButton = $('<div class="ui icon button" style="margin-left: 0.5em"><i class="download icon" /></div>')
					.appendTo(this.bottomBar);
			}
		},
		initApprovalButtons: function() {
			this.denyButton = $('<div class="ui negative button deny-button">').text(this.denyButtonText).click(function() {
				div.saveApproved(false);
			}).appendTo(this.bottomBar);

			this.submitButton = $('<div class="ui blue icon button submit-proof-button">Finalize and Submit <i class="location arrow icon"/></div>').click(function() {
				if($(this).hasClass('disabled')) {
					return;
				}

				div.submitProof();
			}).css({
				marginLeft: '1em',
				marginRight: '1em'
			}).appendTo(this.bottomBar);

			this.approveButton = $('<div class="ui positive button approve-button">').text(this.approveButtonText).click(function() {
				if(div.proofData.type === 'seniors') {
					div.saveApproved(true);
				} else if(div.proofData.type === 'group-rows') {
					let rowComments = Object.values(div.currentPage.comments).filter(comment => {
						return comment.id.includes('row-') && !comment.deleted;
					});
					let rowsWithNoNames = rowComments.filter(comment => !comment.comment.trim());

					if(rowsWithNoNames.length) {
						$.Alert('Error', 'Approving the group rows requires you to enter names for each row or delete the rows with nothing in them.');
					} else {
						$.Confirm('Are you done with this batch?', 'Do you have any more names to add?  Once you approve this batch, you will no longer be able to edit the names.', function() {
							div.saveApproved(true);
						});
					}
				} else {
					$.Confirm('Are you done with this batch?', 'Do you have any more comments to add?  Once you approve this batch, you will no longer be able to add comments to it.', function() {
						div.saveApproved(true);
					});
				}
			}).appendTo(this.bottomBar);

			this.deadlineStat = $('<div class="ui mini statistic deadline-stat" style="visibility: hidden"><div class="value"><i class="calendar icon"></i> <span class="days">0 days</span></div> <div class="label">Until deadline</div></div>').prependTo(this.bottomBar);
			this.bogusRightStat = $('<div class="ui mini statistic" style="visibility: hidden"><div class="value"><i class="calendar icon"></i> <span class="days">0 days</span></div> <div class="label">Until deadline</div></div>').appendTo(this.bottomBar);
		},
		updateSubmitButton: function() {
			if(!this.submitButton) {
				return;
			}

			if(this.submitButton.hasClass('hasPopup')) {
				this.submitButton.removeClass('hasPopup').popup('destroy');
			}

			var unreviewed = this.renders.filter(function(render) {
				return !render.reviewedAt || !$.isInit(render.approved);
			});
			if(unreviewed.length) {
				var unreviewedNames = unreviewed.map(function(render) {
					return render.name;
				});

				this.submitButton.addClass('disabled hasPopup').popup({
					title: 'Review these proofs to continue:',
					content: unreviewedNames.join(', ')
				});
			} else {
				this.submitButton.removeClass('disabled').click();
			}
		},
		initApprovalResults: function() {
			this.approvedStatusLabel = $('<div class="ui large label">').appendTo(this.bottomBar);
		},
		initDeadlinePicker: function() {
			let input = $('<input type="text" style="display: none" />');
			this.editDeadlineDiv.append(input);
			
			this.editDeadlineDiv.deadlinePicker = flatpickr(input[0], {
				onChange: (dates) => {
					this.proof.setDeadline(moment(dates[0]).format('MM/DD/YYYY'));
					this.setDeadlineLabel();
				},
				static: true
			});
		},
		setDeadlineLabel: function() {
			if(this.editDeadlineDiv) {
				if(this.proof.deadline) {
					this.editDeadlineDiv.removeClass('red green blue').attr('data-tooltip', 'Click to edit deadline');
					let date = Date.parse(this.proof.deadline);
					if(date < new Date()) {
						this.editDeadlineDiv.addClass('red');
					} else {
						this.editDeadlineDiv.addClass('green');
					}

					let formattedDate = moment(this.proof.deadline).format('MM/DD/YYYY');
					this.editDeadlineLabel.text('Deadline: ' +formattedDate);
				} else {
					this.editDeadlineDiv.removeClass('red green').addClass('blue').removeAttr('data-tooltip');
					this.editDeadlineLabel.text('Create Deadline');
				}
			} else if(this.deadlineStat) {
				if(this.proof.deadline) {
					$(this.deadlineStat).css('visibility', '');

					let days = this.proof.getDeadlineDays();
					if(days < 0) {
						$(this.deadlineStat).addClass('red');
						$(this.deadlineStat).find('.label').text('Days past due');
						$.Alert('Error', 'This proof is past its deadline.  Please contact your studio or photographer in order to make additional corrections. ');
					} else {
						$(this.deadlineStat).removeClass('red');
						$(this.deadlineStat).find('.label').text('Until deadline');
					}

					$(this.deadlineStat).find('.days').text(Math.abs(days) + ' days');
				} else {
					$(this.deadlineStat).css('visibility', 'hidden');
				}
			}
		},
		load: function(events) {
			if(!events) {
				events = {};
			}

			this.setLoading(true);

			this.db.load({
				onReady: function(data) {
					if(events.onReady) {
						events.onReady(data);
					}

					div.setProof(data.compositeProof);
				}
			})
		},
		setProof: function(proofData) {
			this.proofData = proofData;
			this.db.loadFromModel(proofData);
			this.db.postData.proofId = proofData.compositeProofId;
			this.pageDiv.setPoseSelectionEnabled(false);

			this.updateCommentsBar(proofData.type);
			this.setRenders(div.proof.getRenders());
			if(proofData.approvedAt) {
				this.setEditable(false);
				this.comments.setEditable(!this.composer);
				this.comments.setCanUseActions(!this.composer);
			}

			$(this).removeClass('group-row-proof subjects-proof');
			$(this.canEditRowNamesButton).add(this.extraDataToCollectButton).hide();
			this.pagesList.showWaitingReviewFirst = this.showApprovalButtons;
			let hasImageBatches = !!this.proof.getRenders().find(function(render) {
				return !!render.photo;
			});
			this.pagesList.canCreateNewBatch = !this.showApprovalButtons && (proofData.type === 'group-rows' || proofData.type === 'comments') && hasImageBatches;
			this.pagesList.loadAlbumOptions = {
				tag: 'Proof Images',
				resourceType: 'project',
				resourceId: proofData.plicProjectId
			};
			if(proofData.type === 'group-rows') {
				$(this).addClass('group-row-proof');
				$(this.canEditRowNamesButton).add(this.extraDataToCollectButton).show();
			} else if(proofData.type === 'seniors') {
				$(this).addClass('subjects-proof');
				$(this).find('.deny-button').hide();
				this.pagesList.showWaitingReviewFirst = this.showApprovalButtons;

				if(this.zoomButton.instance.zoom === 100) {
					this.zoomButton.setZoom(50);
				}
				if(this.showApprovalButtons) {
					$('<div class="ui blue message"><i class="info icon"></i> Click on an image to select it as the desired pose.  After selecting a pose, click Approve Subject at the bottom and move to the next one.</div>').prependTo(this.commentsWrapper);
				}

				this.comments.options.addCommentButton = 'Add Comment';
				let commentButton = $(this.comments).find('.submit.button')[0];
				if(commentButton && commentButton.childNodes.length > 1) {
					commentButton.childNodes[1].textContent = 'Add Comment';
				}
			}

			if(this.updateProofDiv) {
				if(hasImageBatches) {
					var wrapper = $('<div id="proof-image-uploader"></div>').appendTo(this.updateProofDiv);
					new Vue({
						render: function(createElement) {
							return createElement('button-uploader', {
								props: {
									label: 'Replace Batch Image',
									loadAlbumOptions: {
										tag: 'Proof Images',
										resourceType: 'project',
										resourceId: proofData.plicProjectId
									},
									dropTarget: div.body[0]
								},
								on: {
									'uploads-finished': function(uploads) {
										div.updateProofImage(uploads[0]);
									}
								}
							});
						},
						vuetify: window.Vuetify
					}).$mount(wrapper[0]);
				} else if((this.proofData.type || 'comments') === 'comments') {
					var initValue = null;
					if(this.currentPage) {
						initValue = this.currentPage.pDFId;
					}

					this.updateProofDiv.SettingsBuilder([
						{
							id: 'render',
							type: 'dropdown',
							description: 'Update render',
							loadAjax: {
								url: 'ajax/getPdfs.php',
								data: {
									jobId: proofData.jobId,
									requireFields: ['PdfName'],
									layoutRenders: false
								}
							},
							itemId: 'renderId',
							itemName: 'renderName',
							value: initValue,
							onChange: function(text, value, extras) {
								var newPdf = extras.item;

								if(newPdf.renderId != div.currentPage.pDFId) {
									$.Confirm('Change Render', 'Are you sure you want to change the render for this proof?  This will re-open the proof for approval by the customer.', function() {
										div.updateProofRender(newPdf);
									});
								}
							}
						}
					]);
				}
			}

			this.updateSubmitButton();

			if(this.showApprovalButtons || this.composer) {
				if(this.proof.customerDownload) {
					if(!this.downloadButton) {
						this.downloadButton = $('<a class="ui icon button" style="margin-left: 0.5em" target="_blank"><i class="download icon" /></a>')
							.attr('data-tooltip', 'Download renders')
							.appendTo(this.bottomBar);
					}

					this.downloadButton.attr('href', $.AltDownloadDomain + 'ajax/streamProofImages.php?proofId=' + this.proof.compositeProofId + '&streamingFormat=pdf&filterVisible=true');
				} else if(this.downloadButton) {
					this.downloadButton.remove();
					this.downloadButton = null;
				}
			} else if(this.downloadEnabledButton) {
				this.updateDownloadEnabledButton();
			}
			this.setCanEditRowNames();
			this.setDeadlineLabel();
			
			// This stat needs to be at the end to keep everything centered
			if(this.bogusRightStat) {
				this.bogusRightStat.detach().appendTo(this.bottomBar);
			}
		},
		updateDownloadEnabledButton: function() {
			var me = this;
			if(this.proof.customerDownload) {
				this.downloadEnabledButton.attr('data-tooltip', 'Click to turn off customers being able to download the renders as a PDF')
					.removeClass('red').addClass('green')
					.off('click').on('click', function() {
						me.proof.setCustomerDownload(false);
						me.updateDownloadEnabledButton();
					});
			} else {
				this.downloadEnabledButton.attr('data-tooltip', 'Click to turn on customers being able to download the renders as a PDF')
					.removeClass('green').addClass('red')
					.off('click').on('click', function() {
						me.proof.setCustomerDownload(true);
						me.updateDownloadEnabledButton();
					});
			}
		},
		updateCommentsBar: function(type) {
			if((type || 'comments') === 'comments') {
				if(!$(this.commentsWrapper).isAttached(this)) {
					$(this.commentsWrapper).appendTo(this.body);
				}
				if(!$(this.comments).isAttached(this)) {
					$(this.comments).appendTo(this.commentsWrapper);
				}
				if(this.groupRowComments) {
					$(this.groupRowComments).remove();
					this.groupRowComments = null;
				}
			} else if(type === 'group-rows') {
				if(!$(this.commentsWrapper).isAttached(this)) {
					$(this.commentsWrapper).appendTo(this.body);
				}
				if($(this.comments).isAttached(this)) {
					$(this.comments).detach();
				}

				if(!this.groupRowComments) {
					var wrapper = $('<div></div>').appendTo(this.commentsWrapper)[0];
					this.groupRowComments = new Vue({
						props: ['render'],
						render: function(createElement) {
							return createElement('group-row-comments', {
								props: {
									render: this.render,
									proof: this.proof
								},
								on: {
									
								}
							});
						},
						vuetify: window.Vuetify
					}).$mount(wrapper);
				}
			}
		},

		setRenders: function(renders) {
			this.renders = renders;
			if(this.composer) {
				this.renders = renders = renders.filter(function(page) {
					return page.visible;
				});
			}

			let sortedRenders = this.updatePagesListRenders(renders);
			if(sortedRenders.length) {
				var defaultRender = sortedRenders[0];
				if(this.showApprovalButtons) {
					defaultRender = sortedRenders.find(render => render.atFront) || sortedRenders.find(render => !$.isInit(render.approved)) || sortedRenders[0];
				}

				if(this.proofData && this.proofData.jobId && $.userExtras) {
					var lastLoadedBatches = $.userExtras.lastLoadedBatches || {};
					var lastLoadedBatch = lastLoadedBatches[this.proofData.jobId];
					if(lastLoadedBatch) {
						var startRender = sortedRenders.filter(function(render) {
							return render.batchId == lastLoadedBatch;
						})[0];

						if(startRender) {
							defaultRender = startRender;
						}
					}
				}

				this.setCurrentPage(defaultRender);
			} else {
				this.setCurrentPage(null);
			}
		},
		updatePagesListRenders: function(renders) {
			var proofData = this.proofData;
			if(!renders) {
				renders = this.renders;
			}

			var sortedRenders = $.merge([], renders);
			sortedRenders.batchNameSort();
			sortedRenders.forEach(function(render) {
				if(render.active === undefined) {
					Vue.set(render, 'active', false);
					if(proofData && proofData.jobId) {
						Vue.set(render, 'jobId', proofData.jobId);
					}
				}
			});
			this.pagesList.renders = sortedRenders;

			return sortedRenders;
		},
		setCurrentPage: function(page) {
			this.currentPage = page;
			if(!page) {
				this.setEmptyProof(true);
				this.setLoading(false);
				return;
			}

			this.renders.forEach(function(render) {
				render.active = false;
			});
			page.active = true;

			this.pageDiv.setPage(page);
			var isCustomer = !!this.composer;

			if(this.groupRowComments) {
				this.groupRowComments.render = page;
				this.groupRowComments.proof = this.proof;
			} else {
				var comments = $.merge([], Object.values(page.comments).map(function(comment) {
					// Cloning object breaks checking off comment, so careful to only do this when that isn't an option!
					if(isCustomer) {
						var commentCloned = false;
						if(comment.author && comment.author.userId) {
							comment = $.extend(true, {}, comment);
							commentCloned = true;
							comment.author.name = 'Studio Editor';
							comment.author.title = comment.author.userName = '';
						}

						if(comment.checked && comment.checked.author && comment.checked.author.userId) {
							if(!commentCloned) {
								comment = $.extend(true, {}, comment);
								commentCloned = true;
							}

							comment.checked.author.name = 'Studio Editor';
							comment.checked.author.title = comment.checked.author.userName = '';
						}

						if(comment.replies) {
							if(!commentCloned) {
								comment = $.extend(true, {}, comment);
								commentCloned = true;
							}

							Object.values(comment.replies).forEach(function(reply) {
								if(reply.author && reply.author.userId) {
									reply.author.name = 'Studio Editor';
									reply.author.title = reply.author.userName = '';
								}
							});
						}
					}

					return comment;
				}));
				comments.sortByKey('time');

				this.comments.setComments(comments);
				if(this.showApprovalButtons) {
					this.comments.setEditable(!$.isInit(page.approved));
					this.comments.setCanUseActions(!$.isInit(page.approved));
				} else if(!this.composer) {
					this.comments.setEditable(true);
					this.comments.setCanUseActions(true);
				}
			}
			
			this.setApproved(page.approved, page);
			this.setIsVisible(page);
			this.setAtFront(page);

			if(this.updateProofDiv && this.updateProofDiv.getFieldByName) {
				var renderSetting = this.updateProofDiv.getFieldByName('render');
				if(renderSetting) {
					renderSetting.setSelected(page.pDFId);
				}
			}

			let deadlinePastDue = this.proof.getDeadlineDays() < 0;
			if(deadlinePastDue && this.showApprovalButtons) {
				if(!this.groupRowComments) {
					this.comments.setEditable(false);
					this.comments.setCanUseActions(false);
				}

				$(this.approveButton).add(this.denyButton).addClass('disabled');
			}
		},
		setEmptyProof: function(empty) {
			if(empty) {
				this.body.empty();
				this.body.append('<div class="ui warning message no-proof-message">This project does not have any visible pages available to proof.  If you think there should be pages to proof please contact your Studio or Lab for help.</div>');
				this.bottomBar.empty();
			}
		},
		saveApproved: function(approved) {
			let textArea = $(this.comments).find('textarea')[0];
			if(textArea?.value) {
				this.comments.submitNewComment();
			}

			if(approved === false && $.getObjectCount(this.currentPage.comments) === 0) {
				$.EditNameModal('Why are you denying this proof?', 'Reason', null, function(comment) {
					div.saveApprovedImpl(approved);
					div.comments.saveNewComment(comment);
				});
			} else {
				this.saveApprovedImpl(approved);
			}
		},
		saveApprovedImpl: function(approved) {
			this.pageDiv.getPage().setApproved(approved);
			this.setApproved(approved, this.currentPage);
			this.updateSubmitButton();
		},
		removeAllBatchApprovals: function() {
			this.renders.forEach(function(render) {
				if(render.approved === true) {
					render.setApproved(null);
					div.setApproved(null, render);
				}
			});

			this.updateSubmitButton();
		},
		updateApproval: function() {
			if(this.currentPage) {
				this.setApproved(this.currentPage.approved, this.currentPage);
			}
		},
		setApproved: function(approved, page) {
			if(this.showApprovalButtons) {
				$(this.denyButton).removeClass('disabled icon').text(this.denyButtonText);

				var approveButtonText = this.approveButtonText;
				let approvedButtonText = this.approvedButtonText;
				if((this.proofData.type || 'comments') === 'group-rows') {
					approveButtonText = this.approveRowButtonText;
				} else if(this.proofData.type === 'seniors') {
					approveButtonText = this.approveSubjectsButtonText;
					approvedButtonText = this.approvedSubjectButtonText;
				}
				$(this.approveButton).removeClass('disabled icon').text(approveButtonText);

				if(approved) {
					$(this.approveButton).addClass('icon').text(approvedButtonText).append(' <i class="ui thumbs up icon"/>');
				} else if (approved === false) {
					$(this.denyButton).addClass(' icon').text(this.deniedButtonText).append(' <i class="ui thumbs down icon"/>');
				}

				if(approved === true || approved === false) {
					$(this.approveButton).add(this.denyButton).addClass('disabled');
					this.comments.setEditable(false);
					this.comments.setCanUseActions(false);
					if(this.proofData.type === 'seniors') {
						this.pageDiv.setPoseSelectionEnabled(false);
					}
				} else if(this.proofData.type === 'seniors') {
					if(!page.poseSelectionId) {
						$(this.approveButton).addClass('disabled');
					}
					this.pageDiv.setPoseSelectionEnabled(true);
				}
			} else {
				this.approvedStatusLabel.removeClass('red green').removeAttr('data-tooltip');
				let reviewedBy = page.reviewedBy;
				if(page.reviewedByEmail) {
					reviewedBy += ' (' + page.reviewedByEmail + ')';
				}

				if(approved) {
					this.approvedStatusLabel.addClass('green').html('Approved by ' + reviewedBy + ' <i class="thumbs up icon">');
					this.approvedStatusLabel.attr('data-tooltip', moment(page.reviewedAt).format('MMMM Do YYYY hh:mm A'));

					if(!this.composer) {
						$('<div class="ui tiny icon button" data-tooltip="Remove approval so customer can approve/deny page again" data-position="left center"><i class="unlock icon" /></div>').click(function() {
							div.saveApproved(null);
						}).appendTo(this.approvedStatusLabel);
					}
				} else if(approved === false) {
					this.approvedStatusLabel.addClass('red').html('Denied by ' + reviewedBy + ' <i class="thumbs down icon">');
					this.approvedStatusLabel.attr('data-tooltip', moment(page.reviewedAt).format('MMMM Do YYYY hh:mm A'));

					if(!this.composer) {
						$('<div class="ui tiny icon button" data-tooltip="Remove denial so customer can approve/deny page again" data-position="left center"><i class="unlock icon" /></div>').click(function() {
							div.saveApproved(null);
						}).appendTo(this.approvedStatusLabel);
					}
				} else {
					this.approvedStatusLabel.text('Not reviewed');
				}
			}
		},
		toggleIsVisible: function() {
			var page = this.currentPage;

			page.setProperty('visible', !page.visible);
			this.setIsVisible(page);
		},
		setIsVisible: function(page) {
			if(!this.isVisibleButton) {
				return;
			}

			if(page.visible) {
				this.isVisibleButton.attr('data-tooltip', 'Click to hide from customer when viewing proof').removeClass('yellow').addClass('green active');
			} else {
				this.isVisibleButton.attr('data-tooltip', 'Click to show to customer when viewing proof').removeClass('green active').addClass('yellow');
			}
		},
		toggleAtFront: function() {
			var page = this.currentPage;

			page.setProperty('atFront', !page.atFront);
			this.setAtFront(page);
		},
		setAtFront: function(page) {
			if(!this.atFrontButton) {
				return;
			}

			if(page.atFront) {
				this.atFrontButton.attr('data-tooltip', 'Click to push this batch to the front of the list even when approved').removeClass('orange').addClass('green active');
			} else {
				this.atFrontButton.attr('data-tooltip', 'Click to show this batch in normal sort order').removeClass('green active').addClass('orange');
			}
		},
		toggleCanEditRowNames: function() {
			this.proof.setCanEditRowNames(!this.proof.canEditRowNames);
			this.setCanEditRowNames();
		},
		setCanEditRowNames: function() {
			if(!this.canEditRowNamesButton) {
				return;
			}

			if(this.proof.canEditRowNames) {
				this.canEditRowNamesButton.attr('data-tooltip', 'Click to disable customers from editing row names').removeClass('red').addClass('green active');
			} else {
				this.canEditRowNamesButton.attr('data-tooltip', 'Click to enable customers from editing row names').removeClass('green active').addClass('red');
			}
		},
		editExtraDataToCollect: function() {
			$.SettingsBuilderDialog([
				{
					id: 'extraDataToCollect',
					type: 'tagPicker',
					value: this.proof.extraDataToCollect
				}
			], {
				title: 'Data to collect',
				onSettingsApplied: (currentSettings) => {
					this.proof.setExtraDataToCollect(currentSettings.extraDataToCollect);
				}
			})
		},
		submitProof: function() {
			var unreviewed = this.renders.filter(function(render) {
				return !render.reviewedAt || !$.isInit(render.approved);
			});
			var denied = this.renders.filter(function(render) {
				return render.approved === false;
			});

			if(unreviewed.length) {
				$.ConfirmCheckbox('Approve Remaining Proofs?', 'You still have ' + unreviewed.length + ' proofs remaining.  Are you sure you want to submit and approve the remaining proofs?', function() {
					unreviewed.forEach(function(render) {
						render.setApproved(true);
					});

					div.confirmSubmitProof();
				});
			} else if(denied.length) {
				$.Confirm('Submit Proofs', 'Do you want submit your review with ' + denied.length + ' denied?', function() {
					div.confirmSubmitProof();
				});
			} else {
				$.Confirm('Submit Proofs', 'Do you want to submit your approval of these proofs?', function() {
					div.confirmSubmitProof();
				});
			}
		},
		confirmSubmitProof: function() {
			this.proof.submit();
			this.setEditable(false);
		},
		setEditable: function(editable) {
			this.showApprovalButtons = editable;

			this.initFooter();
			this.setCurrentPage(this.currentPage);
		},
		updateProofRender: function(newPdf) {
			this.setLoading(true);
			if(newPdf.pages <= 1) {
				var batchId = null;
				if(newPdf.batchIds && newPdf.batchIds.length) {
					batchId = newPdf.batchIds[0];
				}

				this.currentPage.setPDFRender(newPdf, undefined, batchId);
			} else {
				this.updateProofRenderPageBatches(newPdf);
			}

			this.proof.unsubmit();

			// Because we are not doing any live changes of data, another user/tab can update the renders and end up with duplicate batches.
			// Currently we are just de-dupping which will change the batch id from what we saved here.  If we don't reload, then we can end up changing a non-existant batch.
			this.db.saveChanges({
				onSavesComplete: () => {
					$.ajax({
						url: 'ajax/compositeProofs.php',
						data: {
							proofId: this.proof.compositeProofId
						},
						dataType: 'json',
						success: (data) => {
							this.proof.renders = [];
							data.compositeProof.renders.forEach(renderData => {
								this.proof.addRender(this.db.createRenderFromData(renderData));
							});
							this.setRenders(this.proof.renders);
							this.setLoading(false);
						},
						error: () => {
							$.Alert('Error', 'Failed to reload proof information');
							this.setLoading(false);
						}
					});
				},
				onSavesError: () => {
					this.setLoading(false);
				}
			});

			if(this.onUpdateProofRender) {
				this.onUpdateProofRender(this.currentPage);
			}
		},
		updateProofImage: function(upload) {
			this.currentPage.setNewPhoto(upload.photo);

			this.setCurrentPage(this.currentPage);
			this.proof.unsubmit();
			
			if(this.onUpdateProofRender) {
				this.onUpdateProofRender(this.currentPage);
			}
		},
		updateProofRenderPageBatches: function(newPdf) {
			if(!newPdf.batchName) {
				return;
			}

			var batches = JSON.parse(newPdf.batchName);
			if(!batches || batches.length != newPdf.pages) {
				return;
			}

			// Add new batches and update existing ones
			for(let i = 0; i < newPdf.pages; i++) {
				var batchId = null;
				if(newPdf.batchIds && newPdf.batchIds[i]) {
					batchId = newPdf.batchIds[i];
					if(batchId && typeof batchId === 'string') {
						batchId = parseInt(batchId);
					}
				}
				var newBatchName = batches[i];

				var matchedRender = null;
				if(batchId) {
					matchedRender = this.renders.getMatch({batchId: batchId}, 'batchId');
				}
				if(!matchedRender) {
					matchedRender = this.renders.getMatch({name: newBatchName}, 'name');
				}

				if(matchedRender) {
					matchedRender.setPDFRender(newPdf, i, batchId, newBatchName);
				} else {
					this.proof.insertRender({
						name: newBatchName,
						pDFId: newPdf.renderId,
						pDFPage: i,
						imageUrl: 'ajax/getPdfImage.php?id=' + newPdf.remoteName + '&page=' + i,
						pageNumber: i + 1,
						compositeProofId: this.proof.compositeProofId,
						batchId: batchId
					});
				}
			}

			// Remove missing batches
			if(!/[0-9]+ batches/ig.test(newPdf.renderName)) {
				for(let i = 0; i < this.renders.length; i++) {
					var render = this.renders[i];
					var matchedBatch = batches.filter(function(batch) {
						return batch == render.name;
					})[0];
					if(!matchedBatch && newPdf.batchIds) {
						matchedBatch = newPdf.batchIds.filter(function(batch) {
							return batch == render.batchId;
						})[0];
					}

					if(!matchedBatch) {
						this.proof.deleteRender(render);
						i--;
					}
				}
			}
		},

		setZoom: function(zoom) {
			this.pageDiv.setZoom(zoom);
		},

		setLoading: function(loading) {
			if(loading) {
				this.loader.addClass('active');
			} else {
				this.loader.removeClass('active');
			}
		},
		isLoading: function() {
			return this.loader.hasClass('active');
		},

		composer: false,
		showApprovalButtons: false,

		denyButtonText: 'Deny Proof & Submit Correction(s)',
		deniedButtonText: 'Correction Submitted',
		approveButtonText: 'Approve Proof with No More Corrections',
		approveRowButtonText: 'Approve Group Rows',
		approveSubjectsButtonText: 'Approve Subject',
		approvedButtonText: 'Approved Proof',
		approvedSubjectButtonText: 'Approved Subject',

		currentPage: null
	}, options);

	div.init();

	return div;
};