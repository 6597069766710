$.CompositePage = function(classObj) {
	var obj = new $.FlowPageClass(classObj);
	
	$.extend(obj, {
		setTitle: function(title) {

		},
		getTitle: function () {
			return null;
		},

		isIndividualizedLayout: function() {
			return !!(this.layout && (
				(this.layout.largeCell && this.layout.largeCell.require == '*') ||
				(this.layout.primarySubjectPoseFrame && $.isPlainObject(this.layout.primarySubjectPoseFrame))
			));
		},
		getNextIndividualIndex: function (current) {
			var users = this.getKids();
			if (!users) {
				return null;
			}

			var index = current + 1;
			while (index < users.length && !this.isSubjectIndexIndividual(index)) {
				index++;
			}

			if (index >= users.length) {
				return null;
			} else {
				return index;
			}
		},
		getPreviousIndividualIndex: function (current) {
			var users = this.getKids();
			if (!users) {
				return null;
			}

			var index = current - 1;
			while (index >= 0 && !this.isSubjectIndexIndividual(index)) {
				index--;
			}

			if (index < 0) {
				return null;
			} else {
				return index;
			}
		},
		isSubjectIndexIndividual: function (userIndex) {
			var users = this.getKids();
			if (!users) {
				return false;
			}

			return this.isSubjectIndividual(users[userIndex]);
		},
		isSubjectIndividual: function (user) {
			return !user['Teacher Priority'] || user['Individualize Staff'];
		},

		updatePageLabel: function () {
			var sidebar = [
				this.lockOption = {
					name: this.getFreeMovementLocked() ? 'Unlock' : 'Lock',
					icon: 'lock',
					onClick: function (page, layout) {
						var locked = !page.getFreeMovementLocked();
						page.setFreeMovementLocked(locked);

						$(this).html('<i class="' + (locked ? 'unlock' : 'lock') + ' icon"></i>' + (locked ? 'Unlock' : 'Lock'));
						layout.updateMovementLocked();
						layout.updateLabel();
					}
				}
			];

			if (this.theme) {
				sidebar.push({
					name: 'Backgrounds',
					icon: 'setting',
					popup: 'Change settings on backgrounds',
					onClick: function (page, layout) {
						layout.openThemeSettings();
					}
				});

				sidebar.push({
					name: 'Revert Background',
					icon: 'picture',
					popup: 'Revert background back to theme',
					onClick: function (page, layout) {
						page.setTheme(null);
						layout.updateTheme();

						page.updatePageLabel();
						layout.setLabel(page.getPageLabel());
					}
				});
			}

			if (this.layout) {
				sidebar.push({
					name: 'Subject Settings',
					icon: 'tasks',
					popup: 'Edit subject settings',
					onClick: function (page, layout) {
						layout.openSubjectSettings();
					}
				});
			}

			sidebar.push({
				name: 'Render Batch',
				icon: 'location arrow',
				popup: 'Render single batch',
				onClick: function (page, layout) {
					page.renderBatch();
				}
			});

			if($.userPermissions.siteAdmin) {
				sidebar.push({
					name: 'Preview Batch',
					icon: 'location arrow',
					popup: 'Render single batch',
					onClick: function (page, layout) {
						var preview = new $.PageRender(new $.FlowPageSubSet($.composites, [page]), {
							production: false,
							batchName: page.classObj.name,
							forceSide: 'Right',
							backgroundRender: !$.getGETParams().debugPrince && !$.getGETParams().debugPrinceStart,
							includeBatches: true
						});
						preview.showImageDialog();
					}
				});
			}

			sidebar.push({
				name: 'Save Template',
				icon: 'paint brush',
				popup: 'Save Template for use in other projects',
				onClick: function(page, layout) {
					page.saveTemplate(layout);
				}
			})

			if (sidebar.length) {
				var popup = 'Options';
				if(this.layout && this.layout.grid && this.layout.grid.width && this.layout.grid.height) {
					popup += ' - ' + this.layout.grid.width + '" x ' + this.layout.grid.height + '" Composite';
				} else {
					popup += ' - 10" x 8" Composite';
				}
				popup += this.getLockedPopupMessage();

				this.pageLabel = {
					display: '',
					popup: popup,
					sidebar: sidebar,
					icon: this.getFreeMovementLocked() ? 'lock' : 'setting'
				};
			} else {
				this.pageLabel = null;
			}
		},
		renderBatch: function () {
			var form = $('<div class="ui form settings-builder">');

			var modalSize = '';
			if (this.getNextIndividualIndex && this.isIndividualizedLayout()) {
				var productSKUs = $.getProjectSetting('individualizedCompositeSkus');
				var productField = $.getProjectSetting('individualizedCompositeOrderField');
				var checkProductSKUs = productField && productSKUs && productSKUs.length;

				var users = this.getKids();
				var fields = $('<div class="three fields">').appendTo(form);
				for (var i = 0; i < users.length; i++) {
					var user = users[i];
					var userName = user['Last Name'] + ' ' + user['First Name'];

					var isChecked = this.isSubjectIndividual(user);
					if(checkProductSKUs) {
						isChecked = $.isOrderedIndividualizedComposite(user);
					}
					var checkbox = $('<div class="ui checkbox subjectCheckbox"><input type="checkbox" ' + (isChecked ? 'checked' : '') + '><label>' + userName + '</label></div>');
					checkbox.data('index', i);
					checkbox.checkbox({
						onChange: function () {
							form.removeClass('error');
						}
					});
					if(isChecked) {
						checkbox.addClass('checked');
					}

					$('<div class="inline field">').append(checkbox).appendTo(fields);
				}
				if(users.length > 20) {
					modalSize = 'large';
					fields.removeClass('three').addClass('four');
				}

				var selectAll = $('<div style="margin-top: 1em" class="ui checkbox"><input type="checkbox" name="selectAllBox"><label style="font-weight: bold;">Select All</label></div>');
				selectAll.checkbox({
					onChange: function () {
						var toggle = selectAll.checkbox('is checked');

						if (toggle) {
							form.find('.subjectCheckbox').checkbox('check');
						} else {
							form.find('.subjectCheckbox').checkbox('uncheck');
						}
					}
				});
				$('<div class="inline field">').append(selectAll).appendTo(form);

				if(checkProductSKUs) {
					var fieldName = $.getProjectSetting('individualizedCompositeOrderField') === 'Package SKU' ? 'package' : 'product';

					$('<div class="ui visible warning message"></div>')
						.text('Only subjects with an ordered ' + fieldName + ' with a SKU of ' + productSKUs.joinAnd(', ', ' or ') + ' will be selected by default. You can change this at any time from Project Settings.')
						.appendTo(form);
				}

				form.append('<div class="ui error message">');
			}

			form.append('<div class="field"><label>Render Batch</label><input type="text" value="' + this.classObj.name + '" name="renderBatchName"/></div>');
			form.form({
				fields: {
					renderBatchName: {
						identifier: 'renderBatchName',
						rules: [
							{
								type: 'filename',
								prompt: 'Please enter a Render Batch name'
							}
						]
					}
				}
			});

			var uploadGroupImage = false;
			var uploadGroupImageCheckbox = $('<div style="margin-top: 1em" class="ui checkbox uploadGroupImageCheckbox"><input type="checkbox" name="uploadGroupImageBox"><label>Upload rendered composite as a group image to subjects</label></div>');
			uploadGroupImageCheckbox.checkbox({
				onChange: function() {
					uploadGroupImage = uploadGroupImageCheckbox.checkbox('is checked');
				}
			});
			$('<div class="inline field">').append(uploadGroupImageCheckbox).appendTo(form);

			var me = this;
			$('<div class="ui ' + modalSize + ' modal"><i class="close icon"></i><div class="header">Individualized Elements</div></div>')
				.append($('<div class="content">').append(form))
				.append('<div class="actions"><div class="ui negative button">Cancel</div><div class="ui positive button">Render Batch</div></div>')
				.modal({
					onApprove: function () {
						var batchNameField = form.find('[name="renderBatchName"]');
						var batchName = batchNameField.val().removeDiacritics();
						batchNameField.val(batchName);

						if (!form.form('validate form')) {
							return false;
						}
						var renderBatchName = form.find('input[name="renderBatchName"]').val();

						if (form.find('.subjectCheckbox').length) {
							var individualizedIndexes = [];
							form.find('.subjectCheckbox.checked').each(function () {
								individualizedIndexes.push($(this).data('index'));
							});

							if (!individualizedIndexes.length) {
								form.addClass('error').find('.error.message').html('You must select at least one subject to render as an individualized Element.</p>');
								return false;
							}
						}

						me.renderBatchStart(individualizedIndexes, renderBatchName, {
							extraPostData: {
								uploadGroupImage: uploadGroupImage
							}
						});
					},
					onHidden: function () {
						$(this).remove();
					}
				}).modal('show');
		},
		renderBatchStart: function (individualizedIndexes, renderBatchName, options) {
			var preview = new $.PageRender(new $.FlowPageSubSet($.composites, [this]), $.extend(true, {
				production: true,
				individualizedIndexes: individualizedIndexes,
				batchName: renderBatchName ? renderBatchName : this.classObj.name,
				includePageTitles: true,
				includeBatchIds: true,
				isDemo: $.plicLicenseFeatures['is-demo'],
				forceSide: 'Right',
				backgroundRender: true,
				includeBatches: true,
				showDownloadButton: true
			}, options));
			preview.showImageDialog();
		},
		getMaxMargins: function() {
			var max = 2.0;
			if(this.pageSet) {
				var layout = this.getLayout();
				if(layout && layout.grid && layout.grid.width && layout.grid.height) {
					var minSide = Math.min(layout.grid.width, layout.grid.height);
					if(!isNaN(minSide)) {
						max = Math.max(max, minSide / 5);
					}
				}
			}

			return max;
		},
		showSubjectLabelFontSizeMultiplier: function() {
			return true;
		},
		saveTemplate: function(layout) {
			let categories;
			$.SettingsBuilderDialog([
				{
					id: 'layoutName',
					type: 'text',
					description: 'Template Name',
					defaultValue: 'My Template'
				},
				{
					id: 'layoutCategory',
					type: 'dropdown',
					description: 'Template Category',
					defaultFirst: true,
					loadFunction: (chain, onComplete) => {
						this.getCustomerLayoutCategories(chain, (data) => {
							categories = data;
							onComplete(data);
						});
					}
				}
			], {
				title: 'Save Template',
				onSettingsApplied: function(settings, changes) {
					let category = categories.find(category => category.id == settings.layoutCategory);
					let layout = obj.createLayoutTemplate();
					obj.createStudioLayoutTemplate(category, layout, settings.layoutName);
				}
			});
		},

		createLayoutTemplate: function() {
			var layout = $.extend(true, {}, this.getLayout());
			layout.texts = $.extend(true, {}, this.getTexts());
			layout.images = $.extend(true, {}, this.getCandids());
			layout.theme = this.getTheme();
			layout.studentLabelCSS = this.studentLabelCSS.css;
			layout.studentMask = this.getStudentMask();

			var copyExtras = ['pageMargins', 'subjectEffects', 'backgroundSettings'];
			for(var i = 0; i < copyExtras.length; i++) {
				var name = copyExtras[i];

				var extraProperty = this.getExtraProperty(name);
				if(extraProperty) {
					if($.isPlainObject(extraProperty)) {
						layout[name] = $.extend(true, {}, extraProperty);
					} else {
						layout[name] = extraProperty;
					}
				} else {
					layout[name] = null;
				}
			}

			return layout;
		},

		createStudioLayoutTemplate: function(category, layout, layoutName) {
			$.ajax({
				url: 'ajax/createLayout.php',
				data: {
					categoryId: category.id,
					layoutName: layoutName,
					definition: JSON.stringify(layout),
					type: 'Templates'
				},
				type: 'POST',
				dataType: 'json',
				success: function(data) {
					// If already cached, add this layout to the cache
					$.layoutCategories.addItemToCategoriesCache(category, data.layout);

					// Force reload
					$.layoutCategories.categories.currentCategory = null;
					$.layoutCategories.slider.currentType = null;
					$.layoutCategories.setCategory(category);
				},
				error: function(jqXHR) {
					if(jqXHR.responseJSON && jqXHR.responseJSON.reason == 'Duplicate layout name already exists') {
						$.Alert('Error', 'A template with the name "' + layoutName + '" already exists.  Please choose a different name.');
					} else {
						$.Alert('Error', 'Failed to save layout template');
					}
				}
			});
		},
		getCustomerLayoutCategories: function(chain, onComplete) {
			var categories = $.layoutCategories.getCurrentCategories();
			var candidLayoutCategory = categories.getMatch({}, 'name', 'Templates');
			if(!candidLayoutCategory) {
				onComplete([categories[categories.length - 2]]);
				return;
			}

			let subCategories = [];
			for(var i = 0; i < candidLayoutCategory.subCategories.length; i++) {
				var subCategory = candidLayoutCategory.subCategories[i];

				if(subCategory.orgId == $.UserOrgId) {
					subCategories.push(subCategory);
				}
			}
			if(subCategories.length) {
				onComplete(subCategories);
				return;
			}

			// Otherwise we need to create it
			chain.add({
				url: 'ajax/createLayoutCategory.php',
				data: {
					orgId: $.UserOrgId,
					name: 'Studio Templates',
					returnDuplicate: true
				},
				type: 'POST',
				dataType: 'json',
				success: function(data) {
					let parentCategoryDiv = $($.layoutCategories).find('#layoutCategoryOptions').children(':contains("Templates")').children('.menu');

					let category = data.category;
					category.load = window.getTemplateCategoryLoad(category);

					$.layoutCategories.addCategoryAlphabetical(category, parentCategoryDiv);
					onComplete([category]);
				}
			});
		},
		doesContentOverlapSubjectLabels: function() {
			return $.getProjectSetting('contentOverlapsSubjectLabels', true);
		},

		isCommentsVisible: function() {
			return this.comments && $.getObjectCount(this.comments) > 0;
		},
		onCheckComment: function(comment) {
			// Do not change this to be a more itamized changed without changing onReplyComment as well!
			var renderData = {};
			renderData[this.compositeProofRenderId] = {
				comments: this.comments
			};

			$.proxyAjax({
				url: 'ajax/compositeProofs.php',
				data: {
					proofId: this.pageSet.compositeProofId,
					changes: JSON.stringify({
						renders: renderData
					})
				},
				type: 'POST',
				dataType: 'json',
				error: function() {
					$.Alert('Error', 'Failed to save proof comment changes');
				}
			});
		},
		onReplyComment: function(comment, reply) {
			this.onCheckComment(comment);
		},
		shouldShowGreenScreenOptions: function() {
			return true;
		},
		canEditSubjectDetails: function() {
			return true;
		},
		canHideCell: function() {
			return true;
		},
		getPageNumberDisplay: function() {
			if(this.classObj) {
				return 'page ' + this.classObj.name;
			} else {
				return 'page ' + (this.pageNumber - $.PAGE_OFFSET);
			}
		},

		type: 'composite',
		largeCellPosition: {
			col: 0,
			row: 0
		},
		// Not sure why I had this on before, but having it on breaks dropping a saved template with two lines for first/last name onto an existing page with one line for first last name
		keepNameOrder: false,
		keepGridSize: true
	});

	obj.updatePageLabel();
	return obj;
};