$.ProofRender = function(settings) {
	var obj = $.DataModel();

	$.extend(obj, {
		setApproved: function(approved) {
			this.setProperty('approved', approved);
			this.setProperty('reviewedAt', window.moment().toISOString());
			this.setProperty('reviewedBy', $.CurrentUser.name);
			this.setProperty('reviewedByEmail', $.CurrentUser.userName);
		},
		setNewPhoto: function(photo) {
			this.setProperty('photo', photo);
			this.onUpdateBatchImage();

			this.imageUrl = $.getPlicThumbnail(photo);
		},
		setPDFRender: function(newPdf, pdfPage, batchId, newBatchName) {
			this.setProperty('pDFId', newPdf.renderId);

			// If we are going to a single page render, update pageNumber as well
			if(!newPdf.pages || newPdf.pages <= this.pDFPage) {
				this.setProperty('pDFPage', 0);
			}

			if($.isInit(pdfPage)) {
				this.setProperty('pDFPage', pdfPage);
			}

			this.onUpdateBatchImage();
			if(batchId) {
				this.setProperty('batchId', batchId);
			}
			if(newBatchName) {
				this.setProperty('name', newBatchName);
			}

			this.imageUrl = 'ajax/getPdfImage.php?id=' + newPdf.remoteName + '&page=' + this.pDFPage;
		},
		setName(newName) {
			this.setProperty('name', newName);
		},

		onUpdateBatchImage() {
			if(this.approved === false) {
				this.setProperty('approved', null);
			}

			if($.getObjectCount(this.comments) > 0) {
				for(var id in this.comments) {
					var comment = this.comments[id];

					comment.old = true;
					if(comment.replies) {
						for(var replyId in comment.replies) {
							var reply = comment.replies[replyId];
							reply.old = true;
						}
					}
				}

				this.jsonReplace('comments', this.comments);
			}
		},

		changeScope: 'renders',
		comments: []
	}, settings);

	obj.id = obj.compositeProofRenderId || $.getUniqueId();

	return obj;
};