const STAFF_VALUE = 20;

export default function getGradeValue(value) {
	if(value?.toLowerCase) {
		value = value.toLowerCase();
	}

	if(value === 'k') {
		return 0;
	} else if(value === 'pk' || value === 'ps') {
		return -1;
	} else if(value === 'ee') {
		return -2;
	} else if(value === 'sta' || value === 'staff' || value === 'fac') {
		return STAFF_VALUE;
	} else if(!isNaN(value) && !isNaN(parseInt(value))) {
		return parseInt(value);
	} else {
		return false;
	}
}

export { STAFF_VALUE };