$.SubjectManagement.cacheSubjectFields = function(jobId, subjects, placeholderSubjects, template, job) {
	subjects = $.merge([], subjects);
	if(placeholderSubjects) {
		$.merge(subjects, placeholderSubjects);
	}
	var fieldIdMap = $.SubjectManagement.createFieldMap(template);

	var fieldValues = {};
	template.subject_fields.forEach(function(field) {
		if(!field.auto_suggest_enabled) {
			return;
		}

		var label = fieldIdMap[field.id];
		var fieldValue = {};
		subjects.forEach(function(subject) {
			var value = subject[label];
			if(!value) {
				return;
			}

			if(fieldValue[value]) {
				fieldValue[value]++;
			} else {
				fieldValue[value] = 1;
			}
		});

		if($.getObjectCount(fieldValue) > 0) {
			fieldValues[label] = fieldValue;
		}
	});

	if(job && fieldValues[job.groupBy || 'Teacher'] && job.batches) {
		var groupBy = job.groupBy || 'Teacher';
		job.batches.forEach(function(batch) {
			if(!fieldValues[groupBy][batch.name]) {
				fieldValues[groupBy][batch.name] = 0;
			}
		});
	}

	if($.getObjectCount(fieldValues) > 0) {
		$.ajax({
			url: 'ajax/cacheSubjectFields.php',
			dataType: 'json',
			data: {
				jobId: jobId,
				fieldValues: JSON.stringify(fieldValues)
			},
			type: 'POST'
			// User doesn't care what this responds with it is an async process
		});
	}
};