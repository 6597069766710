$.FlowLayoutTitle = function(wrapper, onChange) {
	

	var div = new $.FlowLayoutSVG({
		wrapper: wrapper,
		ratio: wrapper.ratio,
		extraOffset: wrapper.canvasBorderWidth,
		editable: wrapper.editable,
		defaultFontSize: 26,
		pageHeightRatio: 1,
		editTools: [
			'styles',
			'align',
			'font-family',
			'font-size',
			'text-colors',
			{
				title: 'Padding',
				dropdown: [0, 0.025, 0.05, 0.075, 0.1, 0.125, 0.15, 0.20, 0.25, 0.30, 0.40, 0.50].map(size => `${size}"`),
				allowAdditions: true,
				selection: 'instance',
				onChange: function(selection, change) {
					if(typeof change === 'string') {
						let parsedChange = parseFloat(change.replace('"', ''));
						if(isNaN(parsedChange) || parsedChange < 0 || parsedChange > 1) {
							return;
						}

						change = parsedChange;
					}

					div.addStyleToInstance('verticalPadding', change);
					div.refreshTitlePadding();
				},
				updateLabel: function(selection, dropdown, label) {
					let padding = selection.verticalPadding ?? 0.125;

					dropdown.setSelectedValue(padding);
					label.html('<span class="hideOnNarrowScreen">Padding: </span>' + padding + '"');
				}
			},
			{
				addClass: 'icon',
				group: ['stroke', 'drop shadow', 'opacity', 'curve']
			},
			{
				icon: 'edit',
				popup: 'Apply Styles To All Pages',
				singleSelection: true,
				onClick: function(selection) {
					var me = this;
					$.Confirm('Apply to All', 'Are you sure you want to apply this title style to all pages?', function() {
						var pageSet = me.wrapper.getPageSet();
						if(pageSet) {
							pageSet.copyTitleStylesToOthers(me.wrapper.getPage(), selection);

							if(me.wrapper.parent) {
								me.wrapper.parent.refreshTitles();
							}
						}
					});
				}
			},
			{
				icon: 'remove',
				popup: 'Remove title from page',
				color: 'red',
				singleSelection: true,
				onClick: function() {
					$.Confirm('Delete Title', 'Are you sure you want to delete the title off of this page?', function() {
						div.setTitle(null);
						if(onChange) {
							onChange.call(div, null, true);
						}
					});
				}
			}
		],
		getDynamicFields: function() {
			return wrapper.getDynamicFields();
		},
		getDynamicFieldRep: function(field) {
			if(field === 'Batch') {
				var page = wrapper.getPage();

				// We are in a mid-page class break
				if(this.extraBatch) {
					return this.extraBatch.name;
				}
				// We are starting an overflow from a previous merged batch
				else if(page && page.getParentPage && page.getParentPage()) {
					var parentPage = page.getParentPage();
					var parentLastClass = parentPage.getLastClass();
					if(parentLastClass) {
						return parentLastClass.name;
					}
				}
			}

			return wrapper.getDynamicFieldRep.apply(wrapper, arguments);
		},
		getEditToolsDistance: function() {
			return 10;
		},

		updateDefaultFontSize: function() {
			let pageHeight = wrapper.inchHeight;

			// Realistically it would be better to have this heigh be based off of the inside height without bleed, but that would be changing everyone's existing designs
			// With hideBleed: true, during design the inchHeight will be smaller than when rendered with the bleed
			// This is making the render match the design height so that it is consistent
			var pageSet = wrapper.getPageSet();
			if(pageSet && pageSet.layoutDimensions) {
				if(pageSet.layoutDimensions.hideBleed) {
					let heightHiddenDuringDesign = (wrapper.inchBleed.top + wrapper.inchBleed.bottom) - (wrapper.inchSafeSpace.top + wrapper.inchSafeSpace.bottom);
					pageHeight = pageHeight - heightHiddenDuringDesign;
				}

				pageHeight = pageHeight - (wrapper.inchWhiteSpace.top + wrapper.inchWhiteSpace.bottom);
			}
			this.pageHeightRatio = pageHeight / 11.25;

			this.defaultFontSize = Math.round(26 * this.pageHeightRatio);
		},

		// This is mostly to keep the status quo where we already weren't wrapping until the user goes in and explicitly changes something
		disablePostDynamicTextCheck: true
	});
	div.updateDefaultFontSize();

	var _addStyleToSelection = div.addStyleToSelection;

	$(div).addClass('flowTitle');
	$.extend(div, {
		setTitle: function(title) {
			if(title) {
				if(typeof title == 'string') {
					title = {
						lines: {
							text: title,
							align: 'center'
						}
					};

					var themeStyles = wrapper.getThemeTextStyles('Panel', 'title');
					if(themeStyles) {
						$.extend(title.lines, themeStyles);

						onChange.call(this, title);
					}
				} else if($.isInit(title.style) || $.isInit(title.text)) {
					this.updateStyleToLines(title);
				}

				$(this).show();
				this.refreshTitlePadding(title);
				this.setInstance(title);
			} else {
				$(this).hide();
				if(this.focused) {
					this.setFocused(false);
				}
			}
		},
		refreshTitlePadding: function(instance) {
			if(!instance) {
				instance = this.instance;
			}

			var titlePadding = this.ratio * (instance?.verticalPadding ?? 0.125) * this.pageHeightRatio;
			$(this).addClass('flowTitle').css({
				'padding-top': titlePadding,
				'padding-bottom': titlePadding
			});

			var maxWidth = wrapper.canvasMargins.getBoundingClientRect().width - 2;
			this.style['max-width'] = maxWidth + 'px';
		},
		updateStyleToLines: function(title) {
			if(title.style) {
				var lines = {
					text: title.text
				};
				$('<div>' + title.style + '</div>').recursiveEach(function() {
					switch(this.nodeName.toLowerCase()) {
						case 'strong':
							lines['font-weight'] = 'bold';
							break;
						case 'em':
							lines['italic'] =  'font-style';
							break;
						case 'u':
							lines['underline'] = 'text-decoration';
							break;
					}

					var checkStyles = ['color', 'fontFamily', 'font-size'];
					for(var i = 0; i < checkStyles.length; i++) {
						var style = checkStyles[i];
						if(this.style[style]) {
							lines[style] = this.style[style];
						}
					}
				});

				if(title.shadow) {
					lines['drop-shadow'] = title.shadow.color;
					delete title.shadow;
				}
				if(title.stroke) {
					lines.stroke = title.stroke.color;
					delete title.stroke;
				}

				title.lines = lines;
				delete title.text;
				delete title.style;
			} else if(title.text) {
				title.lines = title.text;
				delete title.text;
				title.schema = this.maxSchema;
			}
		},
		onChangeInstanceProperty: function(name, value) {
			if(onChange) {
				onChange.call(this, this.instance, true);
			}
		},
		addStyleToSelection: function(selection, name, value) {
			if(name === 'align') {
				if(!this.instance.useMaxWidth) {
					this.changeInstanceProperty('useMaxWidth', true);
				}
			}

			_addStyleToSelection.apply(this, arguments);
		},
		onSetFocused: function(focused) {
			if(!focused) {
				wrapper.onFlowChange(null, null, true);
			}
		},
		onPostRenderLines: function() {
			// For fonts we can, we want to not rely on rendered display height so we have a consistent title height and the rest of the class layout is the same between browsers/monitor resolutions
			if(this.hasFont('rock salt') || this.hasFont('homemade apple')) {
				// Fix calculations that rely on this be accurate not working
				this.calculatedRenderedHeight = this.renderedDisplayHeight;
				$(this).css('max-height', '');
			} else {
				let height = this.calculatedRenderedHeight;

				// This should mirror what we have in svg.js curve calculations
				if(this.instance.curve) {
					let a = this.renderedDisplayWidth / 2;
					let curveAngle = $.convertToRadians(this.instance.curve);
					let curveHeight = Math.tan(curveAngle) * a;

					height += Math.abs(curveHeight / 2);
				}

				$(this).css('max-height', height + 'px');
			}
		},
		wordWrapText: true,
		wordWrapTextBounds: wrapper.container
	});

	return div;
};