$.FlowLayoutSheetUtils = {
	getSheetLayout: function(page, options) {
		options = $.extend({
			editable: true,
			definition: page.layout
		}, options);

		var subjects = page.getSubjects() || [];
		var images = page.getCandids();
		var texts = page.getTexts();

		var lowestZIndex = -20;
		Object.values(images || {}).forEach(function(image) {
			if(image.zIndex < lowestZIndex) {
				lowestZIndex = image.zIndex - 1;
			}
		});
		Object.values(texts || {}).forEach(function(text) {
			if(text.zIndex < lowestZIndex) {
				lowestZIndex = text.zIndex - 1;
			}
		});

		var layoutGrid = this.sanitizeNumbersAsStrings(options.definition.grid);
		var sheet = this.sanitizeNumbersAsStrings(options.definition.sheet);
		let layoutWidth = layoutGrid.width;
		let layoutHeight = layoutGrid.height;
		var verticalSpacing = 0;
		var horizontalSpacing = 0;
		if(sheet.spacing) {
			layoutWidth += sheet.spacing.horizontal;
			layoutHeight += sheet.spacing.vertical;
		} else if(layoutGrid.bleed) {
			layoutWidth += layoutGrid.bleed.left + layoutGrid.bleed.right;
			layoutHeight += layoutGrid.bleed.top + layoutGrid.bleed.bottom;

			verticalSpacing = layoutGrid.bleed.top;
			horizontalSpacing = layoutGrid.bleed.left;
		}

		var i = 0;
		var grid = this.getSubjectGrid(options.definition);
		var layoutImages = {};
		var layoutTexts = {};
		for(var y = 0; y < grid.rows; y++) {
			for(var x = 0; x < grid.columns; x++) {
				// Only show blank spots for previews
				if(!options.editable) {
					var subject = subjects[i];
					if(!subject) {
						i++;
						continue;
					}
				}

				for(let id in images) {
					var image = $.extend(true, {}, images[id]);
					if(image.sheet) {
						continue;
					}

					image.id = image.id + '-' + i;
					image.x = image.x + x * layoutWidth + horizontalSpacing;
					image.y = image.y + y * layoutHeight + verticalSpacing;
					image.subjectIndex = i;

					layoutImages[image.id] = image;
				}

				if(page.layoutTheme && page.layoutTheme.Background) {
					var layoutBackground = {
						id: 'background-' + i,
						photo: page.layoutTheme.Background.id,
						existingUrl: page.layoutTheme.Background.cdnUrl,
						x: x * layoutWidth + horizontalSpacing,
						y: y * layoutHeight + verticalSpacing,
						width: layoutWidth,
						height: layoutHeight,
						zIndex: lowestZIndex
					};
					if(sheet.spacing) {
						layoutBackground.width -= sheet.spacing.horizontal;
						layoutBackground.height -= sheet.spacing.vertical;
					}
					Object.keys(page.extras?.layoutBackgroundSettings ?? {}).forEach(key => {
						if(key === 'crop') {
							layoutBackground.crop = $.extend(true, {
								percentage: true
							}, page.extras.layoutBackgroundSettings.crop);
						} else {
							layoutBackground[key] = page.extras.layoutBackgroundSettings[key];
						}
					});
					layoutImages[layoutBackground.id] = layoutBackground;
				}
				
				for(let id in texts) {
					var text = $.extend(true, {}, texts[id]);
					if(!text.position || text.sheet) {
						continue;
					}

					text.id = text.id + '-' + i;
					text.position.left = text.position.left + x * layoutWidth + horizontalSpacing;
					text.position.top = text.position.top + y * layoutHeight + verticalSpacing;
					text.subjectIndex = i;

					layoutTexts[text.id] = text;
				}

				i++;
			}
		}

		return {
			grid: options.definition.sheet,
			images: layoutImages,
			texts: layoutTexts
		}
	},
	getSheetSubjects: function(subjects, options) {
		var duplicateSubjects = options.definition.sheet.duplicateSubjects || 1;
		if(duplicateSubjects > 1) {
			subjects = subjects.reduce(function(array, subject) {
				for(var i = 0; i < duplicateSubjects; i++) {
					array.push(subject);
				}

				return array;
			}, []);
		}
		if(options.definition.sheet.onePagePerPose === true || options.definition.sheet.onePagePerPose === 'true') {
			subjects = subjects.reduce((array, subject, index) => {
				if(subject.photos) {
					let validPoses = subject.photos.filter(photo => {
						return photo.category === 'individual_category' && !photo.hidden;
					});
					if(options.definition.sheet.maxPosesPerSubject && !isNaN(parseInt(options.definition.sheet.maxPosesPerSubject))) {
						let max = parseInt(options.definition.sheet.maxPosesPerSubject);
						if(validPoses.length > max) {
							validPoses.length = max;
						}
					}
					validPoses.forEach(pose => {
						let newSubject = { ...subject };
						newSubject.yearbookPhoto = pose;
						delete newSubject.photoCdnUrl;
						newSubject.yearbookCrop = newSubject.yearbookPhoto.yearbookCrop = pose.photo_crops?.[0];

						array.push(newSubject);
					});
				} else {
					// If we don't have any photos we default to setting that controls whether we render subjects without photos
					array.push(subject);
				}

				return array;
			}, []);
		}

		if(options.definition.sheet.onePagePerOrder === true || options.definition.sheet.onePagePerOrder === 'true') {
			subjects = subjects.reduce(function(array, subject) {
				if(subject.orders && subject.orders.length > 1) {
					subject.orders.forEach(order => {
						let newSubject = { ...subject };
						newSubject.orders = [order];

						array.push(newSubject);
					});
				} else {
					array.push(subject);
				}

				return array;
			}, []);
		}

		return subjects;
	},
	getSheetTexts: function(page) {
		var texts = page.getTexts();
		var layoutTexts = {};
		
		for(let id in texts) {
			var text = $.extend(true, {}, texts[id]);
			if(text.sheet) {
				layoutTexts[text.id] = text;
			}
		}

		return layoutTexts;
	},
	getSheetImages: function(page) {
		var images = page.getCandids();
		var layoutImages = {};
		
		for(let id in images) {
			var image = $.extend(true, {}, images[id]);
			if(image.sheet) {
				layoutImages[image.id] = image;
			}
		}

		return layoutImages;
	},
	getSubjectGrid: function(definition) {
		var sheetGrid = this.sanitizeNumbersAsStrings(definition.sheet);
		var sheetWidth = sheetGrid.width - sheetGrid.bleed.left - sheetGrid.bleed.right;
		var sheetHeight = sheetGrid.height - sheetGrid.bleed.top - sheetGrid.bleed.bottom;

		var layoutGrid = this.sanitizeNumbersAsStrings(definition.grid);
		let layoutWidth = layoutGrid.width || 1;
		let layoutHeight = layoutGrid.height || 1;
		if(sheetGrid.spacing) {
			layoutWidth += sheetGrid.spacing.horizontal;
			layoutHeight += sheetGrid.spacing.vertical;

			// Also need to add to overall sheet dimensions since the spacing is not on the last cell
			sheetWidth += sheetGrid.spacing.horizontal + 0.001;
			sheetHeight += sheetGrid.spacing.vertical + 0.001;
		} else if(layoutGrid.bleed) {
			layoutWidth += layoutGrid.bleed.left + layoutGrid.bleed.right;
			layoutHeight += layoutGrid.bleed.top + layoutGrid.bleed.bottom;
		}

		let columns = Math.floor(sheetWidth / layoutWidth);
		let rows = Math.floor(sheetHeight / layoutHeight);

		return {
			columns: columns,
			rows: rows
		};
	},

	getSheetDefinitionSettings: function(layout) {
		layout = $.extend(true, {}, layout);

		return [
			{
				id: 'layoutName',
				description: 'Sheet Name',
				type: 'text',
				defaultValue: layout.layoutName || ''
			},
			{
				name: 'sheet',
				group: 'sheet',
				subGroup: 'sheet',
				type: 'section',
				description: 'Sheet Dimensions (in)',
				value: {},
				settings: [
					{
						id: 'width',
						description: 'Width',
						type: 'positiveFloat',
						range:[1, 100]
					},
					{
						id: 'height',
						description: 'Height',
						type: 'positiveFloat',
						range:[1, 100]
					}
				]
			},
			{
				name: 'sheetMargin',
				group: 'sheetMargin',
				subGroup: 'sheetMargin',
				type: 'section',
				value: {},
				perLine: 4,
				settings: [
					{
						id: 'left',
						description: 'Left Margin',
						type: 'positiveFloat',
						range:[0, 100],
						defaultValue: 0.0
					},
					{
						id: 'right',
						description: 'Right Margin',
						type: 'positiveFloat',
						range:[0, 100],
						defaultValue: 0.0
					},
					{
						id: 'top',
						description: 'Top Margin',
						type: 'positiveFloat',
						range:[0, 100],
						defaultValue: 0.0
					},
					{
						id: 'bottom',
						description: 'Bottom Margin',
						type: 'positiveFloat',
						range:[0, 100],
						defaultValue: 0.0
					}
				]
			},
			{
				name: 'layout',
				group: 'layout',
				subGroup: 'layout',
				type: 'section',
				description: 'Layout Dimensions (in)',
				value: {},
				settings: [
					{
						id: 'columns',
						description: '%value% columns',
						maxDisplay: '10',
						type: 'inc/dec',
						range: [1, 10],
						defaultValue: 4
					},
					{
						id: 'rows',
						description: '%value% rows',
						maxDisplay: '100',
						type: 'inc/dec',
						range: [1, 100],
						defaultValue: 4
					},
					{
						id: 'width',
						description: 'Width',
						type: 'positiveFloat',
						range:[0.1, 100],
						value: layout.definition ? layout.definition.grid.width : null
					},
					{
						id: 'height',
						description: 'Height',
						type: 'positiveFloat',
						range:[0.1, 100],
						value: layout.definition ? layout.definition.grid.height : null
					}
				]
			},
			{
				name: 'layoutSpacing',
				group: 'layoutSpacing',
				subGroup: 'layoutSpacing',
				type: 'section',
				value: {},
				settings: [
					{
						id: 'horizontal',
						description: 'Horizontal Spacing',
						type: 'positiveFloat',
						range:[0, 100],
						defaultValue: 0.0
					},
					{
						id: 'vertical',
						description: 'Vertical Spacing',
						type: 'positiveFloat',
						range:[0, 100],
						defaultValue: 0.0
					}
				]
			},
			{
				name: 'grouping',
				description: 'Subjects',
				type: 'section',
				settings: [
					{
						id: 'groupBy',
						description: 'Group By',
						type: 'dropdown',
						options: ['Project', 'Subject', 'First Name', 'Last Name', 'Teacher', 'Grade', 'Home Room', 'Student ID'],
						defaultValue: 'Project',
						multiple: true,
						saveAll: true
					},
					{
						id: 'sortBy',
						description: 'Sort By',
						type: 'dropdown',
						options: ['First Name', 'Last Name', 'Teacher', 'Grade', 'Home Room', 'Student ID', 'Primary Pose Name'],
						defaultValue: 'Last Name,First Name',
						multiple: true,
						saveAll: true
					},
					{
						id: 'duplicateSubjects',
						description: 'Duplicate each subject %value% times',
						type: 'inc/dec',
						range: [1, 100],
						minDisplay: '1',
						maxDisplay: '100',
						defaultValue: 1
					},
					{
						id: 'onePagePerPose',
						description: 'Render each subject once for each pose',
						type: 'checkbox',
						defaultValue: false
					},
					{
						id: 'maxPosesPerSubject',
						description: 'Maximum number of poses for each subject',
						help: 'Leave blank if you want to render each pose for each subject no matter how many there are',
						type: 'positiveInt',
						defaultValue: '',
						customRule: ''
					},
					{
						id: 'sortOrder',
						description: 'Sort Order',
						type: 'dropdown',
						options: [
							'Standard',
							'Reverse Sort',
							'Standard Column Sort',
							'Column Sort',
							'Reverse Column Sort',
							'Stack Sort',
							'Column Stack Sort'
						],
						defaultValue: 'Standard'
					},
					{
						id: 'includeSubjectsWithoutPhotos',
						description: 'Include subjects without photos',
						type: 'checkbox',
						defaultValue: true
					}
				]
			}
		];
	},
	updateSheetLayoutDimensionsFromChange: function(change) {
		if(change.group != 'layout' || change.id == 'columns' || change.id == 'rows') {
			$.FlowLayoutSheetUtils.updateSheetLayoutDimensionsAfterChange(this);
		}
	},
	updateSheetLayoutDimensionsAfterChange: function(form, options) {
		options = $.extend({
			updateColumnsRows: false
		}, options);

		let columns = form.currentSettings.layout.columns || 4;
		let rows = form.currentSettings.layout.rows || 4;

		var sheetWidth = form.currentSettings.sheet.width;
		var sheetHeight = form.currentSettings.sheet.height;
		if(!sheetWidth || !sheetHeight) {
			return;
		}

		sheetWidth -= (parseFloat(form.currentSettings.sheetMargin.left) || 0) + (parseFloat(form.currentSettings.sheetMargin.right) || 0);
		sheetHeight -= (parseFloat(form.currentSettings.sheetMargin.top) || 0) + (parseFloat(form.currentSettings.sheetMargin.bottom) || 0);

		var layoutVerticalSpacing = form.currentSettings.layoutSpacing.vertical || 0;
		var layoutHorizontalSpacing = form.currentSettings.layoutSpacing.horizontal || 0;
		var totalLayoutVerticalSpacing = layoutVerticalSpacing * (rows - 1);
		var totalLayoutHorizontalSpacing = layoutHorizontalSpacing * (columns - 1);

		if(options.updateColumnsRows) {
			let layoutWidth = parseFloat(form.currentSettings.layout.width);
			let layoutHeight = parseFloat(form.currentSettings.layout.height);

			columns = sheetWidth / layoutWidth;
			rows = sheetHeight / layoutHeight;
			var intColumns = Math.max(1, Math.floor(columns));
			var intRows = Math.max(1, Math.floor(rows));

			var totalExtraWidth = (columns - intColumns) * layoutWidth;
			var totalExtraHeight = (rows - intRows) * layoutHeight;
			
			layoutHorizontalSpacing = $.toFixedFloored(totalExtraWidth / (intColumns - 1), 4);
			layoutVerticalSpacing = $.toFixedFloored(totalExtraHeight / (intRows - 1), 4);

			form.ignoreNextSettingChange = true;
			form.setSettingByName('columns', intColumns, 'layout');
			form.setSettingByName('rows', intRows, 'layout');
			form.setSettingByName('horizontal', layoutHorizontalSpacing, 'layoutSpacing');
			form.setSettingByName('vertical', layoutVerticalSpacing, 'layoutSpacing');
			form.ignoreNextSettingChange = false;
		} else {
			let layoutWidth = $.toFixedFloored((sheetWidth - totalLayoutHorizontalSpacing) / columns, 4);
			let layoutHeight = $.toFixedFloored((sheetHeight - totalLayoutVerticalSpacing) / rows, 4);
			
			form.setSettingByName('width', layoutWidth, 'layout');
			form.setSettingByName('height', layoutHeight, 'layout');
		}
	},
	sanitizeNumbersAsStrings: function(data) {
		data = $.extend(true, {}, data);
	
		for(let id in data) {
			var value = data[id];
			if($.isPlainObject(value)) {
				data[id] = this.sanitizeNumbersAsStrings(value);
			} else if(typeof value == 'string' && !isNaN(value)) {
				value = parseFloat(value);
	
				// To prevent things like parseFloat(true) because true is a number apparently
				if(!isNaN(value)) {
					data[id] = value;
				}
			}
		}
	
		return data;
	}
};