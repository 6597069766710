$.managementTabTutorials = {
	'Snapshot.html': {
		id: 'tourDashboard',
		steps: [
			{
				target: '#totalBooksMessage',
				title: 'Welcome to your PLIC Elements Dashboard',
				content: 'Howdy! Welcome to your Dashboard. This page will help you get a quick view of your most important statistics so you don’t have to go Menu-Hopping. <b>Clicking</b> on the Stat will <b>Filter</b> the results for you in the <b>Schools</b> page.',
				placement: 'right',
				xOffset: 'center',
				yOffset: 0
			},
			{
				target: '#totalBooksMessage > .statistic',
				title: 'Total number of Projects',
				content: 'This Statistic shows how many School Projects you have in the system, regardless of where they are in the process.',
				placement: 'right'
			},
			{
				target: '#progressBooksMessage > .statistic',
				title: 'Elements in Progress',
				content: 'This Statistic shows how many School Projects that are being actively worked-on. Keep tabs on them. Crack the whip!',
				placement: 'left'
			},
			{
				target: '#submittedBooksMessage > .statistic',
				title: 'Elements submitted successfully',
				content: 'This Statistic shows you how many Elements were completed and submitted for rendering. You also have emails that will back this stat up :).',
				placement: 'left'
			}			
		],
		showPrevButton: true,
		scrollTopMargin: 100
	},
	'Schools.html#loaded': {
		id: 'tourDashboard',
		steps: [
			{
				target: '#organizationTable tr:first',
				title: 'Schools',
				content: 'Here is where you manage your all of your Schools and their project settings.',
				placement: 'top',
				xOffset: 'center',
				arrowOffset: 'center'
			}
		],
		showPrevButton: true,
		scrollTopMargin: 100
	},
	'SubjectManagement.html#loaded': {
		id: 'tourDashboard',
		steps: [
			{
				target: '.subjectManagementBatch',
				title: 'Subject Management',
				content: 'This is where all the data magic happens!<br>Search, correct spellings, move subjects to different classes, add staff options and more...',
				placement: 'top',
				xOffset: 'center',
				arrowOffset: 'center'
			},
			{
				target: '#subjectBatchesSlider',
				title: 'Batches or (Classes)',
				content: 'Your teacher classes or grade classes are listed here. The label shows the total in the batch. You can rename, add [+] or delete batches',
				placement: 'top',
				xOffset: 'center',
				arrowOffset: 'center'
			},
			{
				target: '#subjectManager .subjectManagementBatch .subjectCard:first',
				title: 'Subject Cards',
				content: 'To move or copy subjects from one batch to another, drag their image to the desired batch title.<br><b>Note:</b>  To move or copy a group of subjects, simply hold down the CTRL key and select each student you wish to move before dragging.',
				placement: 'right'
			},
			{
				target: '#subjectManager .subjectManagementBatch .subjectCard:first .startButton:last',
				title: 'Edit Subjects and Options',
				content: 'You can change name spellings or change the batch using the [Edit] button.<br>Assign a subject as Staff by using the [Options] button.',
				placement: 'right'
			},
			{
				target: '#subjectManager input[type="search"]',
				title: 'Search!',
				content: 'Find that kid! Use search to filter the one(s) you need to find from all batches.',
				placement: 'left'
			},
			{
				target: '#subjectManager .subjectManagementBatch .subjectAddCard',
				title: 'Add Subjects',
				content: 'You can add a subject and their portrait easily. They are added to the current batch',
				placement: 'top'
			}
		],
		showPrevButton: true,
		scrollTopMargin: 100
	},
	'Seasons.html#loaded': {
		id: 'tourDashboard',
		steps: [
			{
				target: '#seasonsTable_wrapper',
				title: 'Seasons Management',
				content: 'Here you can manage your seasons.  Seasons are a great way to manage your projects between different school years.',
				placement: 'bottom'
			},
			{
				target: 'seasonsTable_length',
				title: 'Create Season',
				content: 'We recommend you create a season for this current school season before you begin.  If you have old projects from the previous season, you should also create a season for them as well.',
				placement: 'bottom'
			},
			{
				target: '#seasonsTable_wrapper .bulkAddProjectsButton:first',
				backupTarget: '#seasonsTable_wrapper',
				title: 'Edit which projects are in a season',
				content: 'After you have created your first season, you can edit the season a project belongs to from Project Settings.  You can also bulk import projects into a season with the blue transfer button.',
				placement: 'bottom'
			},
			{
				target: '#seasonsTable_wrapper .filterSeasonButton:first',
				backupTarget: '#seasonsTable_wrapper',
				title: 'View old seasons',
				content: 'After you move to a new season, you may want to go back and view the projects from an old season.  You may do this by clicking on the gray filter button.',
				placement: 'bottom'
			}
		]
	}
};
$.runManagementTabTutorial = function(path) {
	var tutorial = $.managementTabTutorials[path];
	if(tutorial) {
		$.runTutorial(path, tutorial);

		// Add a help button regardless of whether this was run or not
		var index = path.indexOf('#')
		if(index != -1) {
			path = path.substr(0, index);
		}
		var tab = $('#tabs > div[data-tab="' + path + '"]');
		var header = tab.children('h2.header').first();
		if(header.length && !header.hasClass('left')) {
			header.addClass('left floated');

			var help = $('<h2 class="ui right floated header" style="cursor: pointer"><i class="help circle green icon"></i></h2>').click(function() {
				$.runTutorial(path, tutorial, true);
			}).insertAfter(header);
			help.next().css('clear', 'both');
		}
	}
};
$.runTutorial = function(name, tutorial, manualRun) {
	if(tutorial) {
		// Already ran once
		if($.userExtras['tutorials'] && $.userExtras['tutorials'][name] && !$.userExtras['debugTutorials'] && !manualRun) {
			return;
		}

		window.setTimeout(function () {
			hopscotch.startTour(tutorial, 0);

			if(!manualRun) {
				$.ajax({
					url: 'ajax/tutorialRead.php',
					dataType: 'json',
					data: {
						tutorial: name
					},
					type: 'POST'
				});
			}
		}, 10);
	}
};

$.composerTutorial = {
	id: 'tourDashboard',
	steps: [
		{
			target: '#compositePage',
			title: 'Composer',
			content: 'Welcome to the composer.  Here you will be able to create your elements.',
			placement: 'right',
			xOffset: 'center',
			yOffset: 'center'
		},
		{
			target: '#layoutCategoryWrapper',
			title: 'Element Layouts',
			content: 'Change the look of your batches by dragging a Layout onto your page.',
			placement: 'right'
		},
		{
			target: '#classesWrapper',
			title: 'Batches',
			content: 'Click to change which Element you are viewing.',
			placement: 'right',
			xOffset: 'center',
			yOffset: 0
		},
		{
			target: '#candidsCategoryWrapper',
			title: 'Candids',
			content: 'Drag your graphics or candids onto pages.  Afterwords you can move, resize, and add effects to them.',
			placement: 'left'
		}
	],
	showPrevButton: true,
	scrollTopMargin: 100
};