$.FlowLayoutContainer = function(options) {
	var div = document.createElement('div');
	div.className = 'flowPageContainer';

	$.extend(div, {
		setRatio: function(ratio) {
			this.ratio = ratio;

			if(this.bleedMask) {
				this.bleedMask.setRatio(this.ratio);
			}
			if(this.cropMarks) {
				this.cropMarks.setRatio(this.ratio);
			}
		},
		setEditable: function(editable) {
			this.editable = editable;

			if(editable) {
				var pageSet = this.flowLayout.getPageSet();

				if(!this.bleedMask && pageSet && pageSet.showBleedMask()) {
					this.flowLayout.appendChild(this.bleedMask = new $.FlowLayoutBleedMask({
						flowLayout: this.flowLayout,
						bleed: this.bleed
					}));
				}

				if(!this.mouseDragWrapper) {
					this.mouseDragWrapper = $('#content')[0] ?? this.flowLayout.parent?.wrapper ?? this;
					if(this.mouseDragWrapper instanceof $) {
						this.mouseDragWrapper = this.mouseDragWrapper[0];
					}
				}
				if(!this.mouseDownHandler) {
					this.mouseDragWrapper.addEventListener('mousedown', this.mouseDownHandler = function(event) {
						if(div.startMouseDrag(event)) {
							event.preventDefault();
						}
					});
				}
			} else {
				if(this.bleedMask) {
					this.bleedMask.destroy();
					this.bleedMask = null;
				}

				if(this.mouseDownHandler) {
					this.mouseDragWrapper.removeEventListener('mousedown', this.mouseDownHandler);
					this.mouseDownHandler = null;
				}
			}
		},
		setBleed: function(bleed) {
			var visibleBleed = {
				left: 0,
				right: 0,
				top: 0,
				bottom: 0
			};
			var actualBleed = $.extend({}, visibleBleed);

			var safeSpace = this.flowLayout.inchSafeSpace;
			for(var side in bleed) {
				var margin = bleed[side] * this.ratio - this.flowLayout.canvasBorderWidth;
				if(margin < 0) {
					margin = 0;
				}

				visibleBleed[side] = margin;
				actualBleed[side] = margin - (safeSpace[side] * this.ratio);
			}

			$(this).css({
				width: $(this.flowLayout).getFloatStyle('width') - visibleBleed.left - visibleBleed.right,
				height: $(this.flowLayout).getFloatStyle('height') - visibleBleed.top - visibleBleed.bottom,
				marginLeft: visibleBleed.left,
				marginRight: visibleBleed.right,
				marginTop: visibleBleed.top,
				marginBottom: visibleBleed.bottom
			});

			if(this.bleedMask) {
				this.bleedMask.setBleed(actualBleed, visibleBleed);
			}
			if(this.cropMarks) {
				this.cropMarks.setBleed(actualBleed);
			}
		},
		setDisplayCropMarks: function(displayCropMarks) {
			this.displayCropMarks = displayCropMarks;

			if(displayCropMarks) {
				this.cropMarks = new $.FlowLayoutCropMarks({
					flowLayout: this.flowLayout
				});

				this.flowLayout.appendChild(this.cropMarks);
			} else if(this.cropMarks) {
				this.cropMarks.destroy();
			}
		},

		startMouseDrag: function(event) {
			if(event.target !== this && event.target !== this.mouseDragWrapper && event.target !== this.flowLayout.parent?.wrapper && event.target !== this.flowLayout.parent?.wrapper?.parentNode && event.target.id !== 'pageButtonsWrapper') {
				return false;
			}
			// Currently only support selecting stuff on one page at a time
			if(event.target !== this && this.mouseDragWrapper !== this && this.flowLayout.parent?.pages.length > 1) {
				let pageRect = this.flowLayout.getBoundingClientRect();
				if(this.flowLayout.side === 'Right') {
					if(event.clientX < pageRect.left) {
						return false;
					}
				} else if(this.flowLayout.side === 'Left') {
					if(event.clientX > pageRect.right) {
						return false;
					}
				}
			}

			if(!this.mouseMoveHandler) {
				document.addEventListener('mousemove', this.mouseMoveHandler = function(event) {
					div.mouseDrag(event);
				});
			}

			if(!this.mouseStopHandler) {
				document.addEventListener('mouseup', this.mouseStopHandler = function(event) {
					div.stopMouseDrag(event);
				});
			}

			this.startDragX = event.clientX;
			this.startDragY = event.clientY;

			return true;
		},
		mouseDrag: function(event) {
			var offset = $(this.mouseDragWrapper).offset();
			var x = Math.min(this.startDragX, event.clientX) - offset.left;
			var y = Math.min(this.startDragY, event.clientY) - offset.top;
			var width = Math.abs(this.startDragX - event.clientX);
			var height = Math.abs(this.startDragY - event.clientY);

			if(width < this.minDragSize && height < this.minDragSize) {
				return;
			}

			if(!this.mouseDragRect) {
				this.mouseDragRect = document.createElement('div');
				this.mouseDragRect.className = 'mouseDragRect';
				this.mouseDragWrapper.appendChild(this.mouseDragRect);
			}

			$(this.mouseDragRect).css({
				left: x,
				top: y,
				width: width,
				height: height
			});
		},
		stopMouseDrag: function(event) {
			document.removeEventListener('mousemove', this.mouseMoveHandler);
			document.removeEventListener('mouseup', this.mouseStopHandler);
			this.mouseMoveHandler = null;
			this.mouseStopHandler = null;

			if(this.mouseDragRect) {
				let movableContent = this.getMultiSelectableContent(this.flowLayout);
				// Be able to select stuff one both sides of a spread
				if(this.flowLayout.nextLayout) {
					movableContent.push(...this.getOtherPageMultiSelectableContent(this.flowLayout.nextLayout, movableContent));
				} else if(this.flowLayout.previousLayout) {
					movableContent.push(...this.getOtherPageMultiSelectableContent(this.flowLayout.previousLayout, movableContent));
				}

				let overlaps = $(this.mouseDragRect).overlaps(movableContent).toArray();
				$(this.mouseDragRect).remove();
				this.mouseDragRect = null;

				movableContent.each(function() {
					if(this.focused && overlaps[0] !== this) {
						this.setFocused(false);
					}
				});

				if(overlaps.length) {
					// Default the primary selection to whichever side we started dragging from
					let thisLayoutOverlap = overlaps.find(overlap => overlap.wrapper === this.flowLayout);
					let primaryOverlap = thisLayoutOverlap || overlaps[0];
				
					// Using click logic so dynamic text is focused the same way
					$(primaryOverlap).click();
					for(var i = 0; i < overlaps.length; i++) {
						let overlap = overlaps[i];
						if(overlap === primaryOverlap) {
							continue;
						}

						overlap.setSecondaryFocused(true, {
							updateEditTools: false
						});
					}

					// Need this so toolbar position is above everything in the group
					if(overlaps.length > 1) {
						primaryOverlap.updateEditTools();
						primaryOverlap.forceUpdateToolbarPosition();
					}
					// Takes a second to run above code, this helps make sure the subsequent click event is within 100ms
					primaryOverlap.focusedAt = new Date().getTime();
				}
			}
		},
		getMultiSelectableContent: function(flowLayout) {
			return flowLayout.getMovableContent().filter(function() {
				return this.multipleSelect && (!this.instance || !this.instance.locked);
			});
		},
		getOtherPageMultiSelectableContent: function(flowLayout, mainPageContents) {
			return this.getMultiSelectableContent(flowLayout).filter(function() {
				let content = this;
				return mainPageContents.filter(function() {
					return content.instance?.id === this.instance?.id;
				}).length === 0;
			});
		},

		destroy: function() {
			if(this.bleedMask) {
				this.bleedMask.destroy();
			}

			if(this.mouseDownHandler) {
				this.mouseDragWrapper.removeEventListener('mousedown', this.mouseDownHandler);
				this.mouseDownHandler = null;
			}
		},

		editable: false,
		displayCropMarks: false,
		minDragSize: 20
	}, options);

	if(div.flowLayout.displayCropMarks) {
		div.setDisplayCropMarks(true);
	}

	return div;
};