$.LayoutPreview = function(settings) {
	var obj = new Object;

	$.extend(obj, {
		setLayout: function (layout) {
			if (layout) {
				this.id = layout.id;
				this.title = layout.title;
				if (typeof layout.definition == 'string') {
					this.definition = JSON.parse(layout.definition);
				} else {
					this.definition = layout.definition;
				}
				this.organizationId = layout.organizationId;
				if (this.definitionBox) {
					this.definitionBox.val($.prettyFormatJSON(this.definition));
				}
				this.order = layout.order;
				if (this.orderBox) {
					this.orderBox.val(this.order ? this.order : 0);
				}
			} else {
				this.id = null;
				this.title = null;
				this.definition = null;
				this.organizationId = null;
				if (this.definitionBox) {
					this.definitionBox.val('');
				}
				if (this.orderBox) {
					this.orderBox.val('');
				}
			}

			if (this.previewBox) {
				this.updatePreview();
			}
			if (this.onLayoutChange) {
				this.onLayoutChange(layout);
			}
		},
		setSubject: function(subject) {
			this.subject = $.extend(true, {}, subject);

			if (this.previewBox) {
				this.updatePreview();
			}
		},

		setDefinition: function (definition) {
			this.definition = definition;
			if (this.definitionBox) {
				this.definitionBox.val($.prettyFormatJSON(this.definition));
			}
			if (this.previewBox) {
				this.updatePreview();
			}
		},
		updateDefinition: function (text) {
			try {
				var definition = JSON.parse(text);
				this.definition = definition;
				if (this.previewBox) {
					this.updatePreview();
				}
			} catch (e) {
				// Not valid JSON, don't update
			}
		},

		updatePreview: function () {
			try {
				this.previewBox.empty();

				// Add image preview frames
				if (this.definition) {
					this.bleed = {
						left: 0,
						right: 0,
						top: 0,
						bottom: 0
					};

					var grid = this.definition.previewGrid || this.definition.grid;
					if (grid && grid.width) {
						grid = this.sanitizeNumbersAsStrings(grid);
						
						var width = grid.width;
						var height = grid.height;
						if(grid.bleed && $.isPlainObject(grid.bleed)) {
							$.extend(this.bleed, grid.bleed);

							if(grid.bleedOutsideDimensions) {
								width += this.bleed.left + this.bleed.right;
								height += this.bleed.top + this.bleed.bottom;
							}
						}

						this.updatePreviewPageDimensions({
							width: width,
							height: height
						});
					} else {
						this.updatePreviewPageDimensions({
							width: this.defaultGridWidth,
							height: this.defaultGridHeight
						});
					}

					var textRatio = this.ratio / 70;
					if (this.definition.cell) {
						this.addCells(textRatio);
					}

					this.addBackground();
					this.addFrames();
					this.addTexts(textRatio);
					// Only add title block if not adding one in addCells already
					if(this.definition.title && !this.definition.cell) {
						var title = this.definition.title;
						if(!$.isPlainObject(title)) {
							title = {
								text: title
							};
						}

						this.addText({
							lines: title,
							fontSize: 48,
							position: {
								left: 'center',
								top: 0.5
							}
						}, textRatio);
					}

					if(this.displayGrid && this.definition.grid) {
						this.addGrid(this.definition.grid);
					}
				}

				this.previewBox[0].layout = this.definition;
			} catch(e) {
				if($.fireErrorReport) {
					$.fireErrorReport('Failed to load preview', 'Failed to load preview', 'Failed to load preview for layout ' + this.id, {
						exception: e,
						layout: this.getDefinition()
					});
				} else {
					console.error('Failed to load preview', e);
				}
			}
		},
		sanitizeNumbersAsStrings: function(data) {
			data = $.extend(true, {}, data);
		
			for(var id in data) {
				var value = data[id];
				if($.isPlainObject(value)) {
					data[id] = this.sanitizeNumbersAsStrings(value);
				} else if(typeof value == 'string' && !isNaN(value)) {
					value = parseFloat(value);
		
					// To prevent things like parseFloat(true) because true is a number apparently
					if(!isNaN(value)) {
						data[id] = value;
					}
				}
			}
		
			return data;
		},
		addCells: function(textRatio) {
			var topPadding = 0.5 * this.ratio;
			let sideBleed = ((this.definition.previewSidePadding !== undefined && this.definition.previewSidePadding !== null) ? this.definition.previewSidePadding : 0.5) * this.ratio;

			var startY = 0;
			if (this.definition.cell.size != 'fit' && this.definition.title !== null) {
				let titleDiv;
				let titleText = this.definition.titlePlaceholder || 'Teachers Name';
				if(this.definition.title) {
					titleDiv = this.addText({
						lines: this.definition.title
					}, textRatio);
					titleDiv.find('div').first().text(titleText);
				} else {
					titleDiv = $('<div class="textPlaceholder">');
					titleDiv.html(titleText);
					titleDiv.css({
						fontSize: (20 * textRatio) + 'pt'
					});
					this.previewBox.append(titleDiv);
				}

				titleDiv.css({
					left: 0,
					top: 0.25 * this.ratio,
					width: '100%',
				});
				startY = 0.6 * this.ratio;
			}

			var cellDefinition = $.extend(true, {
				padding: 0.05
			}, this.definition.cell);

			var defaultSize = 'Small';
			if(this.definition.cell.previewDefault) {
				defaultSize = this.definition.cell.previewDefault;
			} else if(this.definition.cell.default) {
				defaultSize = this.definition.cell.default;
			}

			if(this.definition.cells && this.definition.cells[defaultSize]) {
				cellDefinition = $.extend(true, {}, cellDefinition, this.definition.cells[defaultSize]);
			}

			var columns = 6;
			if(cellDefinition.columns) {
				columns = cellDefinition.columns;
			}

			// If we have 0 padding we want a slight gap to see that there are different cells
			var cellPadding = (cellDefinition.padding || 0.02) * this.ratio;
			var leftCellPadding = cellPadding;
			if(cellDefinition.leftPadding) {
				leftCellPadding = cellDefinition.leftPadding * this.ratio;
			}
			var rightCellPadding = cellPadding;
			if(cellDefinition.rightPadding) {
				rightCellPadding = cellDefinition.rightPadding * this.ratio;
			}

			var totalCellPaddingWidth = leftCellPadding + rightCellPadding;
			if(cellDefinition.outsidePadding) {
				totalCellPaddingWidth = totalCellPaddingWidth - cellPadding + cellDefinition.outsidePadding * this.ratio;
			} else if(cellDefinition.insidePadding) {
				totalCellPaddingWidth = totalCellPaddingWidth - cellPadding + cellDefinition.insidePadding * this.ratio;
			}

			var gridWidth = this.defaultGridWidth;
			var gridHeight = this.defaultGridHeight;
			if(this.definition.grid) {
				gridWidth = this.definition.grid.width || 8.5;
				gridHeight = this.definition.grid.height || 11;
			}

			var cellRatio = cellDefinition.ratio || 0.8;
			if(cellRatio === '*') {
				cellRatio = 0.8;
			}
			var cellWidth = (gridWidth * this.ratio - sideBleed * 2) / columns - totalCellPaddingWidth;
			var cellHeight = cellWidth / cellRatio;
			var cellOuterHeight = cellHeight + cellPadding * 2;
			if(cellDefinition.bottomPadding) {
				cellOuterHeight += cellDefinition.bottomPadding * this.ratio;
			}

			if(this.definition.separateGrids) {
				for(i = 0; i < 2; i++) {
					outerWrapper = document.createElement('div');
					outerWrapper.className = 'imageOuterStyleWrapper';
					middleWrapper = document.createElement('div');
					middleWrapper.className = 'imageMiddleStyleWrapper';
					innerWrapper = document.createElement('div');
					innerWrapper.className = 'imageInnerStyleWrapper';
					outerWrapper.appendChild(middleWrapper);
					middleWrapper.appendChild(innerWrapper);

					imagePlaceholderWrapper = document.createElement('div');
					imagePlaceholderWrapper.className = 'imagePlaceholder';
					imagePlaceholderWrapper.appendChild(outerWrapper);
					$(imagePlaceholderWrapper).css({
						width: cellWidth * 1.5,
						height: cellHeight * 1.5,
						left: (gridWidth * this.ratio / 4) * (i + 1) + (0.22 * this.ratio),
						top: startY + cellPadding * 6
					});
					this.previewBox.append(imagePlaceholderWrapper);
				}

				startY = 2 * this.ratio;
			} else if(this.definition.primarySubjectPoseFrame) {
				outerWrapper = document.createElement('div');
				outerWrapper.className = 'imageOuterStyleWrapper';
				middleWrapper = document.createElement('div');
				middleWrapper.className = 'imageMiddleStyleWrapper';
				innerWrapper = document.createElement('div');
				innerWrapper.className = 'imageInnerStyleWrapper';
				outerWrapper.appendChild(middleWrapper);
				middleWrapper.appendChild(innerWrapper);

				imagePlaceholderWrapper = document.createElement('div');
				imagePlaceholderWrapper.className = 'imagePlaceholder';
				imagePlaceholderWrapper.appendChild(outerWrapper);
				$(imagePlaceholderWrapper).css({
					width: (gridWidth * this.ratio / 2) - (0.22 * this.ratio * 2),
					height: cellHeight * 1.5,
					left: (gridWidth * this.ratio / 4) + (0.22 * this.ratio),
					top: startY + cellPadding * 6
				});
				this.previewBox.append(imagePlaceholderWrapper);

				startY = 2 * this.ratio;
			}

			var labelFontSize = 11 * textRatio;
			var labelFontHeight = parseFloat($.convertToPx(labelFontSize));

			var hasCellLabel = (!this.definition.row || !this.definition.row.position) && this.definition.name && this.definition.name != 'none' && (!this.definition.cell || this.definition.cell.name !== 'none');
			if (hasCellLabel) {
				if(cellDefinition.name != 'inside') {
					cellOuterHeight += labelFontHeight;
					if (this.definition.name.order2 || this.definition.name.overflowLabel == 'wrap') {
						cellOuterHeight += labelFontHeight;
					}
				}
			}

			var rows = this.definition.rows || Math.floor((gridHeight * this.ratio - topPadding * 2 - startY) / cellOuterHeight);
			var startLeft = sideBleed;
			var startTop = topPadding + startY;

			var largeCellCol;
			if (this.definition.largeCell) {
				if ($.isInit(this.definition.largeCell.col.Left)) {
					largeCellCol = this.definition.largeCell.col.Left;
				} else {
					largeCellCol = this.definition.largeCell.col;
				}
			}

			// Only want to do this once
			let studentMask = this.definition.studentMask ?? this.definition.cell?.mask;
			if(studentMask) {
				$.FlowLayoutFrameUtils.addRoundedCornerMask(studentMask);
			}

			var rowIncrement = 1;
			for (var i = 0; i < rows; i += rowIncrement) {
				rowIncrement = 1;
				var startCell = 0, endCell = columns;
				var rowStartLeft = startLeft;
				if (this.definition.row && this.definition.row.position) {
					startCell++;

					for (let j = 0; j < columns; j++) {
						let label = $('<div class="textPlaceholder">');
						label.html('First Last');
						label.css({
							left: startLeft,
							width: cellWidth,
							'text-align': 'right',
							top: startTop + cellOuterHeight * i + (labelFontHeight * j) + cellPadding,
							fontSize: labelFontSize + 'pt'
						});
						this.previewBox.append(label);
					}
				}
				if (this.definition.grid && this.definition.grid.horizontalDecrement) {
					if (this.definition.grid.horizontalStart) {
						endCell = this.definition.grid.horizontalStart;
					}

					endCell -= i * this.definition.grid.horizontalDecrement;
				} else if(this.definition.row && this.definition.row.groupBy) {
					if(i === 0 && !this.definition.row.groupByOrder) {
						endCell = Math.floor(endCell / 2);
					} else if(i == rows - 1 && this.definition.row.groupByOrder) {
						endCell = Math.floor(endCell / 2);
					}
				} else if(i == rows - 1 && !this.definition.rows) {
					if(this.definition.cell && this.definition.cell.alignCells == 'right') {
						startCell = endCell / 2;
					} else if(this.definition.cell && this.definition.cell.alignCells == 'center') {
						startCell++;
						endCell = columns - 1;
					} else {
						endCell = Math.ceil(endCell / 2) + 1;
					}
				}

				if(this.definition.row && (this.definition.row.centered || (this.definition.row.topRowCentered && i === 0))) {
					var diff = columns - endCell;
					if(diff > 0) {
						rowStartLeft += (diff * cellWidth) / 2;
					}

					if(this.definition.largeCell) {
						endCell--;
						rowStartLeft += cellWidth / 2;

						if(this.definition.row && this.definition.row.groupBy) {
							rowIncrement = 2;
						}
					}
				}

				for (let j = startCell; j < endCell; j++) {
					// Probably should refactor this to be an actual calculation, but it was easy and works so...
					if(cellDefinition.wave && ((j <= 1 && i <= 1) || (i === 2 && j === 0) || (i === 0 && j === 2) || (j === 3 && i === 4) || (j === 5 && i === 3) || (i === 3 && j === 4) || (i === 2 && j === 5))) {
						continue;
					}

					if(cellDefinition.outsidePadding) {
						if(j % 2 == 0) {
							leftCellPadding = cellDefinition.outsidePadding * this.ratio;
							rightCellPadding = cellPadding;
						} else {
							leftCellPadding = cellPadding;
							rightCellPadding = cellDefinition.outsidePadding * this.ratio;
						}
					} else if(cellDefinition.insidePadding) {
						leftCellPadding = cellPadding;
						rightCellPadding = cellDefinition.insidePadding * this.ratio;
					}

					var colSpan = 1, rowSpan = 1;
					var thisLabelFontSize = labelFontSize;
					var labelText = this.definition.name ? this.definition.name.order : null;
					if (this.definition.largeCell) {
						// Main cell
						var centerCol = Math.floor(endCell / 2 - 1);
						var offCenterCol = centerCol + 1;
						if (i == this.definition.largeCell.row && ((largeCellCol == 0 && j == startCell) || (largeCellCol == -1 && j == (endCell - 2)) || (largeCellCol == 'center' && j == centerCol))) {
							colSpan = this.definition.largeCell.colSpan;
							rowSpan = this.definition.largeCell.rowSpan;
							thisLabelFontSize *= 1.5;

							if(this.definition.name && this.definition.name.teacherPrefixOrder) {
								labelText = this.definition.name.teacherPrefixOrder;
							}
						}
						// Overflown ones
						else if (i == (this.definition.largeCell.row + 1) && ((largeCellCol == 0 && j <= (startCell + rowSpan)) || (largeCellCol == -1 && j >= (endCell - 2)) || (largeCellCol == 'center' && (j == centerCol || j == offCenterCol)))) {
							continue;
						}
					}

					var cellTop = startTop + cellOuterHeight * i + cellPadding;
					var currentTop = cellTop;
					var cellLeft = rowStartLeft + (cellWidth + leftCellPadding + rightCellPadding) * j + leftCellPadding * colSpan;

					var outerWrapper = document.createElement('div');
					outerWrapper.className = 'imageOuterStyleWrapper';
					var middleWrapper = document.createElement('div');
					middleWrapper.className = 'imageMiddleStyleWrapper';
					var innerWrapper = document.createElement('div');
					innerWrapper.className = 'imageInnerStyleWrapper';
					outerWrapper.appendChild(middleWrapper);
					middleWrapper.appendChild(innerWrapper);

					var imagePlaceholderWrapper = document.createElement('div');
					imagePlaceholderWrapper.className = 'imagePlaceholder';
					imagePlaceholderWrapper.appendChild(outerWrapper);
					$(imagePlaceholderWrapper).css({
						width: cellWidth * colSpan,
						height: cellHeight * rowSpan,
						left: cellLeft,
						top: currentTop
					});

					let subjectEffects = this.definition.subjectEffects ?? this.definition.extras?.subjectEffects;
					if(subjectEffects) {
						$.extend(imagePlaceholderWrapper, {
							parent: {
								ratio: this.ratio
							},
							maskedBorder: true
						});
						$.FlowLayoutFrameUtils.applyFilters.call(imagePlaceholderWrapper, subjectEffects);
					}
					if(studentMask) {
						$(innerWrapper).css(studentMask);
					}

					this.previewBox.append(imagePlaceholderWrapper);
					currentTop += (cellHeight * rowSpan);

					if (hasCellLabel) {
						let label = $('<div class="textPlaceholder">');
						label.text(labelText.replace(/%/ig, '').toTitleCase());

						var labelCSS = {
							fontSize: thisLabelFontSize + 'pt',
							'white-space': 'nowrap'
						};
						if(this.definition.studentLabelCSS) {
							labelCSS = $.extend(true, {}, this.definition.studentLabelCSS, labelCSS);
						}

						if(cellDefinition.name != 'inside') {
							$.extend(labelCSS, {
								left: cellLeft,
								width: cellWidth * colSpan,
								top: currentTop
							});
						} else {
							$.extend(labelCSS, {
								left: cellLeft + cellWidth + (cellPadding * 2),
								width: cellWidth * colSpan,
								top: currentTop - cellHeight,
								'text-align': 'left'
							});
						}
						label.css(labelCSS);
						this.previewBox.append(label);
						currentTop += labelFontHeight;

						if (this.definition.name.order2 || (this.definition.name.overflowLabel == 'wrap' && ((j * endCell + i + 3) % 4 == 0))) {
							var secondLineText;
							if (this.definition.name.order2) {
								secondLineText = this.definition.name.order2.replace(/%/ig, '').toTitleCase();
							} else {
								secondLineText = 'Name';
							}

							var label2 = $('<div class="textPlaceholder">');
							label2.text(secondLineText);

							let secondLabelCSS = {
								left: cellLeft,
								width: cellWidth * colSpan,
								top: currentTop,
								fontSize: labelFontSize + 'pt',
								'white-space': 'nowrap'
							};
							if(this.definition.studentLabelCSS) {
								secondLabelCSS = $.extend(true, {}, this.definition.studentLabelCSS, secondLabelCSS);
							}

							label2.css(secondLabelCSS);
							this.previewBox.append(label2);
							currentTop += labelFontHeight;
						}
					}

					if(this.definition.cellTexts) {
						var textDefinition = this.definition.cellTexts[0];

						let label = $('<div class="textPlaceholder">');
						label.text(textDefinition.lines.text);

						var extraTop = 0, extraLeft = 0;
						var textWidth = cellWidth * colSpan;
						if(textDefinition.position) {
							if(textDefinition.position.top) {
								if(textDefinition.position.top == 'cellTop') {
									extraTop = cellTop - currentTop;
								} else if(textDefinition.position.top == 'labelBottom') {
									extraTop = labelFontHeight - cellHeight;
								} else {
									extraTop += textDefinition.position.top * this.ratio;
								}
							}

							if(textDefinition.position.outside) {
								var outside = textDefinition.position.outside;
								if(outside == 'outsidePadding') {
									outside = cellDefinition.outsidePadding;
								}

								if(j % 2 == 0) {
									extraLeft -= outside * this.ratio;
								} else {
									extraLeft += cellWidth * colSpan;
								}
							} else if(textDefinition.position.left == 'cellInside') {
								extraLeft += cellWidth;
							}

							if(textDefinition.manualSize) {
								if(textDefinition.manualSize.width == 'outsidePadding') {
									textWidth = outside * this.ratio;
								}
							}
						}

						label.css({
							left: cellLeft + extraLeft,
							width: textWidth,
							top: currentTop + extraTop,
							fontSize: labelFontSize + 'pt'
						});
						this.previewBox.append(label);
						currentTop += labelFontHeight + extraTop;
					}

					if (colSpan > 1) {
						j += colSpan - 1;
					}
				}
			}

			if(this.definition.showPageNumbers === true) {
				let label = $('<div class="textPlaceholder">');
				label.text('Page 1');

				label.css({
					left: 0,
					bottom: 0.25 * this.ratio,
					width: '100%',
					fontSize: (30 * textRatio) + 'pt'
				});
				this.previewBox.append(label);
			}
		},

		addBackground: function() {
			var background = this.definition.theme;
			if(!background) {
				return;
			}

			var url = null;
			if(background.Background) {
				if(background.Background.cdnUrl) {
					url = background.Background.cdnUrl;
				} else {
					url = this.getPlicThumbnail(background.Background, {
						w: 200
					});
				}
			} else if(background.type == 'project-background') {
				if(this.projectBackgroundId) {
					url = this.getPlicThumbnail({
						id: this.projectBackgroundId
					}, {
						w: 200
					});
				}
			}

			if(url) {
				var img = $('<img class="image">');
				this.setImage(img, url);

				let backgroundCSS = {
					left: 0,
					top: 0,
					width: '100%',
					height: '100%',
					'z-index': -1
				};
				if(this.definition.extras?.backgroundSettings?.crop) {
					let crop = this.definition.extras.backgroundSettings.crop;
					backgroundCSS.width = crop.width * 100 + '%';
					backgroundCSS.height = crop.height * 100 + '%';
					backgroundCSS.left = Math.min(0, crop.left) * 100 + '%';
					backgroundCSS.top = Math.min(0, crop.top) * 100 + '%';
				}

				img.css(backgroundCSS);

				if(this.definition.extras && this.definition.extras.backgroundSettings) {
					this.applyThemeSettings(img, this.definition.extras.backgroundSettings);
				} else if(this.definition.backgroundSettings) {
					this.applyThemeSettings(img, this.definition.backgroundSettings);
				}

				this.previewBox.append(img);
			}
		},
		applyThemeSettings: function (backgroundImage, settings) {
			if (settings.opacity) {
				$(backgroundImage).css('opacity', settings.opacity / 100.0);
			} else {
				$(backgroundImage).css('opacity', '');
			}

			if (settings.flipX || settings.flipY) {
				var transform = [];

				if (settings.flipX) {
					transform.push('scaleX(-1)');
				}
				if (settings.flipY) {
					transform.push('scaleY(-1)');
				}

				$(backgroundImage).css('transform', transform.join(' '));
			} else {
				$(backgroundImage).css('transform', '');
			}

			if(settings.hue) {
				$(backgroundImage).css('filter', 'hue-rotate(' + settings.hue + 'deg)');
			} else {
				$(backgroundImage).css('filter', '');
			}
		},
		addFrames: function() {
			var frames = this.definition.frames;
			if(!frames && this.definition.images) {
				frames = this.definition.images;
			}
			if(!frames && this.definition.candids) {
				frames = this.definition.candids;
			}

			if (frames) {
				frames = this.convertToArray(frames);

				for (var i = 0; i < frames.length; i++) {
					var frame = $.extend(true, {}, frames[i]);
					this.addFrame(frame);
				}
			}
		},
		addFrame: function(frame) {
			if (typeof frame.cell == 'string') {
				var cell = this.definition[frame.cell];
				for (let x in cell) {
					if (typeof frame[x] == 'undefined') {
						frame[x] = cell[x];
					}
				}
			}

			let width = frame.width;
			let height = frame.height;

			let x, y;
			if (frame.position) {
				x = frame.position.left;
				y = frame.position.top;
			} else {
				x = frame.x;
				y = frame.y;
			}

			var wrapper = $('<div class="image">');
			var img = document.createElement('img');
			let backgroundImg;
			if(frame.photoFile) {
				this.setImage(img, URL.createObjectURL(frame.photoFile.file));
			} else if(frame.photo) {
				let url = this.getPlicThumbnail(frame, {
					w: 200
				});

				this.setImage(img, url);
			} else if (frame.field) {
				let url = 'https://img.plic.io/aNdyGwKkONe95Rn_5vaKHV9v0ng87Da0a3vSBQuPqvY//aHR0cHM6Ly9wbGljLWlvLnMzLmFtYXpvbmF3cy5jb20vcHVibGljL3BvcnRyYWl0X3BsYWNlaG9sZGVyXzgxMC5zdmc.svg';

				// Lock it into a .8 ratio
				if (frame.field == 'Image Name') {
					var subject = this.subject;
					if(typeof frame.subjectIndex !== 'undefined' && this.subjects && frame.subjectIndex < this.subjects.length) {
						subject = this.subjects[frame.subjectIndex];
					}

					var subjectPose = $.FlowLayoutFrameUtils.getSubjectPose(frame, subject);
					if(!subjectPose && subject.photo && subject.photos) {
						subjectPose = subject.photos.find(photo => photo.id === subject.photo);
					}
					// PLIC requires a primary photo, but if we upload (or BP syncs) a group photo, that shouldn't really be treated as the primary
					if(subjectPose && subjectPose.category === 'group_category') {
						subject.photo = subject.photoCdnUrl = subject.photoWidth = subject.photoHeight = subject.chromaKey = subjectPose = null;
					}
					if(subjectPose && subject.photo && subject.photo != subjectPose.id) {
						subject.photoWidth = subjectPose.width;
						subject.photoHeight = subjectPose.height;
						subject.chromaKey = subjectPose.chroma_key;
						subject.photo = subjectPose.id;
						subject.photoCdnUrl = null;
					}

					if(subject.photoWidth && subject.photoHeight) {
						img.photoWidth = subject.photoWidth;
						img.photoHeight = subject.photoHeight;

						if(frame.cropSelection != 'no crop') {
							img.photoCrop = this.getSubjectImageCrop(subject);
						}
					}

					this.applyImgCrop(frame, img);

					if(subject.photoCdnUrl) {
						url = subject.photoCdnUrl;
					} else if(subject.photo) {
						url = this.getPlicThumbnail(subject.photo);
					}

					if(subject.chromaKey === 'processed' && frame.useGreenScreenBackground) {
						let backgroundUrl;
						if(subjectPose?.background_photo_id) {
							backgroundUrl = this.getPlicThumbnail({
								id: subjectPose.background_photo_id
							}, {
								w: 200
							});
						} else if(this.projectBackgroundId) {
							backgroundUrl = this.getPlicThumbnail({
								id: this.projectBackgroundId
							}, {
								w: 200
							});
						}

						if(backgroundUrl) {
							backgroundImg = document.createElement('img');
							this.setImage(backgroundImg, backgroundUrl);
						}
					}
				}

				this.setImage(img, url);
			} else if(frame.photoFieldMap) {
				this.setupDynamicImage(frame.photoFieldMap, img);
			} else {
				$(img).addClass('imagePlaceholder');
				// A blank src makes it appear to have a border around the img node in Chrome
				img.src = "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E";

				if(frame.shape && frame.color) {
					$(img).css({
						background: frame.color
					});
				}
			}

			var zIndex = frame.zIndex ? frame.zIndex : '';

			wrapper.css({
				width: width * this.ratio,
				height: height * this.ratio,
				left: (x + this.bleed.left) * this.ratio,
				top: (y + this.bleed.top) * this.ratio,
				transform: frame.transform,
				zIndex: zIndex
			});

			var outerWrapper = document.createElement('div');
			outerWrapper.className = 'imageOuterStyleWrapper';
			var middleWrapper = document.createElement('div');
			middleWrapper.className = 'imageMiddleStyleWrapper';
			var innerWrapper = document.createElement('div');
			innerWrapper.className = 'imageInnerStyleWrapper';
			outerWrapper.appendChild(middleWrapper);
			middleWrapper.appendChild(innerWrapper);
			if(backgroundImg) {
				innerWrapper.appendChild(backgroundImg);
			}
			innerWrapper.appendChild(img);

			img.frame = frame;
			$(wrapper).append(outerWrapper).appendTo(this.previewBox);

			$.extend(wrapper[0], {
				parent: {
					ratio: this.ratio
				},
				maskedBorder: true
			});
			$.FlowLayoutFrameUtils.applyFilters.call(wrapper[0], frame);

			if(frame.mask) {
				$.FlowLayoutFrameUtils.addRoundedCornerMask(frame.mask);
				$(innerWrapper).css(frame.mask);
			}
		},
		getSubjectImageCrop: function(subject) {
			if(subject.photos) {
				var photo = subject.photos.filter(function(photo) {
					return photo.id === subject.photo;
				})[0];

				if(photo) {
					if(photo.default_photo_crop) {
						return photo.default_photo_crop;
					} else if(photo.default_photo_crop_id && photo.photo_crops) {
						return photo.photo_crops.filter(function(crop) {
							return crop.id == photo.default_photo_crop_id;
						})[0];
					}
				}
			}

			return null;
		},
		applyImgCrop: function(frame, img) {
			var dynamicCrop = img.photoCrop;
			if(frame.crop) {
				var cropWidth = (1 / frame.crop.width);
				var cropHeight = (1 / frame.crop.height);
				dynamicCrop = {
					x: -(frame.crop.left * cropWidth),
					y: -(frame.crop.top * cropHeight),
					width: cropWidth,
					height: cropHeight
				};
			}

			$(img).css($.FlowLayoutFrameUtils.getDynamicFieldCrop(frame, img.photoWidth || 80, img.photoHeight || 100, dynamicCrop));
		},
		setupDynamicImage: function(photoFieldMap, img) {
			for(var i = 0; i < photoFieldMap.length; i++) {
				var map = photoFieldMap[i];

				if(this.subject.isPlaceholderSubject || this.checkIfFieldMapMatches(this.subject, map.fieldMap)) {
					var photo = map.photo;

					var url;
					if(photo.file && photo.file.file) {
						url = URL.createObjectURL(photo.file.file);
					} else {
						url = this.getPlicThumbnail(photo, {
							w: 200
						});
					}

					this.setImage(img, url);
					return;
				}
			}
			
			// A blank src makes it appear to have a border around the img node in Chrome
			img.src = "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E";
		},
		checkIfFieldMapMatches: function(subject, fieldMap) {
			return $.FlowLayoutFrameUtils.checkIfFieldMapMatches(function(name) {
				return subject[name];
			}, fieldMap);
		},

		getPlicThumbnail: function(photo, options) {
			if($.getPlicThumbnail) {
				return $.getPlicThumbnail(photo, options);
			} else {
				var id;
				if(photo.existingUrl) {
					return photo.existingUrl;
				} else if(photo.photo) {
					id = photo.photo;
				} else if(photo.id) {
					id = photo.id;
				} else {
					id = photo;
				}

				return this.plicIO + 'api/photos/' + id + '/thumb?opts[op]=resize,rotate&opts[deg]=auto&opts[mode]=clip&opts[filter]=bilinear&opts[q]=90&opts[w]=200'
			}
		},
		setImage: function(img, url) {
			// TODO: Find a good way to encapsulate this class so we can use it even in third party services
			if($(img).LoadImage) {
				$(img).LoadImage(url, {
					onComplete: function() {
						obj.fixRegisteredAspectRatio(img);
					}
				});
			} else {
				$(img).attr('src', url).on('load', function() {
					obj.fixRegisteredAspectRatio(img);
				});
			}
		},
		fixRegisteredAspectRatio: function(img) {
			if(img.photoWidth && img.photoHeight && img.naturalWidth && img.naturalHeight) {
				var naturalAspectRatio = img.naturalWidth / img.naturalHeight;
				var invertedAspectRatio = img.photoHeight / img.photoWidth;

				// We have a rotated image
				if(Math.abs(naturalAspectRatio - invertedAspectRatio) <= 0.01) {
					var temp = img.photoHeight;
					img.photoHeight = img.photoWidth;
					img.photoWidth = temp;

					this.applyImgCrop(img.frame, img);
				}
			}
		},

		addTexts: function(textRatio) {
			var texts = this.definition.texts;
			if(texts) {
				texts = this.convertToArray(texts);

				for(var i = 0; i < texts.length; i++) {
					var text = texts[i];
					this.addText(text, textRatio);
				}
			}

			this.addGoogleFonts();
		},
		addText: function(text, textRatio) {
			var fontSize;
			if (text.fontSize) {
				fontSize = text.fontSize;
			} else {
				fontSize = 14;
			}
			fontSize = fontSize * textRatio;

			var subject = this.subject;
			if(typeof text.subjectIndex !== 'undefined' && this.subjects && text.subjectIndex < this.subjects.length) {
				subject = this.subjects[text.subjectIndex];
			}

			var html;
			var css = {};
			if (text.style) {
				html = $.scaleText(text.style, textRatio);
			} else if (text.lines) {
				var lines = text.lines;
				if(!$.isArray(lines)) {
					lines = [lines];
				}

				var line;
				if($.isArray(lines)) {
					line = lines[0];
				} else {
					line = lines;
				}

				// Can be reached by an empty array of lines
				if(!line) {
					return;
				}

				var styleLine = line;
				if(line.parts) {
					styleLine = line.parts[0];
				}

				var lineHeight = text.lineHeight ? text.lineHeight : this.defaultLineHeight;
				var autoSized = false;
				if (styleLine['font-size']) {
					if(styleLine['font-size'] == 'auto') {
						if(text.manualSize) {
							fontSize = this.convertToPt(text.manualSize.height / lines.length / lineHeight * this.PRODUCTION_RATIO);
							autoSized = true;
						} else {
							fontSize = 14;
						}
					} else if (typeof styleLine['font-size'] == 'string') {
						fontSize = parseFloat(styleLine['font-size'].replace('pt', ''));
					} else {
						fontSize = styleLine['font-size'];
					}

					fontSize = fontSize / this.PRODUCTION_RATIO * this.ratio;
				}

				var fontFamily = styleLine.fontFamily && styleLine.fontFamily.toLowerCase();
				if(fontFamily && (fontFamily.indexOf('c39') != -1 || (fontFamily.indexOf('code') !== -1 && fontFamily.indexOf('unicode') === -1))) {
					if(styleLine.fontFamily == 'qr code') {
						html = this.createQrCode(subject, text, styleLine, fontSize);
					} else {
						html = this.createBarcode(subject, text, styleLine, fontSize);
					}
					if(!html) {
						return;
					}

					if(line.align) {
						css['text-align'] = line.align;
					}
				} else {
					var style = '';
					for(var styleName in styleLine) {
						if(['effects', 'text', 'parts', 'font-size', 'align'].indexOf(styleName) == -1) {
							var setStyleName = styleName;
							if (styleName == 'fontFamily') {
								setStyleName = 'font-family';
							} else if (styleName == 'backgroundColor') {
								setStyleName = 'background-color';
							} else if(styleName == 'stroke') {
								setStyleName = '-webkit-text-stroke';
							} else if(styleName == 'drop-shadow') {
								setStyleName = 'text-shadow';
							}

							var value = styleLine[styleName];
							if(value === null || value === undefined) {
								continue;
							}

							if(styleName == 'fontFamily') {
								// Get rid of imported in Windows fonts
								var titleCaseFont = this.getTitleCaseFont(value);
								if(styleLine.customFont) {
									this.customGoogleFonts.push(styleLine.customFont);
								} else if($.GoogleFontList.indexOf(titleCaseFont) == -1) {
									continue;
								}

								value = "'" + value + "'";
							} else if(styleName == 'stroke') {
								var thickness = fontSize / this.PRODUCTION_RATIO * 3 * value.thickness;
								value = thickness + 'px ' + value.color;
							} else if(styleName == 'drop-shadow') {
								if(line['drop-shadow']) {
									continue;
								}
								
								var depth = fontSize * value.depth / this.PRODUCTION_RATIO;
								var intensity = value.intensity * fontSize / this.PRODUCTION_RATIO;
								value = depth + 'px ' + depth + 'px ' + intensity + 'px ' + value.color;
							}

							style += setStyleName + ': ' + value + ';';
						}
					}

					if(line.align) {
						style += 'text-align: ' + line.align + ';';
					}
					if(line['drop-shadow']) {
						let depth = fontSize * line['drop-shadow'].depth / this.PRODUCTION_RATIO;
						let intensity = line['drop-shadow'].intensity * fontSize / this.PRODUCTION_RATIO;
						style += 'text-shadow: ' + depth + 'px ' + depth + 'px ' + intensity + 'px ' + line['drop-shadow'].color + ';';
					}

					let label = '';
					for (let j = 0; j < lines.length; j++) {
						if (j > 0) {
							label += '<br/>';
						}

						var subLine = lines[j];
						var parts = subLine.parts ? subLine.parts : [subLine];
						for (var k = 0; k < parts.length; k++) {
							var part = parts[k];
							label += part.text;
						}
					}

					// Auto sized labels do word wrap
					if(!autoSized) {
						label = label.replace(/ /g, String.fromCharCode(160));
					}
					label = this.replacePlaceholdersWithText(subject, label, autoSized);
					if(styleLine.upperCase) {
						label = label.toUpperCase();
					}

					html = '<div style="' + style + '">' + label + '</div>';
				}
			} else {
				html = this.replacePlaceholdersWithText(subject, text.text);
			}

			var div = $('<div class="textPlaceholder">');
			if(text.lines) {
				div.addClass('svgPlaceholder');
			}
			div.html(html);

			// Some text nodes are missing information
			if(!text.position) {
				text.position = {
					left: 0,
					top: 0
				};
			}

			var filter = '';
			if(text.opacity) {
				filter += 'opacity(' + text.opacity + '%) ';
			}

			var zIndex = text.zIndex ? text.zIndex : '';
			$.extend(css, {
				left: (text.position.left + this.bleed.left) * this.ratio,
				top: (text.position.top + this.bleed.top) * this.ratio,
				fontSize: fontSize + 'pt',
				transform: text.transform,
				zIndex: zIndex,
				filter: filter,
				'-webkit-filter': filter
			});
			if(text.position.left === 'center') {
				css.left = 0;
				css.right = 0;
				css['text-align'] = 'center';
			}
			div.css(css);

			if ((text.resizable || text.lines) && text.manualSize) {
				div.css({
					width: text.manualSize.width * this.ratio,
					height: text.manualSize.height * this.ratio
				});
			}

			// Check if within allowed width
			if(autoSized) {
				// Hack needed since in a lot of cases this isn't on the DOM yet
				var temp = $('<div class="layoutEditorPreview">').append(div).appendTo('body');
				this.checkForTextOverflow(div[0]);
				temp.remove();
			}

			this.previewBox.append(div);

			return div;
		},
		checkForTextOverflow: function(div) {
			var sizeDiff;
			for(var i = 0; i < 20; i++) {
				sizeDiff = Math.min(div.clientWidth / div.scrollWidth, div.clientHeight / div.scrollHeight);
				if(sizeDiff >= 1) {
					return;
				}

				var fontSize = parseFloat(div.style['font-size'].replace('pt', ''));
				$(div).css('font-size', (fontSize * Math.max(sizeDiff, 0.9)) + 'pt');
			}
		},
		createBarcode: function(subject, instance, line, fontSizePt) {
			var img = document.createElement('img');
			$(img).addClass('barcode');

			var format = 'code39';
			if(line.fontFamily.indexOf('128') != -1) {
				format = 'code128';
			}

			var barWidth = 2;
			if(line.barWidth) {
				barWidth = line.barWidth;
			}
			barWidth = (barWidth / 2) * (this.ratio / this.PRODUCTION_RATIO);

			var height = this.convertToPx(fontSizePt, true);
			if(instance && instance.manualSize && instance.manualSize.height) {
				height = instance.manualSize.height * this.ratio;
			}

			var text = this.replacePlaceholdersWithText(subject, line.text);
			// Doesn't seem to like * in barcodes
			text = text.replace($.BarcodeIllegalCharacters, '');
			if(!text) {
				return null;
			}

			try {
				$(img).JsBarcode(text, {
					format: format,
					displayValue: false,
					margin: 0,
					height: height,
					width: barWidth
				});
			} catch(e) {
				if($.fireErrorReport) {
					$.fireErrorReport(null, 'Failed to initialize barcode', 'Failed to initialize barcode', {
						exception: e,
						text: text,
						instance: instance
					});
				} else {
					console.error('Failed to initialize barcode', e);
				}
			}

			return img.outerHTML;
		},
		createQrCode: function(subject, instance, line) {
			var width = 100;
			var height = 100;
			if(instance.manualSize) {
				width = instance.manualSize.width;
				height = instance.manualSize.height;
			}
			var size = Math.min(width, height) * this.ratio;

			var text = this.replacePlaceholdersWithText(subject, line.text);
			var options = {
				text: text,
				width: size * 10,
				height: size * 10
			};

			if(line.color) {
				options.colorDark = line.color;
			}
			if(line['background-color']) {
				options.colorLight = line['background-color'];
			}

			var div = document.createElement('div');
			new window.QRCode(div, options);

			let imgNode = $(div).find('img');
			imgNode.addClass('qr-code');

			switch(line.align) {
				case 'left':
					break;
				case 'right':
					imgNode.css('margin-left', 'auto');
					break;
				default:
					imgNode.css({
						'margin-left': 'auto',
						'margin-right': 'auto'
					});
			}

			return imgNode[0];
		},
		convertToPx: function(pt) {
			return parseFloat(pt) * 96.0 / 72.0;
		},
		convertToPt: function(px) {
			return parseFloat(px) * 72.0 / 96.0;
		},
		replacePlaceholdersWithText: function(subject, text, autoSized) {
			if(!text || !text.replace) {
				return '';
			}

			for(var name in $.combinedSubjectFields) {
				if(!subject[name]) {
					text = text.replace(new RegExp('%(' + name + ')%', 'ig'), $.combinedSubjectFields[name].replace(/ /ig, String.fromCharCode(160)));
				}
			}

			for (let field in subject) {
				var value = subject[field];
				if(value === undefined || value === null) {
					value = '';
				}

				if(!autoSized) {
					field = field.replace(/ /ig, String.fromCharCode(160));
				}

				let fieldTextRegex = new RegExp('%' + field + '(\\d?)%', 'i');
				text = text.replace(fieldTextRegex, value);
			}

			for(let field in this.globalPlaceholders) {
				var fieldSearch = field;
				if(!autoSized) {
					fieldSearch = fieldSearch.replace(/ /ig, String.fromCharCode(160));
				}

				let fieldTextRegex = new RegExp('%' + fieldSearch + '%', 'i');
				text = text.replace(fieldTextRegex, this.globalPlaceholders[field]);
			}

			return text;
		},
		addGoogleFonts: function() {
			if(!this.hasGoogleFonts('Crafty Girl')) {
				let fontUrl = 'https://fonts.googleapis.com/css?family=' + $.GoogleFontList.join('|').replace(/ /ig, '+');
				let link = '<link rel="stylesheet" type="text/css" href="' + fontUrl + '"/>';
				$('head').append(link);
			}

			if(this.customGoogleFonts.length && !this.hasGoogleFonts(this.customGoogleFonts[0])) {
				let fontUrl = 'https://fonts.googleapis.com/css?family=' + this.customGoogleFonts.join('|').replace(/ /ig, '+');
				let link = '<link rel="stylesheet" type="text/css" href="' + fontUrl + '"/>';
				$('head').append(link);
			}
		},
		hasGoogleFonts: function(checkFont) {
			var hasGoogleFonts = false;

			$('link').each(function() {
				if(this.href && this.href.indexOf(checkFont.replace(/ /g, '+')) != -1) {
					hasGoogleFonts = true;
					return false;
				}
			});

			return hasGoogleFonts;
		},

		convertToArray: function(object) {
			if($.isPlainObject(object)) {
				var array = [];
				for(var i in object) {
					array.push(object[i]);
				}

				return array;
			} else {
				return object;
			}
		},

		addGrid: function(grid) {
			var gridLines = $('<div class="layoutPreviewGridLines">').appendTo(this.previewBox);

			gridLines.css({
				position: 'absolute',
				left: (grid.bleed.left * this.ratio - 1) + 'px',
				top: (grid.bleed.top * this.ratio - 1) + 'px',
				width: ((grid.width - grid.bleed.left - grid.bleed.right) * this.ratio + 2) + 'px',
				height: ((grid.height - grid.bleed.top - grid.bleed.bottom) * this.ratio + 2) + 'px',
				border: '1px dotted black'
			});
		},

		setPreviewWidth: function(width) {
			this.settings.previewWidth = width;
			this.updatePreview();
		},
		updatePreviewPageDimensions: function (pageDimensions) {
			pageDimensions.ratio = pageDimensions.width / pageDimensions.height;

			var height;
			if (this.settings.previewWidth) {
				var width = this.settings.previewWidth;
				height = this.settings.previewWidth / pageDimensions.ratio;

				if (this.settings.maxHeight && height > this.settings.maxHeight) {
					height = this.settings.maxHeight;
					width = height * pageDimensions.ratio;
				}

				this.previewBox.width(width);
				this.previewBox.height(height);
				this.previewBox.css('min-height', height + 'px');
			} else {
				height = this.previewBox.height();

				var maxWidth = height * pageDimensions.ratio;
				var parentWidth = this.previewBox.parent().width();
				if (maxWidth > parentWidth) {
					maxWidth = parentWidth;

					height = maxWidth / pageDimensions.ratio;
					this.previewBox.height(height);
				}
				this.previewBox.width(maxWidth);
			}

			this.ratio = height / pageDimensions.height;
		},

		getLayout: function () {
			if (this.id) {
				return {
					id: this.id,
					title: this.title,
					definition: this.definition,
					order: this.order
				};
			} else if (this.definition && this.nameBox) {
				return {
					title: this.nameBox.val(),
					definition: this.definition,
					order: this.order
				};
			} else if (this.title) {
				return {
					title: this.title,
					definition: this.definition,
					order: this.order
				};
			} else {
				return null;
			}
		},
		getDefinition: function () {
			return JSON.stringify(this.definition);
		},
		getUniqueId: function() {
			function s4() {
				return Math.floor((1 + Math.random()) * 0x10000)
					.toString(16)
					.substring(1);
			}

			return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
		},
		getTitleCase: function(string) {
			var i, j, str, uppers;
			str = string.replace(/([^\W_]+[^\s-]*) */g, function(txt) {
				return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
			});

			// Certain words such as initialisms or acronyms should be left uppercase
			uppers = ['Id', 'Tv', 'Sc'];
			for (i = 0, j = uppers.length; i < j; i++) {
				str = str.replace(new RegExp('\\b' + uppers[i] + '\\b', 'g'), uppers[i].toUpperCase());
			}

			return str;
		},
		getTitleCaseFont: function(font) {
			font = (font || '').toLowerCase();

			var titleFont = this.getTitleCase(font);
			if($.GoogleFontSpecialTitleCaseMap[font]) {
				titleFont = $.GoogleFontSpecialTitleCaseMap[font];
			}

			return titleFont;
		},
		setLoading: function(loading) {
			if(loading) {
				if(!this.loader) {
					this.loader = $('<div class="ui active inverted dimmer"><div class="ui text loader"></div></div>').appendTo(this.previewBox);
				}
			} else if(this.loader) {
				this.loader.remove();
			}
		},
		settings: settings,
		subject: {
			'Grade': '8',
			'First Name': 'John',
			'Last Name': 'Doe',
			'Student ID': '2006002',
			'Code': 'Barcode Sample',
			'Teacher': 'Butler',
			'Project': 'John Doe High School',
			'Organization': 'John Doe High School',
			'Page Number': '1',
			'Prefix': 'Mr.',
			'Title': 'Title',
			isPlaceholderSubject: true
		},
		plicIO: 'https://plic.io/',
		PRODUCTION_RATIO: 96,
		defaultLineHeight: 1.33,
		defaultGridWidth: 8.5,
		defaultGridHeight: 11,
		customGoogleFonts: [],
		displayGrid: false
	}, settings);


	if(obj.definitionBox) {
		obj.definitionBox.on('input', function() {
			obj.updateDefinition($(this).val());
		});
	}

	if(obj.orderBox) {
		obj.orderBox.on('input', function() {
			obj.order = $(this).val();
		});
	}
	if(obj.previewBox) {
		obj.previewBox.addClass('layoutEditorPreview');

		if(!obj.pageDimensions) {
			obj.pageDimensions = {
				width: obj.defaultGridWidth,
				height: obj.defaultGridHeight
			}
		}

		obj.updatePreviewPageDimensions(obj.pageDimensions);
		obj.previewBox[0].layoutPreview = obj;
	}

	obj.globalPlaceholders = {};
	$.dynamicGlobalVariables.forEach(function(field) {
		obj.globalPlaceholders[field] = $.getDynamicGlobalVariables(field, {
			season: settings.season
		});
	});
	obj.subject = $.extend(true, {}, obj.subject);

	return obj;
};