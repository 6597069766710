import Vue from 'vue';

Vue.mixin({
	computed: {
		baseUrl() {
			return process.env.VUE_APP_MAIN_APP_LOCATION || '/';
		},
		logoUrl() {
			if(process.env.VUE_APP_LOGO_URL) {
				return process.env.VUE_APP_LOGO_URL;
			} else {
				return this.baseUrl + 'css/images/logo.png';
			}
		},
		largeLogoUrl() {
			return this.logoUrl;
		}
	}
});