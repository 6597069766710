import ExifReader from 'exifreader';

// Returns blob
export function rotateImage(imageUrl, rotateRight) {
	return loadImage(imageUrl).then(({ image, type, startOrientation }) => {
		let newOrientation = rotateOrientation(startOrientation, rotateRight);
		let canvas = hardRotateImage(image, newOrientation);

		return new Promise(function(resolve, reject) {
			canvas.toBlob(function(blob) {
				if(!blob) {
					reject('Blob is null');
				}

				blob.imageWidth = canvas.width;
				blob.imageHeight = canvas.height;

				resolve(blob);
			}, type, 0.95);
		});
	});
}

function loadImage(imageUrl) {
	return fetch(imageUrl).then((response) => {
		return response.blob();
	}).then((blob) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.addEventListener('loadend', () => {
				try {
					let tags = ExifReader.load(reader.result);
					let startOrientation = tags?.Orientation?.value ?? 1;

					resolve({
						blob,
						startOrientation
					});
				} catch(e) {
					console.warn('Failed to read EXIF data tags: ', e);
					// Assume EXIF orientation of 1 if we can't read for any reason
					resolve({
						blob,
						startOrientation: 1
					});
				}
			});
			reader.addEventListener('error', (e) => {
				reject(e);
			});

			reader.readAsArrayBuffer(blob);
		});
	}).then(({ blob, startOrientation }) => {
		return new Promise((resolve, reject) => {
			let image = new Image();
			image.crossOrigin = 'Anonymous';
			image.onload = function() {
				resolve({
					image,
					type: blob.type,
					startOrientation
				});
			};
			image.onerror = function(e) {
				reject(e);
			};

			image.src = URL.createObjectURL(blob);
		});
	});
}

export function rotateOrientation(startOrientation, rotateRight) {
	if(startOrientation < 1 || startOrientation > 8) {
		startOrientation = 1;
	}

	let orientationTrack1 = [1, 8, 3, 6];
	let orientationTrack2 = [2, 7, 4, 5];
	let inc = rotateRight ? -1 : 1;

	let track = orientationTrack2;
	if(orientationTrack1.includes(startOrientation)) {
		track = orientationTrack1;
	}

	let index = track.indexOf(startOrientation);
	let newIndex = index + inc;
	if(newIndex >= track.length) {
		return track[0];
	} else if(newIndex < 0) {
		return track[track.length - 1];
	} else {
		return track[newIndex];
	}
}

function hardRotateImage(image, orientation) {
	let transforms = [
		// [flip-x, flip-y, deg]
		[false, false, 0],   // 1
		[true,  false, 0],   // 2
		[false, false, 180], // 3
		[false, true,  0],   // 4
		[true,  false, 90],  // 5
		[false, false, 90],  // 6
		[true,  false, -90], // 7
		[false, false, -90]  // 8
	];

	let transform = transforms[orientation - 1];
	let flipX = transform[0];
	let flipY = transform[1];
	let deg = transform[2];

	let canvas = document.createElement('canvas');
	let ctx = canvas.getContext('2d');
	let width = image.naturalWidth || image.width;
	let height = image.naturalHeight || image.height;

	canvas.width = Math.abs(deg) === 90 ? height : width;
	canvas.height = Math.abs(deg) === 90 ? width : height;

	if(flipX || flipY) {
		flip(canvas, ctx, flipX, flipY);
	}

	if(deg) {
		rotate(canvas, ctx, deg);
	}

	ctx.drawImage(image, 0, 0);

	return canvas;
}

function flip(canvas, ctx, flipX, flipY) {
	ctx.translate(flipX ? canvas.width : 0, flipY ? canvas.height : 0);
	ctx.scale(flipX ? -1 : 1, flipY ? -1 : 1);
}

function rotate(canvas, ctx, deg) {
	let width = canvas.width;
	let height = canvas.height;

	ctx.translate(width / 2, height / 2);
	ctx.rotate(deg * (Math.PI / 180));
	ctx.translate(-width / 2, -height / 2);

	if(Math.abs(deg) === 90) {
		ctx.translate((width - height) / 2, -(width - height) / 2);
	}
}