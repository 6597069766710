$.ProofDB = function(settings) {
	var db = new $.DBQueue($.extend({
		loadUrl: 'ajax/compositeProofs.php',
		loadType: 'GET',
		saveUrl: 'ajax/compositeProofs.php',
		postData: {
			proofId: $.getGETParams()['proofId']
		},

		loadFromModel: function(proofData) {
			for(var name in proofData) {
				if(name !== 'renders') {
					db.proof[name] = proofData[name];
				}
			}

			proofData.renders.forEach(function(renderData) {
				db.proof.addRender(db.createRenderFromData(renderData));
			});
		},
		createRenderFromData: function(renderData) {
			let comments = renderData.comments;
			let fixedComment = false;
			if($.isArray(comments) && comments.length > 0) {
				comments.forEach(comment => {
					if(!comment.id) {
						Object.keys(comment).forEach(commentProp => {
							if($.isPlainObject(comment[commentProp]) && comment[commentProp].id === commentProp) {
								let realComment = comment[commentProp];
								Object.keys(realComment).forEach(realProp => comment[realProp] = realComment[realProp]);
								delete comment[commentProp];
							}
						});
					}
				});

				let commentObj = {};
				comments.forEach(comment => {
					if(comment.id) {
						commentObj[comment.id] = comment;
					}
				});
				comments = commentObj;
				fixedComment = true;
			}
			var render = $.ProofRender(renderData);
			if(fixedComment) {
				render.db = this;
				render.jsonReplace('comments', comments);
			}

			return render;
		}
	}, settings));

	if(settings.proof) {
		settings.proof.db = db;
	}

	return db;
};