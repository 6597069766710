$.LayoutPreviewSheet = function(settings) {
	var obj = new $.LayoutPreview(settings);

	var _setLayout = obj.setLayout;
	$.extend(obj, {
		setLayout: function(layout) {
			if(layout && layout.type === 'Sheet') {
				this.layout = layout;
				layout = $.extend(true, {}, layout);
				if (typeof layout.definition == 'string') {
					layout.definition = JSON.parse(layout.definition);
				}

				if(layout.definition && layout.definition.grid) {
					var page = {
						getSubjects: function() {
							if(this.subjects) {
								return this.subjects;
							} else if(this.subject) {
								return this.subject;
							} else {
								return [];
							}
						},
						getCandids: function() {
							return layout.definition.images;
						},
						getTexts: function() {
							return layout.definition.texts
						},
						layout: layout.definition,
						subjects: this.subjects
					};
					var sheetTexts = $.FlowLayoutSheetUtils.getSheetTexts(page);
					var sheetImages = $.FlowLayoutSheetUtils.getSheetImages(page);
					layout.definition = $.FlowLayoutSheetUtils.getSheetLayout(page, {
						editable: !this.subjects || !this.subjects.length
					});
					$.extend(layout.definition.texts, sheetTexts);
					$.extend(layout.definition.images, sheetImages);
				}
			}

			_setLayout.apply(this, arguments);
		},
		setSubjects: function(subjects) {
			this.subjects = subjects;
			this.setLayout(this.layout);
		}
	});

	return obj;
};