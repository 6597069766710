$.FlowLayoutSheet = function(options) {
	options = $.extend({}, {
		displaySheet: true
	}, options);

	var flowLayout = new $.FlowLayout(options);

	var _setLayout = flowLayout.setLayout;
	var _setRatio = flowLayout.setRatio;
	var _getSubjects = flowLayout.getSubjects;
	var _setTheme = flowLayout.setTheme;
	var _saveTheme = flowLayout.saveTheme;
	var _clearBackground = flowLayout.clearBackground;
	var _getDynamicFields = flowLayout.getDynamicFields;
	var _getDynamicFieldRep = flowLayout.getDynamicFieldRep;
	var _getPrimarySubject = flowLayout.getPrimarySubject;
	$.extend(flowLayout, {
		setDisplaySheet: function(value) {
			this.displaySheet = value;
			if($.mainApp && $.mainApp.sidebarTabs[0]) {
				$.mainApp.sidebarTabs[0].properties.displaySheet = value;
			}
			this.page.showSheet = value;

			this.setPage(this.page, true);
		},
		setLayout: function(definition, extras) {
			if(definition.sheet && this.displaySheet) {
				this.extraContentProperties = {
					sheet: true
				};
				this.setSheet(definition, extras);
			} else {
				this.extraContentProperties = {};
				_setLayout.apply(this, arguments);
			}
		},
		filterFramesSet: function(frames) {
			if(this.displaySheet === false) {
				frames = $.extend(true, {}, frames);
				for(var id in frames) {
					if(frames[id].sheet) {
						delete frames[id];
					}
				}
			}

			return frames;
		},
		filterTextsSet: function(texts) {
			if(this.displaySheet === false) {
				texts = $.extend(true, {}, texts);
				for(var id in texts) {
					if(texts[id].sheet) {
						delete texts[id];
					}
				}
			}

			return texts;
		},
		setSheet: function(definition, extras) {
			this.removeCurrentLayout();

			this.definition = definition;
			this.initLayoutDefinition(definition);
			this.setupInchDimensions({
				grid: definition.sheet
			});

			this.setupOuterSize();
			this.setRatio($(this).getFloatStyle('height') / this.inchHeight);
			this.container.setBleed(this.inchBleed);
			this.updatePageMargins();

			this.setupSheetGrid(definition);
			this.addLayouts(definition);

			this.applyGlobalEffects();
		},
		addLayouts: function(definition) {
			var page = this.getPage();
			
			var layout = $.FlowLayoutSheetUtils.getSheetLayout(page, {
				definition: definition,
				editable: this.editable
			});

			this.tmpEditable = this.editable;
			this.editable = false;
			this.addFramesSet(layout.images);
			this.addTextsSet(layout.texts);
			this.editable = true;
			delete this.tmpEditable;
			$(this.container).find('.flowContent.flowAlignTarget').removeClass('flowAlignTarget');

			this.addFramesSet($.FlowLayoutSheetUtils.getSheetImages(page));
			this.addTextsSet($.FlowLayoutSheetUtils.getSheetTexts(page));
		},
		getSubjectGrid: function(definition) {
			return $.FlowLayoutSheetUtils.getSubjectGrid(definition);
		},
		getSubjectsInSheet: function(definition) {
			var grid = this.getSubjectGrid(definition);
			return grid.columns * grid.rows;
		},
		setupSheetGrid: function(definition) {
			if(!this.sheetGridLines) {
				return;
			}

			var grid = $.sanitizeNumbersAsStrings(definition.grid);
			var sheet = $.sanitizeNumbersAsStrings(definition.sheet);
			var width = grid.width;
			var height = grid.height;
			if(sheet.spacing) {
				if(sheet.spacing.horizontal) {
					width = [width, sheet.spacing.horizontal];
				}

				if(sheet.spacing.vertical) {
					height = [height, sheet.spacing.vertical];
				}
			} else if(grid.bleed) {
				width += grid.bleed.left + grid.bleed.right;
				height += grid.bleed.top + grid.bleed.bottom;
			}

			this.sheetGridLines.setSettings({
				enabled: true,
				unitWidth: width,
				unitHeight: height,
				color: 'black'
			});
		},
		setRatio: function(ratio) {
			_setRatio.apply(this, arguments);

			if(this.sheetGridLines) {
				this.sheetGridLines.setRatio(ratio);
			}
		},
		getSubjects: function() {
			var subjects = _getSubjects.apply(this, arguments);
			
			if((this.editable || this.tmpEditable) && !this.isPreview && subjects && this.definition && this.definition.sheet) {
				if($.composerPreviewLayoutButton && $.isArray($.composerPreviewLayoutButton.subject)) {
					subjects = $.composerPreviewLayoutButton.subject;
				}

				subjects = $.FlowLayoutSheetUtils.getSheetSubjects(subjects, {
					definition: this.definition
				});
			}
			
			return subjects;
		},

		getGroupByParam: function() {
			if(this.definition && this.definition.sheet && this.definition.sheet.groupBy) {
				return this.definition.sheet.groupBy.split(',');
			} else {
				return null;
			}
		},
		setTheme: function(theme, updating) {
			if(this.definition && this.definition.sheet && !this.displaySheet && updating !== true) {
				var page = this.getPage();
				theme = page.layoutTheme || null;
			}

			_setTheme.call(this, theme, updating);
		},
		saveTheme: function(theme) {
			if(this.definition && this.definition.sheet && !this.displaySheet) {
				var page = this.getPage();
				var layout = page.getLayout();
				layout.layoutTheme = theme;

				page.propertyChange('layoutTheme', theme, false, true);
			} else {
				_saveTheme.call(this, theme);
			}
		},
		clearBackground: function() {
			if(this.definition && this.definition.sheet && !this.displaySheet) {
				var page = this.getPage();
				page.propertyChange('layoutTheme', null, false, true);
				this.updateTheme();

				page.updatePageLabel();
				this.setLabel(page.getPageLabel());
			} else {
				_clearBackground.call(this);
			}
		},

		getOpenThemeSettingsTheme: function(page) {
			let theme;
			if(!this.definition?.sheet || this.displaySheet) {
				theme = page.getTheme();
			} else {
				theme = page.layoutTheme;
			}

			return {
				theme,
				settingsOnPart: false
			};
		},
		getBackgroundSettingKey: function() {
			if(!this.definition?.sheet || this.displaySheet) {
				return 'backgroundSettings';
			} else {
				return 'layoutBackgroundSettings';
			}
		},

		getPrimarySubject: function() {
			let proofBatch = flowLayout.getPageSet()?.getPrimaryProofBatch();
			if(proofBatch) {
				return {
					...proofBatch,
					photos: []
				};
			} else {
				return _getPrimarySubject.apply(this, arguments);
			}
		},
		getDynamicFields: function() {
			var extraProofFields = [];
			let proofBatch = flowLayout.getPageSet()?.getPrimaryProofBatch();

			if(proofBatch) {
				let extraKeys = Object.keys(proofBatch.comments)
					.filter(key => key.startsWith('extra-'))
					.filter(key => !proofBatch.comments[key].deleted);

				extraProofFields = extraKeys.map(key => {
					return `Proof ${key.substring(6)}`;
				});
			}

			return [
				..._getDynamicFields.apply(this, arguments),
				'Proof Group Name',
				'Proof Batch Name',
				'Proof Row Names',
				'Proof Not Pictured Names',
				...extraProofFields
			];
		},
		getDynamicFieldRep: function(field, subjectIndex, options) {
			options = $.extend({
				allowBlank: true
			}, options);

			let proofBatch = flowLayout.getPageSet()?.getPrimaryProofBatch();
			if(['Proof Batch Name', 'Proof Group Name'].includes(field)) {
				if(proofBatch) {
					return proofBatch.name;
				} else if(this.editable && options.allowBlank) {
					return 'Blank';
				} else {
					return '';
				}
			} else if(['Proof Row Names', 'Proof Not Pictured Names'].includes(field)) {
				let fieldRep = null;
				if(proofBatch) {
					const commentTypes = Object.keys(proofBatch.comments).filter(key => !proofBatch.comments[key].deleted);
					let rowKeys = field === 'Proof Row Names' ? commentTypes.filter(type => type.startsWith('row-')) : commentTypes.filter(type => type === 'not-pictured');
					if(field === 'Proof Row Names') {
						if($.isInit(subjectIndex)) {
							rowKeys = rowKeys.filter(rowKey => rowKey === `row-${subjectIndex + 1}`);
						} else if(proofBatch.comments['not-pictured']?.comment) {
							rowKeys.push('not-pictured');
						}
					}

					if(rowKeys.length) {
						fieldRep = rowKeys.map(rowKey => {
							let row = proofBatch.comments[rowKey];

							if($.isInit(subjectIndex) || field === 'Proof Not Pictured Names') {
								return row.comment;
							} else {
								return `${row.name}: ${row.comment}`;
							}
						}).join('\n');
					}
				}

				return fieldRep ?? '';
			} else if(field.startsWith('Proof ')) {
				let fieldRep = null;
				if(proofBatch) {
					let fieldName = field.substring(6);
					let comment = proofBatch.comments[`extra-${fieldName}`];
					if(comment) {
						fieldRep = comment.comment;
					}
				}

				if(fieldRep) {
					return fieldRep;
				} else if(this.editable && options.allowBlank) {
					return 'Blank';
				} else {
					return '';
				}
			}

			return _getDynamicFieldRep.apply(this, arguments);
		},

		displaySheetGrid: true
	}, options);

	if(flowLayout.displaySheetGrid) {
		flowLayout.sheetGridLines = new $.FlowLayoutGridLines({
			flowLayout: flowLayout
		});
	}

	return flowLayout;
};