import '../js/common/globals';
import './legacy/pages';
import './legacy/data';
import '../js/flow-layout/globals';
import '../js/flow-layout/flow-layout-sheet';
import '../js/common/sheet-utils';

// Really hard to tell if any of this is needed anymore but it was definitely included before migrating to Webpack
import '../css/composer.css';

import ascii85 from '../js/lib/ascii85';
window.ascii85 = ascii85;