<template>
	<div v-if="errorMessage">
		<v-alert type="error" >{{ errorMessage }}</v-alert>
		<v-btn color="error" raised @click="cancel">Cancel</v-btn>
	</div>
	<v-progress-circular v-else-if="loading" indeterminate />
	<div v-else>
		<v-data-iterator
			:items="images"
			:items-per-page="itemsPerPage"
			:footer-props="{ 'show-current-page': true, 'show-first-last-page': true, 'next-icon': 'chevron_right', 'prev-icon': 'chevron_left' }"
			class="image-grid"
			:hide-default-footer="disableImagePagination"
			@pagination="updateDisplayedPage">

			<template v-slot:default="props">
				<v-row class="justify-center">
					<v-col lg="3" md="4" sm="6" v-for="image in props.items" :key="image.id" class="preview-col" >
						<v-img :src="image.baseUrl" style="max-width: 100%" min-height="12em" max-height="30em" @click="toggleImage(image)" />
						<v-btn x-large color="green" light icon fab class="checkmark" v-if="image.selected" @click="toggleImage(image)"><v-icon class="checkmark-icon">check_circle</v-icon></v-btn>
					</v-col>
				</v-row>
				<v-progress-linear indeterminate v-if="loadingMore" class="loading-more" height="8" />
			</template>
		</v-data-iterator>

		<v-btn color="error" raised @click="cancel">Cancel</v-btn>
		<v-btn color="primary" raised @click="selectFiles" class="continue-button" :disabled="selectedImages.length <= 0">Continue {{ selectedText }}</v-btn>
	</div>
</template>

<script>
import { client } from '../utils/axios';
import replaceFileExtension from './utils/replace-file-extension';
const SCOPES = 'https://www.googleapis.com/auth/photoslibrary.readonly';
const PAGE_SIZE = 100;

export default {
	props: ['acceptedFileMimeTypes', 'allowMultiple'],
	data() {
		return {
			loading: true,
			loadingMore: false,
			errorMessage: null,
			images: [],
			accessToken: null,
			nextPageToken: null
		};
	},
	computed: {
		itemsPerPage() {
			if(this.$vuetify.breakpoint.smAndDown) {
				return 4;
			} else if(this.$vuetify.breakpoint.md) {
				return 6;
			} else {
				return 8;
			}
		},
		disableImagePagination() {
			return this.images.length <= this.itemsPerPage;
		},
		selectedImages() {
			return this.images.filter((image) => {
				return image.selected;
			});
		},
		selectedText() {
			if(this.selectedImages.length === 0) {
				return '';
			} else {
				return '(' + this.selectedImages.length + ' selected)';
			}
		}
	},
	methods: {
		selectFiles() {
			let files = this.selectedImages.map(image => {
				// HEIC files are auto converted to jpeg even when uploaded from website
				let filename = image.filename;
				let mimeType = image.mimeType;
				if(['image/heic', 'image/heif'].includes(mimeType)) {
					filename = replaceFileExtension(filename, 'jpg');
					mimeType = 'image/jpeg';
				}
				
				return {
					id: image.id,
					name: filename,
					type: mimeType,
					// Docs: "If you want to download the image retaining all the Exif metadata except the location metadata, concatenate the base URL with the d parameter."
					downloadUrl: `${image.baseUrl}=d`,
					viewUrl: image.baseUrl,
					size: 1000,
					source: 'google-photos'
				};
			});
			this.$emit('filesSelected', files);
			this.$emit('returnToMain');
		},
		cancel() {
			this.$emit('returnToMain');
		},

		initGoogleAuth() {
			this.loading = true;
			let tokenClient = window.google.accounts.oauth2.initTokenClient({
				client_id: process.env.VUE_APP_GOOGLE_API_CLIENT_ID,
				scope: SCOPES,
				callback: (response) => {
					if(response.error !== undefined) {
						this.errorMessage = response.error;
					} else {
						this.accessToken = window.gapi.cachedPhotosAccessToken = response.access_token;
						this.getPhotos();
					}
				}
			});

			tokenClient.requestAccessToken({prompt: 'consent'});
		},
		async getPhotos() {
			if(!this.accessToken) {
				return;
			}

			try {
				let response = await client.post('https://photoslibrary.googleapis.com/v1/mediaItems:search', {
						pageSize: PAGE_SIZE,
						filters: {
							mediaTypeFilter: {
								mediaTypes: [
									'PHOTO'
								]
							}
						}
					}, {
					headers: {
						'Content-type': 'application/json',
						'Authorization': `Bearer ${this.accessToken}`
					}
				});
				this.images = this.filterMediaItems(response.data.mediaItems);
				this.nextPageToken = response.data.nextPageToken;

				// This client doesn't actually seem to work well
				/*const DISCOVERY_DOC = 'https://www.googleapis.com/discovery/v1/apis/photoslibrary/v1/rest';
				window.gapi.load('client', () => {
					window.gapi.client.init({
						apiKey: process.env.VUE_APP_GOOGLE_API_KEY,
						// accessToken: this.accessToken,
						discoveryDocs: [DISCOVERY_DOC],
					}).then(() => {
						// window.gapi.client.setToken(this.accessToken);
						window.gapi.client.photoslibrary.mediaItems.get({
							mediaItemId: this.images[0].id,
							'alt': 'media'
						}).then(response => {
							
						});
					});
				});*/

				this.loading = false;
			} catch(error) {
				console.error(error);
				if(error?.response?.data) {
					// Auth tokens only last for a little while and can expire if we leave window open for a while
					if(error.response.data.error?.message?.includes?.('Request had invalid authentication credentials')) {
						delete window.gapi.cachedPhotosAccessToken;
						this.initGoogleAuth();
					} else {
						this.errorMessage = error.response.data.error.message;
					}
				} else {
					this.errorMessage = error;
				}
			}
		},
		async getMorePhotos() {
			if(!this.nextPageToken) {
				return;
			}

			// Clear so we don't accidentally start multiple loads
			let nextPageToken = this.nextPageToken;
			this.nextPageToken = null;
			this.loadingMore = true;
			let response = await client.post('https://photoslibrary.googleapis.com/v1/mediaItems:search', {
					pageSize: PAGE_SIZE,
					filters: {
						mediaTypeFilter: {
							mediaTypes: [
								'PHOTO'
							]
						}
					},
					pageToken: nextPageToken
				}, {
				headers: {
					'Content-type': 'application/json',
					'Authorization': `Bearer ${this.accessToken}`
				}
			});
			this.images.push(...this.filterMediaItems(response.data.mediaItems ?? []));
			this.nextPageToken = response.data.nextPageToken;
			this.loadingMore = false;
		},
		filterMediaItems(mediaItems) {
			return mediaItems.filter(image => this.acceptedFileMimeTypes.includes(image.mimeType)).map((image) => {
				image.selected = false;
				return image;
			});
		},
		
		updateDisplayedPage(args) {
			if(args.page === args.pageCount) {
				this.getMorePhotos();
			}
		},
		toggleImage(image) {
			if(this.allowMultiple === false) {
				this.images.forEach(otherImage => {
					if(image !== otherImage) {
						otherImage.selected = false;
					}
				});
			}

			image.selected = !image.selected;
		}
	},
	mounted() {
		if(window.gapi.cachedPhotosAccessToken) {
			this.accessToken = window.gapi.cachedPhotosAccessToken;
			this.getPhotos();
		} else if(this.accessToken) {
			this.getPhotos();
		} else {
			this.initGoogleAuth();
		}
	}
};
</script>

<style scoped>
.continue-button {
	margin-left: 0.5em;
}

.image-grid {
	margin-bottom: 1em;
}
.image-grid:deep(.v-data-footer__select) {
	display: none;
}

.preview-col {
	position: relative;
}
.checkmark {
	position: absolute;
	top: 0;
	right: 0;
}

.checkmark-icon {
	background-color: white;
}

.loading-more {
	margin-top: 0.5em;
}
</style>