$.LayoutPageSet = function(settings) {
	var obj = new $.FlowPageClassSet($.extend({
		addPage: function(page, index, save) {
			page.db = this.db;
			
			if(!$.isInit(index)) {
				this.pages.push(page);
				this.page = this.pages.length - 1;
				if(this.page % 2 == 1) {
					this.page--;
				}
				index = this.pages.length;
				page.setPageNumber(index);
			} else {
				this.pages.splice(index + 1, 0, page);
				page.setPageNumber(index + 2);
				for(var i = index + 2; i < this.pages.length; i++) {
					this.pages[i].setPageNumber(i + 1);
				}
			}
			page.pageSet = this;

			if(this.onPageChange) {
				this.onPageChange('add', page, index);
			}
			page.onAdd();

			if(this.db && save !== false) {
				this.db.addEntirePage(page);
			}

			return true;
		},
		load: function (events) {
			this.db.load(events);
		},
		switchLayout: function(newLayout, onComplete) {
			$.mainApp.project.id = newLayout;
			this.updateLayoutReferences(newLayout);
			this.pages = [];

			let link = '/Composer.php?layoutId=' + newLayout;
			if($.getGETParams().seasonId) {
				link += '&seasonId=' + $.getGETParams().seasonId;
			}
			window.history.replaceState({
				layoutId: newLayout
			}, null, link);

			// Only want to update here and not in updateLayoutReferences since multi page layouts still want to reference the root layoutId
			this.db.layouts.layoutId = newLayout;
			this.db.refreshWebSocket();

			this.load({
				onReady: onComplete
			});
		},
		updateLayoutReferences: function(newLayout) {
			// ie: on first load this is called but is already what is setup
			if($.getGETParams().layoutId == newLayout) {
				return;
			}

			$.getGETParams().layoutId = newLayout;
			this.db.postData.layoutId = newLayout;

			this.db.pushChanges();
			this.userEvents.clear();
		},
		setProject: function(project) {
			this.project = project;
			this.db.loadSubjects(project);
			this.compositeProof = project.compositeProof;
			if(this.hasGroupProof()) {
				this.db.loadProof(project);
			}

			$.PlicProjectId = project.plicProjectId;
			$.ProjectName = project.jobName;
			$.OrgName = project.organizationName;
		},
		setOrderBatch: function(batch) {
			this.batch = batch;
			this.loadOrderBatch(batch);

			this.project = null;
			$.PlicProjectId = null;
			$.ProjectName = null;
			$.OrgName = null;
		},
		loadOrderBatch: function(batch) {
			this.db.loadOrderBatch(batch);
		},
		hasGroupProof: function() {
			return !!Object.values(this.pages[0]?.getCandids() ?? {}).find(candid => {
				return candid?.poseSelection === 'proof group photo';
			});
		},
		setProof(proof) {
			this.compositeProof = proof;
		},
		loadProof: function() {
			if(!this.project) {
				return;
			}
			this.db.loadProof(this.project);
		},
		setPrimaryProofBatch: function(batch) {
			this.proofBatch = batch;
		},
		getPrimaryProofBatch: function() {
			if(this.hasGroupProof()) {
				return this.proofBatch;
			} else {
				return null;
			}
		},
		getProjectPageSet: function(subjects) {
			return this.pages[0].getProjectPageSet(subjects);
		},
		doesContainTextInParent: function() {
			return false;
		},

		layoutId: $.getGETParams().layoutId,
		pushPageRemoveImmediately: true,
		pageLimit: 2,
		enforcePageLimit: true
	}, settings));

	obj.db = new $.LayoutsDB(obj, settings);

	return obj;
};
