const DOUBLE_WHITE_SPACE_REGEX = /\s\s+/g;
$.pickDataDropdown = function(options) {
	var searchId = (options.id || $.getUniqueId());
	var search = $('<div class="ui loading button selection dropdown">').attr('id', searchId + 'Search');
	if(options.fluid !== false) {
		search.addClass('fluid');
	}
	search.append('<i class="dropdown icon">');
	var input = $('<input type="hidden">').attr('name', searchId).appendTo(search);
	$('<span class="default text">').text(options.title).appendTo(search);
	search.append('<div class="menu">');

	if(options.multiple) {
		search.addClass('multiple');
	}
	if(options.search) {
		search.addClass('search');
	}

	var id, title;
	$.extend(search[0], {
		emptyDropdown: function() {
			search[0].items = null;
			if(!search.hasClass('loading')) {
				search.dropdown('destroy');
				search.find('.menu').empty();
				search.children('a.ui.label').remove();
				search.find('.text').text(options.title);
				input.val('');
			}
		},
		setItems: function(items, data) {
			search[0].items = items;
			id = options.itemId ? options.itemId : 'id';
			title = options.itemTitle ? options.itemTitle : 'name';

			if(options.sort) {
				items.caseInsensitiveSort(title);
			}

			var menu = search.find('.menu')[0];
			for(let i = 0; i < items.length; i++) {
				var item = items[i];
				var elem = search[0].createItem(item);
				if(elem.jquery) {
					elem = elem[0];
				}
				menu.appendChild(elem);
			}

			this.initDropdown();

			if(options.defaultValue) {
				this.setValueSelected(options.defaultValue);
			} else if(options.defaultData) {
				let defaultValue = data || items;
				if($.isArray(options.defaultData)) {
					for(let i = 0; i < options.defaultData.length; i++) {
						defaultValue = defaultValue[options.defaultData[i]];
					}
				} else {
					defaultValue = defaultValue[options.defaultData];
				}

				this.setValueSelected(defaultValue);
			} else if(items.length && options.autoSelectFirst) {
				search.dropdown('set selected', items[0][id]);
			}
		},
		setValueSelected: function(defaultValue, isRetry) {
			if($.isArray(defaultValue)) {
				if(!search.isAttached()) {
					if(isRetry) {
						console.error('Still failing to setValueSelected because not on DOM');
					} else {
						var me = this;
						window.setTimeout(function() {
							me.setValueSelected(defaultValue, true);
						}, 10);
					}
					return;
				}

				for(let i = 0; i < defaultValue.length; i++) {
					var defaultValueId = defaultValue[i];
					if($.isInit(defaultValueId[id])) {
						defaultValueId = defaultValueId[id];
					}

					search.dropdown('set selected', defaultValueId);
				}
			} else {
				search.dropdown('set selected', defaultValue);
			}
		},
		refreshDropdown: function(newOptions) {
			if(newOptions) {
				$.extend(options, newOptions);
			}
			this.emptyDropdown();
			if(!search.hasClass('loading')) {
				search.addClass('loading button');
			}

			$.proxyAjax($.extend({
				type: 'GET',
				dataType: 'json'
			}, options.ajax, {
				success: function(data) {
					search.removeClass('loading button');

					var items = data;
					if(options.data) {
						if($.isArray(options.data)) {
							for(let i = 0; i < options.data.length; i++) {
								items = items[options.data[i]];
							}
						} else if(typeof options.data === 'function') {
							items = options.data(items);
						} else {
							items = items[options.data];
						}
					}

					if(options.ajax.success) {
						options.ajax.success.apply(this, arguments);
					}
					search[0].setItems(items, data);
				},
				error: function() {
					search.addClass('error');
				}
			}));
		},
		initDropdown: function() {
			var dropdownSettings = {
				fullTextSearch: 'exact',
				match: 'text',
				preserveHTML: false,
				onNoResults: function() {
					// This breaks when searching on a multi-select since it wipes out pre-existing selections!
					if(!options.allowAdditions && !options.multiple) {
						search.find('input').val('');
					} else if(options.onChange && !options.multiple) {
						options.onChange.call(search[0], null);
					}
				},
				onChange: function(value, text, elem) {
					if(options.onChange) {
						var item;
						if(typeof elem == 'string') {
							// For multi select the arguments are actually (values, selectedValue, text)
							item = $(search).find('.item[data-value="' + text + '"]').data('item');
						} else {
							item = $(elem).data('item');
						}

						options.onChange.call(search[0], item);
					}

					if(options.blurAfterChange) {
						$(this).find('input').blur();
					}
				}
			};
			if(options.allowAdditions) {
				$.extend(dropdownSettings, {
					allowAdditions: true,
					forceSelection: false
				});
			}

			if(options.dropdownSettings) {
				$.extend(dropdownSettings, options.dropdownSettings);
			}

			if(dropdownSettings.forceSelection === false && options.search && !options.multiple && !options.allowAdditions) {
				delete dropdownSettings.onNoResults;
				dropdownSettings.onHide = function() {
					if(!options.allowAdditions && options.search && dropdownSettings.forceSelection === false && !options.multiple) {
						var selectedItem = search[0].getSelectedItem();
						if(selectedItem) {
							search.dropdown('set text', search.dropdown('get text'));
							search.children('input').last().val('');
						} else {
							search.dropdown('set text', search.dropdown('get placeholder text'));
							search.children('input').last().val('');
						}
					}
				};
			}

			search.dropdown(dropdownSettings);
			search.find('input.search').attr('name', searchId + 'Search').attr('autocomplete', 'nope');
		},
		createItem: function(item) {
			let div;
			if(options.createCustomItem) {
				div = options.createCustomItem(item);
			} else if(typeof item == 'string') {
				div = $('<div class="item">').data('item', item);
				if(options.setValueString) {
					div.attr('data-value', item);
				}

				if(item !== ' ') {
					div.text(item.replace(DOUBLE_WHITE_SPACE_REGEX, ' '));
				} else {
					div.html('&nbsp;');
				}
			} else {
				var text = item[title] || ' ';
				div = $('<div class="item">')
					.attr('data-value', item[id])
					.attr('data-text', text)
					.data('item', item);

				if(text !== ' ') {
					div.text(text);
				} else {
					div.html('&nbsp;');
				}
			}

			if($(div).attr('data-text')) {
				let text = $(div).attr('data-text');
				if(text.match(DOUBLE_WHITE_SPACE_REGEX)) {
					$(div).attr('data-text', text.replace(DOUBLE_WHITE_SPACE_REGEX, ' '));
				}
			}

			return div;
		},
		setCurrent: function(current) {
			var newId = current[id];
			search.dropdown('set selected', newId);
		},
		getCurrent: function() {
			var menuItem = $(this).find('.item.selected');
			if(menuItem.length) {
				return menuItem.data('item');
			} else {
				return null;
			}
		},
		getSelectedValue: function() {
			var value = $(this).dropdown('get value');
			if(options.multiple) {
				if(value) {
					return value.split(',');
				} else {
					return [];
				}
			} else {
				return value;
			}
		},
		getSelectedItem: function() {
			var value = this.getSelectedValue();
			if(!value) {
				return null;
			}

			if(options.multiple) {
				return value.map(function(value) {
					return $(search).find('.item[data-value="' + value + '"]').data('item');
				});
			} else {
				return $(search).find('.item[data-value="' + value + '"]').data('item');
			}
		},
		addDropdownItem: function(item, autoSelect) {
			var menu = $(this).children('.menu');
			menu.append(this.createItem(item));
			search.dropdown('refresh');

			if(autoSelect) {
				search.dropdown('set selected', item[id]);
			}
		},
		removeDropdownItem: function(item) {
			var menuItem = $(this).find('.item[data-value="' + item[id] + '"]');
			if(menuItem.hasClass('selected')) {
				$(this).dropdown('clear');
			}
			menuItem.remove();
		},
		updateDropdownItem: function(item) {
			var menuItem = $(this).find('.item[data-value="' + item[id] + '"]');
			menuItem.text(item[title]);
			if(options.onUpdateDropdownItem) {
				options.onUpdateDropdownItem(item, menuItem);
			}

			// Refresh selected since name is not updated by default
			if(menuItem.hasClass('selected')) {
				search.children('.text').text(item[title]);
			}
		},
		setLoading: function(loading) {
			if(loading) {
				search.addClass('loading button');
			} else {
				search.removeClass('loading button');
			}
		},
		enable: function() {
			$(this).removeClass('disabled');
		},
		disable: function() {
			$(this).addClass('disabled');
		},
		setError: function() {
			search.addClass('error');
		},

		options: options
	});

	if(options.createItem) {
		search[0].createItem = options.createItem;
	}
	if(options.ajax) {
		search[0].refreshDropdown();
	} else if(options.items) {
		search.removeClass('button loading');
		search[0].setItems(options.items);
	}
	if(options.disabled) {
		search[0].disable();
	}

	return search[0];
};