<template>
	<v-menu open-on-hover bottom offset-y content-class="tooltip" :close-delay="200">
		<template v-slot:activator="{ on, attrs }">
			<v-btn
				tile
				class="proof-page-button batch-button classButton"
				:class="batchClass"
				:color="batchColor"
				:dark="batchColor !== 'none'"
				v-bind="attrs"
				v-on="on"
				attach
				@click="clicked"
				ref="batchButton"
				:id="'proof-page-' + batch.id">

				<span v-if="batch.name">{{ batch.name }}</span>
				<span v-else>&nbsp;</span>

				<v-avatar class="comment-count" :color="avatarColor" size="28" :ripple="true" v-if="commentCount">{{ commentCount }}</v-avatar>
			</v-btn>
		</template>

		<div class="tooltip-content" ref="menuContent">
			<div v-if="editable">
				<div class="tooltip-header bolded">
					{{ tooltip }}
				</div>

				<v-btn color="primary" @click="renameBatch">Rename</v-btn>
			</div>
			<div class="tooltip-header" v-else>
				{{ tooltip }}
			</div>
		</div>
	</v-menu>
</template>

<script>
export default {
	props: ['batch', 'editable'],
	data: () => ({
		draggingNode: null,
		currentOver: []
	}),
	computed: {
		commentCount() {
			if(this.batch.comments) {
				return Object.values(this.batch.comments).filter(comment => !comment.deleted && !comment.checked).length;
			} else {
				return 0;
			}
		},
		batchColor() {
			if(this.batch.active) {
				return 'primary';
			} else {
				return 'none';
			}
		},
		batchClass() {
			if(this.batch.approved === true) {
				return { 'proof-approved': true };
			} else if(this.batch.approved === false) {
				return { 'proof-denied': true };
			} else if(this.batch.visible === false) {
				return 'proof-hidden';
			} else {
				return {};
			}
		},
		avatarColor() {
			if(this.batch.approved === true) {
				return 'green';
			} else if(this.batch.approved === false) {
				return 'red';
			} else if(this.batch.visible === false) {
				return 'yellow';
			} else {
				return this.batch.active ? 'white' : 'primary';
			}
		},
		tooltip() {
			if(this.batch.visible !== false) {
				return 'Click to go to page';
			} else {
				return 'Click to go to hidden page';
			}
		}
	},
	methods: {
		clicked() {
			this.$emit('clicked', this.batch);
		},
		renameBatch() {
			this.$emit('rename-batch', this.batch);
		}
	},
	mounted() {
		window.$(this.$refs.batchButton.$el).data('batch', this.batch);
	}
};
</script>

<style scoped>
.batch-button {
	font-size: 14px;
	text-transform: none;
	cursor: pointer;
	letter-spacing: initial;
}
.batch-button.primary {
	font-weight: bold;
}

.comment-count {
	margin-left: 0.3em;
	cursor: pointer;
	font-size: .85714286rem;

	color: rgb(255, 255, 255);
}

.comment-count.white {
	color: #0091d2;
}
.comment-count.primary {
	color: rgb(255, 255, 255);
}

.tooltip {
	background-color: white;
	color: black;
}
.tooltip-content {
	margin: 0.8em;

	text-align: start;
}
.tooltip-header.bolded {
	font-weight: bold;	
	margin-bottom: 0.5em;
}

.remove-button {
	margin-left: 1em;
}

.proof-approved {
	color: #21ba45 !important;
}
.proof-approved.primary {
	color: white !important;
	background-color: #21ba45 !important;
}
.proof-denied {
	color: #db2828 !important;
}
.proof-denied.primary {
	color: white !important;
	background-color: #db2828 !important;
}

.proof-hidden, .proof-hidden.primary {
	color: black !important;
	background-color: #ffeb3b !important;
}
</style>